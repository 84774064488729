import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between" }
const _hoisted_2 = { class: "flex flex-row items-center gap-10" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-700 dark:text-gray-300"
}
const _hoisted_4 = { class: "flex flex-col justify-between" }
const _hoisted_5 = { class: "flex flex-row items-center gap-10" }

import { useKnowledgeStore } from "@/common/stores/knowledgeStore";
import { computed, ComputedRef } from "vue";
import { ConceptType, knowledgeItemId, KnowledgeType } from "../lib/knowledge";
import Icon from "./Icon.vue";
import Select from "./Select.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { storeToRefs } from "pinia";
import { knowledgeSortHeuristic, vsFilterSort } from "../lib/comparators";
import { IconColor } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConceptSelector',
  props: {
    label: {},
    modelValue: {},
    placeholder: {},
    includeAdhoc: { type: Boolean },
    conceptIds: {},
    includeConceptId: { type: Boolean },
    iconColor: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const { conceptColors } = storeToRefs(useExploreStore());

const knowledgeStore = useKnowledgeStore();

const props = __props;
const emit = __emit;

const concepts = computed(() => {
  let allConcepts = conceptMap.value;
  if (props.conceptIds) {
    const conceptSet = new Set(props.conceptIds);
    allConcepts = allConcepts.filter(([k, _]) => conceptSet.has(k));
  }

  const conceptTypes = allConcepts.map(([_, v]) => v);
  conceptTypes.sort(knowledgeSortHeuristic);
  return conceptTypes;
});

const conceptMap: ComputedRef<Array<[string, ConceptType]>> = computed(() => {
  if (props.includeAdhoc) {
    return Object.entries(knowledgeStore.combinedKnowledge).flatMap(([k, v]) =>
      v.type === KnowledgeType.Concept ? [[k, v]] : []
    );
  }
  return Object.entries(knowledgeStore.concepts);
});

function color(conceptType: string) {
  return conceptColors.value[conceptType];
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Select, {
    label: _ctx.label,
    options: concepts.value,
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    "option-value": _unref(knowledgeItemId),
    placeholder: _ctx.placeholder,
    clearable: false,
    filter: _unref(vsFilterSort)(_unref(knowledgeItemId))
  }, {
    "option-data": _withCtx((concept) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "rounded-full p-[3px]",
            style: _normalizeStyle({ backgroundColor: color(_unref(knowledgeItemId)(concept)) })
          }, [
            _createVNode(Icon, {
              name: concept.icon_name,
              color: _ctx.iconColor,
              size: "m"
            }, null, 8, ["name", "color"])
          ], 4),
          _createTextVNode(" " + _toDisplayString(concept.label), 1)
        ]),
        (_ctx.includeConceptId)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(knowledgeItemId)(concept)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    "selected-option-data": _withCtx((concept) => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "rounded-full p-[3px]",
            style: _normalizeStyle({ backgroundColor: color(_unref(knowledgeItemId)(concept)) })
          }, [
            _createVNode(Icon, {
              name: concept.icon_name,
              color: _ctx.iconColor,
              size: "m"
            }, null, 8, ["name", "color"])
          ], 4),
          _createTextVNode(" " + _toDisplayString(concept.label), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["label", "options", "modelValue", "option-value", "placeholder", "filter"]))
}
}

})