import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs text-gray-500"
}

import { CheckboxVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { Query } from "@/common/lib/query";
import { toRefs } from "vue";
import Checkbox from "@/common/components/Checkbox.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationCheckboxControl',
  props: /*@__PURE__*/_mergeModels({
    control: {},
    query: {}
  }, {
    "modelValue": { type: Boolean, ...{ default: false } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;
const { control } = toRefs(props);

const model = _useModel<boolean>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Checkbox, {
      label: _unref(control).label,
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event))
    }, null, 8, ["label", "modelValue"]),
    (_unref(control).description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(control).description), 1))
      : _createCommentVNode("", true)
  ], 64))
}
}

})