import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "my-10" }

import { DerivedPropertyTerm, DividePropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DividePropertyDef',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const OP = PropertyOpType.Divide;

const divisor: Ref<DerivedPropertyTerm | null> = ref(null);
const dividend: Ref<DerivedPropertyTerm | null> = ref(null);

const model = _useModel<DividePropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (divisor.value == null || dividend.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      divisor: divisor.value,
      dividend: dividend.value,
    };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("label", { class: "mb-5 block" }, "Dividend:", -1)),
      _createVNode(DerivedPropertyDef, {
        "parent-op": _unref(OP),
        modelValue: dividend.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dividend).value = $event))
      }, null, 8, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "mb-5 block" }, "Divisor:", -1)),
      _createVNode(DerivedPropertyDef, {
        "parent-op": _unref(OP),
        modelValue: divisor.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((divisor).value = $event))
      }, null, 8, ["parent-op", "modelValue"])
    ])
  ], 64))
}
}

})