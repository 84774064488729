import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pointer-events-none absolute inset-0 z-30 cursor-pointer fill-none stroke-white",
  width: "100%",
  height: "100%"
}
const _hoisted_2 = ["d"]

import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metalink',
  props: {
    linkType: {},
    fromX: {},
    fromY: {},
    toX: {},
    toY: {}
  },
  setup(__props: any) {

const props = __props;
const { fromX, toX, fromY, toY } = toRefs(props);

const path = computed(() => `M ${fromX.value},${fromY.value} L ${toX.value},${toY.value}`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: "z-20 stroke-gray-500 stroke-1",
      d: path.value
    }, null, 8, _hoisted_2)
  ]))
}
}

})