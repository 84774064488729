import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "neighborhood" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "neighbors" }
const _hoisted_8 = ["onClick"]

import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { ConceptAddress, conceptAddress, readerConceptTitle } from "@/reader/lib/concept";
import pluralize from "pluralize";
import { computed, toRefs } from "vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { AsyncStatus } from "@/common/lib/async";
import Spinner from "@/common/components/Spinner.vue";
import { Query } from "@/common/lib/query";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'NeighborhoodSummary',
  props: {
    query: {}
  },
  emits: ["navigate", "pivot"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { query } = toRefs(props);
const emit = __emit;

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcepts } = useQuery(
  appStore.module!,
  () => query.value,
  UserAction.Load_Neighborhood_Summary
);

const conceptType = () => query.value.root_concept_type;

const conceptCount = computed(() => rootConcepts().length);
const conceptIconName = computed(() => getConceptIconName(conceptType()));
const conceptTypeLabel = computed(() => pluralize(typeLabel(conceptType()), conceptCount.value));

const neighbors = computed(function () {
  return rootConcepts().map((concept) => ({
    key: concept.id,
    title: readerConceptTitle(concept),
    address: conceptAddress(concept),
  }));
});

function clickLink(address?: ConceptAddress) {
  if (address != null) emit("navigate", address);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(queryResults).status === _unref(AsyncStatus).InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Spinner)
        ]))
      : _createCommentVNode("", true),
    (_unref(queryResults).status === _unref(AsyncStatus).Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(queryResults).message), 1))
      : _createCommentVNode("", true),
    (_unref(queryResults).status === _unref(AsyncStatus).Succeeded && conceptCount.value > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(Icon, {
              name: conceptIconName.value,
              color: { dark: 'white', light: 'black' }
            }, null, 8, ["name"]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(conceptTypeLabel.value), 1),
            _createVNode(IconButton, {
              name: "table",
              size: "l",
              tooltip: "View all in table",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('pivot')))
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(neighbors.value, (neighbor) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["neighbor", { '-with-link': neighbor.address != null }]),
                key: neighbor.key,
                onClick: ($event: any) => (clickLink(neighbor.address))
              }, _toDisplayString(neighbor.title), 11, _hoisted_8))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})