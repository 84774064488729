import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-center justify-around"
}
const _hoisted_3 = { class: "flex flex-row items-center justify-end gap-10 pt-10" }

import Dialog from "@/common/components/Dialog.vue";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { onMounted, ref } from "vue";
import Spinner from "./Spinner.vue";
import MessageBar from "./MessageBar.vue";

enum State {
  Loading = "loading",
  Saving = "saving",
  Fatal = "fatal",
  Ok = "ok",
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditProjectDetails',
  props: {
    projectId: {}
  },
  emits: ["closed"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const userModuleStore = useUserModuleStore();

const state = ref(State.Loading);

const error = ref<string | undefined>();
const projectDescription = ref("");
const projectLLMPrompt = ref("");

async function saveProjectDetails() {
  state.value = State.Saving;
  error.value = undefined;
  try {
    const description = projectDescription.value.trim();
    const llmPrompt = projectLLMPrompt.value.trim();
    await userModuleStore.updateModule(props.projectId, {
      description,
      app_state: { llmPrompt },
    });
    emit("closed");
  } catch (e) {
    error.value = String(e);
  } finally {
    state.value = State.Ok;
  }
}

const keyHandler = useKeyHandler();
keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("closed"), true, false);
keyHandler.registerHandler(new KeyCommand("Enter"), () => saveProjectDetails(), true, true);

onMounted(async () => {
  try {
    const moduleResponse = await userModuleStore.loadModule(props.projectId);
    projectDescription.value = moduleResponse.module.manifest.description ?? "";
    projectLLMPrompt.value = moduleResponse.module.app_state?.llmPrompt ?? "";
    state.value = State.Ok;
  } catch (e) {
    state.value = State.Fatal;
    error.value = String(e);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    title: "Edit project details",
    icon: "md:edit_note",
    "skip-default-footer": true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (state.value !== State.Saving)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(TextButton, {
                label: "Cancel",
                secondary: true,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('closed')))
              }),
              (state.value === State.Ok)
                ? (_openBlock(), _createBlock(TextButton, {
                    key: 0,
                    label: "Update",
                    onClick: saveProjectDetails,
                    "data-test": "success-button"
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(SpinnerButton, {
              key: 1,
              label: "Updating…"
            }))
      ])
    ]),
    default: _withCtx(() => [
      (state.value === State.Ok || state.value === State.Saving)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(Textbox, {
              label: "Description",
              modelValue: projectDescription.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((projectDescription).value = $event)),
              textarea: true,
              "min-height": "10em",
              disabled: state.value === State.Saving
            }, null, 8, ["modelValue", "disabled"]),
            _createVNode(Textbox, {
              label: "Additional LLM prompt",
              modelValue: projectLLMPrompt.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((projectLLMPrompt).value = $event)),
              textarea: true,
              "min-height": "10em",
              disabled: state.value === State.Saving
            }, null, 8, ["modelValue", "disabled"])
          ]))
        : _createCommentVNode("", true),
      (state.value === State.Loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "Loading…", -1)),
            _createVNode(Spinner)
          ]))
        : _createCommentVNode("", true),
      (error.value)
        ? (_openBlock(), _createBlock(MessageBar, {
            key: 2,
            mode: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error.value), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})