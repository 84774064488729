import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { blobPath } from "@/common/lib/api";
import { BytesValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageValue',
  props: {
    value: {}
  },
  setup(__props: any) {

const props = __props;

const { value } = toRefs(props);

const path = computed(() => blobPath(value.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", { src: path.value }, null, 8, _hoisted_1))
}
}

})