import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_2 = { class: "flex flex-col items-start gap-15" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "italic"
}
const _hoisted_5 = { class: "flex flex-col items-start gap-15" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "italic"
}

import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import Select from "./Select.vue";
import { environment } from "../environments/environmentLoader";

interface LLMOption {
  key: string;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPreferences',
  setup(__props) {

const editingHomePage = ref<string | undefined>();
const editingDefaultLLM = ref<string | undefined>();

const userStore = useUserStore();
const { user, userSettings } = storeToRefs(userStore);

const LLM_OPTIONS: { [key: string]: string } = environment.requireJson("LLM_PROVIDER_MAP");
const llmMenuItems = computed<LLMOption[]>(() => {
  return Object.entries(LLM_OPTIONS).map(([key, label]) => ({
    key,
    label,
  }));
});

const currentDefaultLLMLabel = computed(() => {
  const currentId = userSettings?.value?.default_llm;
  return currentId ? LLM_OPTIONS[currentId] : undefined;
});

function editHomePage() {
  editingHomePage.value = userSettings?.value?.default_homepage ?? "";
}

function editDefaultLLM() {
  editingDefaultLLM.value =
    userSettings?.value?.default_llm ?? environment.require("LLM_PROVIDER_DEFAULT");
}

async function finishEditHomePage() {
  userSettings.value ??= {};
  userSettings.value.default_homepage =
    editingHomePage.value?.trim() === "" ? undefined : editingHomePage.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingHomePage.value = undefined;
}

async function finishEditDefaultLLM() {
  userSettings.value ??= {};
  userSettings.value.default_llm =
    editingDefaultLLM.value?.trim() === "" ? undefined : editingDefaultLLM.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingDefaultLLM.value = undefined;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(user))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-xl" }, "Home Page", -1)),
            (_unref(userSettings)?.default_homepage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(userSettings)?.default_homepage), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, "Home page not set")),
            _createVNode(TextButton, {
              label: "Update\n        home page",
              onClick: editHomePage
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-xl" }, "Default LLM", -1)),
            (currentDefaultLLMLabel.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(currentDefaultLLMLabel.value), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, "Default LLM not set")),
            _createVNode(TextButton, {
              label: "Update default LLM",
              onClick: editDefaultLLM
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (editingHomePage.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 1,
          title: "Update home page",
          "success-label": "Update",
          onSucceeded: finishEditHomePage,
          onCancelled: _cache[1] || (_cache[1] = ($event: any) => (editingHomePage.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Home page path",
              modelValue: editingHomePage.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editingHomePage).value = $event)),
              autoselect: true
            }, null, 8, ["modelValue"]),
            _cache[6] || (_cache[6] = _createTextVNode(" Enter a custom URL path to determine where you are taken upon login (e.g., /reader). "))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (editingDefaultLLM.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 2,
          title: "Update default LLM",
          "success-label": "Update",
          onSucceeded: finishEditDefaultLLM,
          onCancelled: _cache[3] || (_cache[3] = ($event: any) => (editingDefaultLLM.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Select, {
              label: "Select default LLM",
              modelValue: editingDefaultLLM.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editingDefaultLLM).value = $event)),
              options: llmMenuItems.value,
              "option-label": (option) => option.label,
              "option-value": (option) => option.key,
              class: "h-[350px]"
            }, null, 8, ["modelValue", "options", "option-label", "option-value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})