import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "relative mb-10" }
const _hoisted_2 = {
  key: 0,
  class: "absolute left-[-21px] flex h-[26px] items-center bg-gray-100 dark:bg-gray-800"
}
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-5 basis-full" }
const _hoisted_5 = {
  key: 0,
  class: "role-summary"
}
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "ml-[12px] mt-5 border-l border-l-gray-200 pl-10 dark:border-l-gray-700"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-10"
}

import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { LinkDescriptor } from "@/common/lib/graph";
import Disclosure from "@/common/components/Disclosure.vue";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { childPaths, expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { groupBy, identity, isEqual, last, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import TreeProperty from "./TreeProperty.vue";
import { underlyingPropertyTypes } from "@/common/lib/derived";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";
import { hasResolutionClauseForTypes, propertySorter } from "@/common/lib/map";
import { useAppStore } from "@/reader/stores/app";
import { columnIsDerived } from "@/common/lib/query";

const TOP_LEVEL_DERIVED_COLS = "";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeConcept',
  props: {
    path: {}
  },
  setup(__props: any) {

const props = __props;
const { path } = toRefs(props);

const exploreStore = useExploreStore();
const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);
const appStore = useAppStore();

const conceptType = computed(() => last(path.value)!.concept_type);
const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const isRoot = computed(() => path.value.length === 1);

const propertyTypes = computed(function () {
  const properties = getConceptsOfType(conceptType.value)[0].properties ?? [];
  return propertySorter(
    appStore.map,
    conceptType.value,
    properties.map((p) => p.type),
    identity
  );
});

const linkDescription = computed(function () {
  const { elidedRoleType, link_descriptor } = last(path.value) ?? {};
  if (elidedRoleType) {
    const elidedLabel = typeLabel(elidedRoleType);
    if (link_descriptor === LinkDescriptor.RelatedTo) return `As ${elidedLabel}:`;
    if (link_descriptor === LinkDescriptor.RoleOf) return `${elidedLabel}:`;
  }
  if (link_descriptor === LinkDescriptor.AsA) return "As";
  if (link_descriptor === LinkDescriptor.RoleOf) return "Role of";
  return null;
});

const children = computed(function () {
  return childPaths(path.value).map((path) => ({
    path,
    key: JSON.stringify(last(path)),
  }));
});

const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.ConceptType, {
    conceptPath: path.value,
  })
);

const derivedColumns = computed(function () {
  const qpath = expandTreePath(path.value);
  const cols = exploreStore
    .query!.columns.filter((column) => isEqual(column.path, qpath))
    .filter((column) => columnIsDerived(column));
  const groups = groupBy(cols, function (column) {
    const props = underlyingPropertyTypes(column.property_type);
    return props.length != 1 ? TOP_LEVEL_DERIVED_COLS : props[0];
  });
  return mapValues(groups, (cols) => cols.map((col) => col.alias));
});

const isExpanded = computed(() => isRoot.value || exploreStore.isPathExpanded(path.value));

const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function toggleExpanded() {
  exploreStore.togglePathExpanded(path.value);
}

function isResolved(propertyType: string): boolean {
  return hasResolutionClauseForTypes(appStore.map, conceptType.value, propertyType);
}

return (_ctx: any,_cache: any) => {
  const _component_TreeConcept = _resolveComponent("TreeConcept", true)!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isRoot.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Disclosure, {
            expanded: isExpanded.value,
            onToggle: toggleExpanded
          }, null, 8, ["expanded"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "rounded-full p-[3px]",
        style: _normalizeStyle({ backgroundColor: color.value })
      }, [
        _createVNode(Icon, {
          class: "icon",
          name: iconName.value,
          size: "l",
          color: "gray2"
        }, null, 8, ["name"])
      ], 4),
      _createVNode(_unref(Dropdown), {
        placement: "right",
        class: "mr-10 flex basis-full cursor-pointer items-center"
      }, {
        popper: _withCtx(() => [
          _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
            [_directive_close_popper]
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (linkDescription.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(linkDescription.value) + " ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_6, _toDisplayString(name.value), 1)
          ]),
          _createVNode(IconButton, {
            name: "chevron-down",
            size: "xs"
          })
        ]),
        _: 1
      })
    ]),
    (isExpanded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(derivedColumns.value[TOP_LEVEL_DERIVED_COLS], (alias) => {
            return (_openBlock(), _createBlock(TreeDerivedColumn, {
              alias: alias,
              key: alias
            }, null, 8, ["alias"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propertyTypes.value, (propertyType) => {
            return (_openBlock(), _createBlock(TreeProperty, {
              "property-type": propertyType,
              "derived-aliases": derivedColumns.value[propertyType] || [],
              "concept-path": _unref(path),
              key: propertyType,
              "is-resolved": isResolved(propertyType)
            }, null, 8, ["property-type", "derived-aliases", "concept-path", "is-resolved"]))
          }), 128)),
          (children.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(children.value, (child) => {
                  return (_openBlock(), _createBlock(_component_TreeConcept, {
                    path: child.path,
                    key: child.key
                  }, null, 8, ["path"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})