import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import { GeopointValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeopointValue',
  props: {
    value: {}
  },
  setup(__props: any) {

const props = __props;
const { value } = toRefs(props);

const linkUrl = computed(function () {
  const { lat, lon } = value.value.value; // uhh
  return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}&zoom=17`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: linkUrl.value,
    target: "_blank"
  }, _toDisplayString(_unref(value).value.lat) + ", " + _toDisplayString(_unref(value).value.lon), 9, _hoisted_1))
}
}

})