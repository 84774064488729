import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IconSize } from "../lib/icons";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Spinner',
  props: {
    size: { default: "xl" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Icon, {
    name: "spinner",
    size: _ctx.size,
    class: "opacity-40"
  }, null, 8, ["size"]))
}
}

})