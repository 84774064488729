import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "absolute inset-0 overflow-auto px-15" }
const _hoisted_2 = { class: "flex min-h-[36px] flex-row content-center gap-10" }
const _hoisted_3 = {
  key: 0,
  class: "rounded-full bg-gray-900 px-20 py-10 text-gray-200 shadow-snackbar"
}
const _hoisted_4 = { class: "p-10 font-monospace dark:bg-gray-900" }

import TextButton from "@/common/components/TextButton.vue";
import { asyncResultOr } from "@/common/lib/async";
import { sleep } from "@/common/lib/asynchronous";
import { useExploreStore } from "@/reader/stores/explore";
import { format as sql_format } from "sql-formatter";
import { getSegments, Segment } from "sql-highlight";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SqlView',
  setup(__props) {

const exploreStore = useExploreStore();

const displaySnackbar = ref(false);

const sqlData = computed(() => {
  const sql = asyncResultOr(exploreStore.sqlData, "");
  try {
    return sql_format(sql, {
      language: "spark",
      tabWidth: 4,
      keywordCase: "upper",
      linesBetweenQueries: 2,
    });
  } catch (error: unknown) {
    return sql;
  }
});

const sqlLines = computed(() => formatSql(sqlData.value));

function formatSql(sql: string): [Segment[], string][] {
  return sql.split(/\n/).map((line) => {
    const trimmed = line.trimStart();
    const indent = line.length - trimmed.length;
    const tokenized = getSegments(trimmed);
    return [tokenized, `${indent * 4}px`];
  });
}

async function copyContents() {
  await navigator.clipboard.writeText(sqlData.value);
  displaySnackbar.value = true;
  await sleep(2000);
  displaySnackbar.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TextButton, {
        label: "Copy SQL",
        class: "copy-button",
        onClick: copyContents
      }),
      (displaySnackbar.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Copied! "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sqlLines.value, ([line, indent], index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          style: _normalizeStyle({ marginLeft: indent })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (segment, segmentIndex) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `${index}:${segmentIndex}`,
              class: _normalizeClass(["select-text", 'sql-' + segment.name])
            }, _toDisplayString(segment.content), 3))
          }), 128)),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
        ], 4))
      }), 128))
    ])
  ]))
}
}

})