import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10" }

import { CountPropertyType, PropertyOpType } from "@/common/lib/derived";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CountPropertyDef',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const OP = PropertyOpType.Count;

const model = _useModel<CountPropertyType | null>(__props, "modelValue");

onMounted(() => (model.value = { op: OP, approx: false }));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, "Count of concepts or groups"))
}
}

})