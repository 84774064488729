import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "problems" }
const _hoisted_2 = { class: "select-text" }

import MessageBar from "@/common/components/MessageBar.vue";
import { asyncResultOr } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryProblems',
  setup(__props) {

const exploreStore = useExploreStore();
const problems = computed(() => asyncResultOr(exploreStore.problems, []));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(problems.value, (problem, index) => {
      return (_openBlock(), _createBlock(MessageBar, {
        mode: problem.level,
        key: index
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(problem.level === "warning" ? "Warning" : "Error"), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(problem.message), 1)
        ]),
        _: 2
      }, 1032, ["mode"]))
    }), 128))
  ]))
}
}

})