import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "input peer flex w-full flex-col items-start self-stretch" }
const _hoisted_2 = {
  key: 0,
  class: "text-s pb-5 pr-0 pt-5 font-bold text-gray-500 peer-focus:text-orange"
}
const _hoisted_3 = {
  key: 0,
  class: "text-orange"
}
const _hoisted_4 = {
  key: 1,
  class: "flex w-full justify-between pt-5"
}
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = {
  key: 1,
  class: "ml-auto flex"
}
const _hoisted_7 = {
  key: 2,
  class: "pt-5 text-xs text-gray-500"
}

import { computed, ref, toRefs, useSlots, watch } from "vue";
import vSelect, { VueSelectInstance } from "vue-select";
import { isString, isFunction, isEqual, isNil } from "lodash";
import { DropdownOption } from "../lib/comparators";
import TextButton from "@/common/components/TextButton.vue";

type SelectValue = string | object[] | string[] | object | null;


export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
    label: {},
    modelValue: {},
    placeholder: {},
    disabled: { type: Boolean },
    options: {},
    optionLabel: {},
    optionValue: {},
    required: { type: Boolean },
    clearable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    filter: {},
    helperText: {},
    delaySave: { type: Boolean, default: false },
    clearAll: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { modelValue, required } = toRefs(props);

const emit = __emit;
const slots = useSlots();

const handleUpdate = (newVal: SelectValue) => {
  if (props.delaySave) {
    selectedValues.value = newVal;
  } else {
    emit("update:modelValue", newVal);
  }
};

const selectedValues = ref(getValue(props.modelValue));

const hasSelectedValues = computed(() => {
  if (props.delaySave) {
    return Array.isArray(selectedValues.value) && selectedValues.value.length > 0;
  } else {
    return Array.isArray(modelValue.value) && modelValue.value.length > 0;
  }
});

const commitSelection = () => {
  emit("update:modelValue", selectedValues.value);
};

const resetSelection = () => {
  selectedValues.value = getValue(props.modelValue);
};

const clearSelection = () => {
  selectedValues.value = props.multiple ? [] : null;
  if (!props.delaySave) {
    commitSelection();
  }
};

const hasChanges = computed(() => {
  if (!props.delaySave) return false; // No delay save → no tracking needed
  return !isEqual(props.modelValue, selectedValues.value);
});

function getValue(value: SelectValue | undefined): SelectValue {
  if (isNil(value)) {
    return props.multiple ? [] : null;
  }
  return value;
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (props.delaySave) {
      selectedValues.value = getValue(newVal);
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label && (hasSelectedValues.value || _ctx.placeholder))
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_unref(required))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(vSelect), {
      class: "dropdown-options self-stretch",
      options: _ctx.options,
      "model-value": _ctx.delaySave ? selectedValues.value : _unref(modelValue),
      "onUpdate:modelValue": handleUpdate,
      placeholder: _ctx.placeholder || _ctx.label,
      label: _unref(isString)(_ctx.optionLabel) ? _ctx.optionLabel : undefined,
      "get-option-label": _unref(isFunction)(_ctx.optionLabel) ? _ctx.optionLabel : undefined,
      reduce: _ctx.optionValue,
      disabled: _ctx.disabled,
      clearable: _ctx.clearable,
      multiple: _ctx.multiple,
      filter: _ctx.filter
    }, {
      option: _withCtx((option) => [
        _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_guardReactiveProps(option)))
      ]),
      "selected-option": _withCtx((option) => [
        (_unref(slots)['selected-option-data'])
          ? _renderSlot(_ctx.$slots, "selected-option-data", _normalizeProps(_mergeProps({ key: 0 }, option)))
          : _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_mergeProps({ key: 1 }, option)))
      ]),
      _: 3
    }, 8, ["options", "model-value", "placeholder", "label", "get-option-label", "reduce", "disabled", "clearable", "multiple", "filter"]),
    (_ctx.delaySave || _ctx.clearAll)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (hasSelectedValues.value && _ctx.clearAll)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(TextButton, {
                  label: "Clear All",
                  onClick: clearSelection,
                  secondary: true
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.delaySave && hasChanges.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(TextButton, {
                  label: "Cancel",
                  onClick: resetSelection,
                  secondary: true
                }),
                _createVNode(TextButton, {
                  label: "Apply",
                  onClick: commitSelection
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.helperText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.helperText), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})