import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { Ref, onBeforeUnmount, ref, toRefs, watchEffect } from "vue";
import * as vega from "vega";
import * as vegaTooltip from "vega-tooltip";
import { extent } from "d3";
import { ConvertableNativeValue, toNative, toValue } from "../lib/value";
import { TRANSFORMERS } from "../lib/format";


export default /*@__PURE__*/_defineComponent({
  __name: 'Chart',
  props: {
    spec: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const chartEl: Ref<HTMLDivElement | null> = ref(null);
let vegaView: vega.View;

const props = __props;
const { spec } = toRefs(props);

const emit = __emit;

watchEffect(function () {
  if (chartEl.value != null) {
    const tooltipHandler = new vegaTooltip.Handler();

    // If we have more than a few of these, we'll make it more formal
    // This adds a function to check whether the extents of two arrays intersect
    vega.expressionFunction("collide", function (a1: number[], a2: number[]) {
      const e1 = extent(a1) as [number, number];
      const e2 = extent(a2) as [number, number];
      return (
        (e1[0] >= e2[0] && e1[0] <= e2[1]) ||
        (e1[1] >= e2[0] && e1[1] <= e2[1]) ||
        (e2[0] >= e1[0] && e2[0] <= e1[1]) ||
        (e2[1] >= e1[0] && e2[1] <= e1[1])
      );
    });
    vega.expressionFunction(
      "transform",
      function (value: ConvertableNativeValue, transformer: string) {
        return toNative(TRANSFORMERS[transformer](toValue(value)));
      }
    );

    vegaView = new vega.View(vega.parse(spec.value), {
      renderer: "svg",
      container: chartEl.value,
      hover: true,
    });
    // If you want to offer selection, have your spec include a signal called
    // "selection", defaulting to null and set to the selected value upon the
    // appropriate event. Here we'll set it back to null once handled so that
    // the same item can be selected consecutively.
    if (Object.hasOwn(vegaView.getState().signals, "selection")) {
      vegaView.addSignalListener("selection", function (_, value) {
        if (value !== null) {
          emit("select", value);
          vegaView.signal("selection", null).runAsync();
        }
      });
    }
    vegaView.tooltip(tooltipHandler.call);
    vegaView.runAsync();
  }
});

onBeforeUnmount(() => vegaView?.finalize());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "chartEl",
    ref: chartEl
  }, null, 512))
}
}

})