import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-1 flex-col justify-end" }
const _hoisted_2 = { class: "mt-[-2px] flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 pl-15 pr-[2px]" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-0 flex flex-col"
}
const _hoisted_4 = { class: "flex flex-row gap-[8px] py-[5px] pl-[12px] pr-[2px]" }
const _hoisted_5 = { class: "flex w-full flex-col gap-[8px]" }
const _hoisted_6 = { class: "flex min-h-[20px] flex-row items-center gap-[8px]" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "min-h-[20px]"
}
const _hoisted_10 = {
  key: 1,
  class: "text-center text-gray-100 opacity-[0.6]"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "pb-10 pt-5"
}
const _hoisted_13 = {
  key: 1,
  class: "text-center"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 px-15" }

import Icon from "@/common/components/Icon.vue";
import SourceCatalog from "@/common/components/SourceCatalog.vue";
import { SourceSelection, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { computed, onMounted, ref, watch } from "vue";
import { ProviderType, useWorkspaceStore, WorkspacesState } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import IconButton from "./IconButtonV2.vue";
import { environment } from "@/common/environments/environmentLoader";
import ResizeWrapper from "@/editor/components/ResizeWrapper.vue";
import { AsyncStatus } from "../lib/async";
import Dialog from "@/common/components/Dialog.vue";
import { useRoute } from "vue-router";
import { useNavigation } from "@/common/composables/useNavigation";
import MessageBar from "./MessageBar.vue";
import WorkspaceChooser from "./WorkspaceChooser.vue";
import WarehouseChooser from "./WarehouseChooser.vue";
import WarehouseStatus from "./WarehouseStatus.vue";
import Spinner from "./Spinner.vue";
import TextButton from "./TextButton.vue";
import { useDynamicTimer } from "../composables/useDynamicTimer";

const HEADER_HEIGHT = 32;
const CONNECTIONS_URL = "/home/connections";


export default /*@__PURE__*/_defineComponent({
  __name: 'SourceBrowser',
  props: {
    startExpanded: { type: Boolean, default: true },
    parentHeight: {},
    allowWorkspaceSwitching: { type: Boolean, default: false }
  },
  emits: ["select-tables", "expanded"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const height = ref(props.parentHeight ?? 0);
const showServerDetails = ref(false);
const deletedWarehouseWarning = ref(false);
const sourceBrowserExpanded = ref(props.startExpanded);
const maxHeight = ref(props.parentHeight);

const route = useRoute();
const sourceBrowserStore = useSourceBrowserStore();
const workspaceStore = useWorkspaceStore();
const workspaceStoreRefs = storeToRefs(workspaceStore);
const {
  currentWarehouse,
  warehouses,
  workspaces,
  currentWorkspaceId,
  currentWorkspace,
  workspacesState,
  canOpenCurrentWorkspace,
} = workspaceStoreRefs;

__expose({ sourceBrowserExpanded });

function handleHeightUpdate(newHeight: number) {
  height.value = newHeight - HEADER_HEIGHT;
}

function toggleExpanded() {
  sourceBrowserExpanded.value = !sourceBrowserExpanded.value;
  emit("expanded", sourceBrowserExpanded.value);
}

function toggleServerDetails() {
  showServerDetails.value = !showServerDetails.value;
}

const workspace = computed(() => {
  return workspaceStoreRefs.currentWorkspaceSync?.value;
});

const workspaceIcon = computed(() => providerIcon(workspace.value?.provider));

function reconnect() {
  useNavigation().goToUrl(CONNECTIONS_URL);
}

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

function refreshTables() {
  const workspaceId = currentWorkspaceId?.value;
  if (!workspaceId) {
    return;
  }
  sourceBrowserStore.loadSystemTables(workspaceId, true);
}

function openWorkspace() {
  workspaceStore.openCurrentWorkspace();
}

function selectTables(selection: SourceSelection[]) {
  emit("select-tables", selection);
}

const timer = useDynamicTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => props.parentHeight,
  (newVal) => {
    maxHeight.value = newVal;
    height.value = newVal ?? 0;
  }
);

watch(
  () => workspace,
  () => {
    refreshTables;
  }
);

watch(
  () => currentWarehouse?.value?.state,
  (newWarehouseState, oldWarehouseState) => {
    if (
      newWarehouseState === "DELETED" &&
      newWarehouseState !== oldWarehouseState &&
      route.path !== "/connections"
    ) {
      deletedWarehouseWarning.value = true;
    }
  }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
  timer.start(async () => {
    await workspaceStore.refreshCurrentWarehouse();
    if (workspaceStore.currentWarehouse?.state === "RUNNING") {
      return environment.requireNumber("WAREHOUSE_PERIODIC_CHECK_WAIT_MS");
    }
    return environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS");
  });

  maxHeight.value = props.parentHeight ?? 0;
  height.value = maxHeight.value * 0.85;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (sourceBrowserExpanded.value)
        ? (_openBlock(), _createBlock(ResizeWrapper, {
            key: 0,
            vertical: "top",
            "onUpdate:height": handleHeightUpdate,
            "minimum-length": HEADER_HEIGHT,
            "maximum-length": maxHeight.value,
            initialHeight: String(height.value),
            identifier: "SourceBrowser.resources"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-base font-bold" }, "Source Browser", -1)),
                _createVNode(IconButton, {
                  name: "md:bottom_panel_close",
                  "data-test": "expand-sources",
                  onClick: toggleExpanded,
                  size: "l",
                  targetSize: 32
                })
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["relative", {
          'flex-grow transition-all duration-500 ease-[cubic-bezier(0,1,0.5,1)]':
            sourceBrowserExpanded.value,
          'flex-grow-0 duration-500': !sourceBrowserExpanded.value,
        }]),
                style: _normalizeStyle({
          height: height.value > 0 ? height.value + 'px' : '100%',
        })
              }, [
                (_unref(workspacesState) !== _unref(WorkspacesState).Loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(IconButton, {
                          name: showServerDetails.value ? 'md:collapse_all' : 'md:expand_all',
                          label: showServerDetails.value ? 'Collapse' : 'Expand',
                          size: "l",
                          targetSize: 24,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleServerDetails()))
                        }, null, 8, ["name", "label"]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            (workspaceIcon.value)
                              ? (_openBlock(), _createBlock(Icon, {
                                  key: 0,
                                  name: workspaceIcon.value
                                }, null, 8, ["name"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", null, _toDisplayString(workspace.value?.nickname ?? "No current workspace"), 1),
                              (workspace.value && !workspace.value.connected)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(disconnected)"))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          (workspace.value?.connected)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_unref(currentWarehouse))
                                  ? (_openBlock(), _createBlock(WarehouseStatus, {
                                      key: 0,
                                      warehouse: _unref(currentWarehouse)
                                    }, null, 8, ["warehouse"]))
                                  : (
                    _unref(warehouses).status === _unref(AsyncStatus).Succeeded && _unref(warehouses).result.length === 0
                  )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, " No warehouses "))
                                    : (_openBlock(), _createBlock(Spinner, { key: 2 }))
                              ]))
                            : _createCommentVNode("", true),
                          (showServerDetails.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                (_ctx.allowWorkspaceSwitching)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                      _createVNode(WorkspaceChooser)
                                    ]))
                                  : _createCommentVNode("", true),
                                (workspace.value)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      class: _normalizeClass(["min-h-[58px]", { 'pt-10': !_ctx.allowWorkspaceSwitching }])
                                    }, [
                                      _createVNode(WarehouseChooser)
                                    ], 2))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        (_unref(canOpenCurrentWorkspace))
                          ? (_openBlock(), _createBlock(IconButton, {
                              key: 0,
                              name: "md:open_in_new",
                              label: "Open workspace",
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (openWorkspace()), ["stop"])),
                              size: "l",
                              targetSize: 32
                            }))
                          : _createCommentVNode("", true),
                        (workspace.value?.connected)
                          ? (_openBlock(), _createBlock(IconButton, {
                              key: 1,
                              name: "md:restart_alt",
                              label: "Refresh catalog",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (refreshTables()), ["stop"])),
                              size: "l",
                              targetSize: 32
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      (_unref(workspaces).status === _unref(AsyncStatus).Failed)
                        ? (_openBlock(), _createBlock(MessageBar, {
                            key: 0,
                            mode: "error"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Error loading workspaces: " + _toDisplayString(_unref(workspaces).message), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(warehouses).status === _unref(AsyncStatus).Failed)
                        ? (_openBlock(), _createBlock(MessageBar, {
                            key: 1,
                            mode: "error"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Error loading warehouses: " + _toDisplayString(_unref(warehouses).message), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(currentWorkspace).status === _unref(AsyncStatus).Failed)
                        ? (_openBlock(), _createBlock(MessageBar, {
                            key: 2,
                            mode: "error"
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode(" Error loading current workspace ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (workspace.value?.connected === false)
                        ? (_openBlock(), _createBlock(MessageBar, {
                            key: 3,
                            mode: "warning",
                            class: "mt-10"
                          }, {
                            actions: _withCtx(() => [
                              _createVNode(TextButton, {
                                label: "Reconnect",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (reconnect())),
                                mode: "warning"
                              })
                            ]),
                            default: _withCtx(() => [
                              _cache[8] || (_cache[8] = _createTextVNode(" Workspace is not connected "))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(currentWorkspaceId) && workspace.value?.connected)
                        ? (_openBlock(), _createBlock(SourceCatalog, {
                            key: 4,
                            "workspace-id": _unref(currentWorkspaceId),
                            onSelectTables: selectTables
                          }, null, 8, ["workspace-id"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_unref(workspacesState) == _unref(WorkspacesState).Loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(Spinner)
                      ]))
                    : _createCommentVNode("", true)
              ], 6)
            ]),
            _: 1
          }, 8, ["maximum-length", "initialHeight"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-base font-bold" }, "Source Browser", -1)),
              _createVNode(IconButton, {
                name: "md:top_panel_close",
                onClick: toggleExpanded,
                size: "xl",
                targetSize: 32
              })
            ])
          ]))
    ]),
    (deletedWarehouseWarning.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: "DELETED WAREHOUSE",
          "success-label": "Go to settings",
          onSucceeded: _cache[4] || (_cache[4] = ($event: any) => (_unref(useNavigation)().goToUrl(CONNECTIONS_URL))),
          onCancelled: _cache[5] || (_cache[5] = ($event: any) => (deletedWarehouseWarning.value = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(currentWarehouse)?.name) + " has been deleted. You must select a new warehouse to continue. ", 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})