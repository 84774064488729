<template>
  <div class="absolute inset-0 flex">
    <div
      ref="containerEl"
      class="relative m-15 basis-full overflow-hidden"
      @mousemove="updateDropdownPosition"
    >
      <ResizeObserver :emit-on-mount="true" @notify="handleResize" />
      <AsyncWrapper :async="queryResults" v-if="isValid">
        <template v-if="isDone">
          <component
            v-if="!isEmpty"
            :is="vizComponent"
            :visualization="visualization"
            :results="results"
            :width="width"
            :height="height"
            @select="handleSelection"
          />
          <div
            class="absolute h-1 w-1"
            :style="{
              left: `${dropdownX}px`,
              top: `${dropdownY}px`,
              pointerEvents: dropdownShown ? 'all' : 'none',
            }"
          >
            <Dropdown v-model:shown="dropdownShown" :triggers="[]">
              <template #popper>
                <Menu :items="menuItems" v-close-popper />
              </template>
            </Dropdown>
          </div>

          <div
            v-if="isEmpty"
            class="absolute inset-0 flex flex-col items-center justify-center text-center text-gray-500"
          >
            <div class="text-xxl">No results</div>
            <div class="mt-5">Consider changing or removing filters.</div>
          </div>
        </template>
      </AsyncWrapper>
      <div
        v-else
        class="absolute inset-0 flex items-center justify-center text-center text-xxxl text-gray-500"
      >
        To visualize your data, please make all required selections at right.
      </div>
    </div>
    <div
      class="relative m-15 basis-[400px] rounded-md border border-gray-200 bg-gray-100 dark:border-gray-700 dark:bg-gray-800"
    >
      <VisualizationConfig
        v-model:type="exploreStore.visualizationType"
        v-model:config="exploreStore.visualizationConfig"
        :query="exploreStore.query!"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useExploreStore } from "@/reader/stores/explore";
import VisualizationConfig from "../viz-config/VisualizationConfig.vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { computed, Ref, ref } from "vue";
import { ResizeObserver } from "vue-resize";
import { every, isArray, isUndefined, omitBy } from "lodash";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { propertyValueMenu } from "@/reader/lib/exploreMenus";
import { MenuItem } from "@/common/lib/menus";
import { findDeepColumnByAlias } from "@/common/lib/query";
import { formatValue } from "@/common/lib/format";
import { GraphValue } from "@/common/lib/value";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import { UserAction } from "@/common/lib/userAction";

const width = ref(0);
const height = ref(0);
const dropdownX = ref(0);
const dropdownY = ref(0);
const menuItems: Ref<MenuItem[]> = ref([]);
const containerEl: Ref<HTMLDivElement | null> = ref(null);
const dropdownShown = ref(false);

const appStore = useAppStore();
const exploreStore = useExploreStore();

const { isEmpty, results, queryResults, isDone } = useQuery(
  appStore.module!,
  () => exploreStore.query!,
  UserAction.Load_Visualization
);

const isValid = computed(function () {
  const config = exploreStore.visualizationConfig;
  if (exploreStore.visualizationType == null || config == null) return false;
  const configDef = VISUALIZATION_CONFIG_DEFS[exploreStore.visualizationType];
  const requiredFields = configDef.controls.filter((c) => !c.optional).map((c) => c.key);
  return every(requiredFields, (f) => config[f] != null);
});

const vizComponent = computed(() => `visualization-${exploreStore.visualizationType}`);

const visualization = computed(() => ({
  type: exploreStore.visualizationType!,
  title: "",
  query: exploreStore.query!,
  config: omitBy(exploreStore.visualizationConfig!, isUndefined),
}));

function handleResize({ width: x, height: y }: { width: number; height: number }) {
  width.value = x;
  height.value = y;
}

function updateDropdownPosition(event: MouseEvent) {
  if (containerEl.value == null) return;
  const containerPos = containerEl.value.getBoundingClientRect();
  dropdownX.value = event.clientX - containerPos.x;
  dropdownY.value = event.clientY - containerPos.y;
}

function handleSelection(alias: string, value: GraphValue | [GraphValue, GraphValue] | null) {
  if (isArray(value)) {
    // TODO Support ranges
  } else if (value == null) {
    // TODO Support non-exists filtering
  } else {
    const column = findDeepColumnByAlias(exploreStore.query!, alias);
    const fvalue = formatValue(column!.property_type, value);
    menuItems.value = propertyValueMenu(fvalue.originalValue, fvalue.formattedValue, column);
    dropdownShown.value = true;
  }
}
</script>
