import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { PropertyValueGenerator, ValueGeneratorType } from "@/reader/lib/visualization";
import Select from "@/common/components/Select.vue";
import { ColumnVisualizationConfigControl } from "@/reader/lib/visualizationConfig";
import { columnName, findDeepColumnByAlias, Query } from "@/common/lib/query";
import { computed, Ref, toRefs } from "vue";
import { propertyValueType } from "@/common/lib/derived";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationColumnControl',
  props: /*@__PURE__*/_mergeModels({
    control: {},
    query: {}
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;
const { query, control } = toRefs(props);

const model = _useModel<PropertyValueGenerator | undefined>(__props, "modelValue");

const columns: Ref<PropertyValueGenerator[]> = computed(function () {
  let columns = query.value.columns;
  const limit = control.value.config.limit_value_types;
  if (limit != null) {
    columns = columns.filter((col) => limit.includes(propertyValueType(col.property_type)));
  }
  return columns.map((c) => ({
    type: ValueGeneratorType.Property,
    alias: c.alias,
  }));
});

function columnLabel(pvg: PropertyValueGenerator) {
  return columnName(findDeepColumnByAlias(query.value, pvg.alias)!);
}

function setModel(newValue: PropertyValueGenerator | undefined | null) {
  if (newValue === null) newValue = undefined;
  model.value = newValue;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Select, {
    label: _unref(control).label,
    options: columns.value,
    "model-value": model.value,
    "onUpdate:modelValue": setModel,
    "option-label": columnLabel,
    required: !_unref(control).optional,
    "helper-text": _unref(control).description,
    placeholder: "Select a column"
  }, null, 8, ["label", "options", "model-value", "required", "helper-text"]))
}
}

})