import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative mb-15" }
const _hoisted_2 = { class: "mb-15" }
const _hoisted_3 = { class: "relative mb-20" }
const _hoisted_4 = { class: "flex items-center justify-between rounded-full bg-gray-200 pl-15 pr-5 dark:bg-gray-900" }
const _hoisted_5 = { class: "relative mb-20" }
const _hoisted_6 = { class: "mb-10 flex items-center justify-between" }

import { useExploreStore } from "@/reader/stores/explore";
import { useExploreMetagraphStore } from "@/reader/stores/exploreMetagraph";
import { computed } from "vue";
import { compact } from "lodash";
import OrderBy from "./OrderBy.vue";
import GroupBy from "./GroupBy.vue";
import Metatree from "./metatree/Metatree.vue";
import ListBuilder from "@/common/components/ListBuilder.vue";
import Toggle from "@/common/components/Toggle.vue";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import ConceptSelector from "@/common/components/ConceptSelector.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryBasics',
  setup(__props) {

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();

const conceptTypes = computed(() => exploreStore.metagraph.concepts.map((concept) => concept.type));

function toggleMetagraph() {
  exploreMetagraphStore.visible = !exploreMetagraphStore.visible;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(IconButtonV2, {
        name: "md:hub",
        color: "white",
        class: "absolute right-0 top-[-7px]",
        onClick: toggleMetagraph,
        size: "l"
      }),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-10 text-lg font-medium text-gray-500" }, "Perspective", -1)),
      _createVNode(ConceptSelector, {
        "concept-ids": conceptTypes.value,
        "model-value": _unref(exploreStore).query?.root_concept_type,
        "include-adhoc": true,
        "onUpdate:modelValue": _unref(exploreStore).setRootConceptType,
        placeholder: "Choose concept type",
        "data-test": "concept-select",
        "icon-color": "gray2"
      }, null, 8, ["concept-ids", "model-value", "onUpdate:modelValue"])
    ]),
    (_unref(exploreStore).query != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_2, [
            _createVNode(ListBuilder, {
              title: "Sort by",
              "model-value": _unref(exploreStore).query.order_by,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (values) => _unref(exploreStore).setOrderBy(_unref(compact)(values)))
            }, {
              default: _withCtx(({ item, updateItem }) => [
                _createVNode(OrderBy, {
                  "model-value": item,
                  "onUpdate:modelValue": updateItem
                }, null, 8, ["model-value", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["model-value"])
          ]),
          _createElementVNode("section", _hoisted_3, [
            (_unref(exploreStore).query!.group_by === _unref(GROUP_BY_ALL))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-10 text-lg font-medium text-gray-500" }, "Group By", -1)),
                  _createElementVNode("div", _hoisted_4, [
                    _cache[5] || (_cache[5] = _createTextVNode(" Summarize All ")),
                    _createVNode(IconButtonV2, {
                      name: "delete",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(exploreStore).setGroupBy([])))
                    })
                  ])
                ], 64))
              : (_openBlock(), _createBlock(ListBuilder, {
                  key: 1,
                  title: "Group by",
                  "data-test": "add-group-by",
                  "model-value": _unref(exploreStore).groupByColumns,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = (values) => _unref(exploreStore).setGroupBy(_unref(compact)(values)))
                }, {
                  default: _withCtx(({ item, updateItem }) => [
                    _createVNode(GroupBy, {
                      "model-value": item,
                      "onUpdate:modelValue": updateItem,
                      "data-test": "group-by"
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }, 8, ["model-value"]))
          ]),
          _createElementVNode("section", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-lg font-medium text-gray-500" }, "Included data", -1)),
              _createVNode(Toggle, {
                modelValue: _unref(exploreStore).hideUnusedProperties,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(exploreStore).hideUnusedProperties) = $event)),
                label: "Hide Unused"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(Metatree)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})