import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src", "width", "height"]

import { toRefs, computed, inject, Ref } from "vue";
import { parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'TextButton',
  props: {
    label: {},
    disabled: { type: Boolean },
    secondary: { type: Boolean },
    icon: {},
    selected: { type: Boolean },
    darkHover: { type: Boolean },
    mode: {},
    menu: { type: Boolean, default: false },
    textColor: {},
    pill: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const { icon, menu } = toRefs(props);

const darkMode = inject(DarkMode) as Ref<boolean>;

const iconSrc = computed(() => (icon.value ? parseIconName(icon.value) : undefined));
const sizePx = computed(() => (menu.value ? 20 : 16));
const textColor = computed(function () {
  if (props.disabled) {
    return darkMode.value ? "gray-700" : "gray-200";
  }
  if (props.textColor) {
    return props.textColor;
  }
  if (props.selected) {
    return darkMode.value ? "gray-200" : "white";
  }
  if (props.secondary) {
    return "gray-500";
  }
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  if (props.mode === "error") {
    return "red-500";
  }
  if (props.mode === "warning") {
    return "yellow-500";
  }
  return "orange";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["inline-flex cursor-pointer items-center justify-center gap-5 text-center", [
      `text-${textColor.value}`,
      {
        'active:shadow-none hover:bg-gray-200 hover:shadow-light active:bg-gray-100 dark:hover:bg-gray-900 dark:active:bg-black':
          !(_ctx.disabled || _ctx.darkHover),
        'border-none text-lg uppercase': !_unref(icon) && !_ctx.pill,
        'border border-solid text-base normal-case': _unref(icon),
        'hover:shadow-none hover:text-gray-700 dark:hover:text-white dark:active:border-black':
          _unref(icon) && !_ctx.disabled,
        'border-gray-200 dark:border-gray-900': !_ctx.selected && _unref(icon),
        'bg-transparent': !_ctx.selected && !_ctx.pill,
        'shadow-none border-orange bg-orange hover:bg-orange hover:text-white': _ctx.selected,
        'active:bg-gray hover:bg-black': _ctx.darkHover && !_ctx.disabled,
        'hover:bg-yellow-500 hover:text-white active:bg-yellow-900 dark:hover:bg-yellow-500 dark:hover:text-white':
          _ctx.mode === 'warning' && !_ctx.disabled,
        'hover:bg-red-500 hover:text-white active:bg-red-900 dark:hover:bg-red-500 dark:hover:text-white':
          _ctx.mode == 'error' && !_ctx.disabled,
        'hover:bg-orange hover:text-white active:bg-gray-900 dark:hover:bg-orange dark:hover:text-white':
          _ctx.mode == 'generic' && !_ctx.disabled,
        'gap-10': _unref(menu),
        'cursor-not-allowed opacity-100': _ctx.disabled,
        'rounded px-[6px] py-[4px]': !_ctx.pill,
        'rounded-[20px] p-5': _ctx.pill,
        'bg-gray-200 dark:bg-gray-900': _ctx.pill && !_ctx.disabled,
      },
    ]]),
    disabled: _ctx.disabled
  }, [
    (_unref(icon))
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["pointer-events-none block", {
        'svg-to-orange': !(_ctx.disabled || _ctx.selected),
        'svg-to-dark-gray': _unref(darkMode) && _ctx.disabled,
        'svg-to-light-gray': !_unref(darkMode) && _ctx.disabled,
        'svg-to-black active:svg-to-orange': _unref(darkMode) && _ctx.selected,
        'svg-to-dark-gray active:svg-to-light-gray': !_unref(darkMode) && _ctx.selected,
      }]),
          src: iconSrc.value,
          width: sizePx.value,
          height: sizePx.value
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ], 10, _hoisted_1))
}
}

})