import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'ProviderButton',
  props: {
    imgSrc: {},
    height: {},
    static: { type: Boolean },
    disabled: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["flex h-[75px] w-auto cursor-default items-center justify-around rounded border border-gray-700 bg-gray-800", {
      'cursor-pointer hover:border-orange hover:bg-gray-900 active:bg-black': !_ctx.static && !_ctx.disabled,
      'cursor-not-allowed opacity-50': _ctx.disabled,
    }]),
    disabled: _ctx.disabled
  }, [
    _createElementVNode("img", {
      src: _ctx.imgSrc,
      style: _normalizeStyle({ height: _ctx.height })
    }, null, 12, _hoisted_2)
  ], 10, _hoisted_1))
}
}

})