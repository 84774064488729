import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex" }
const _hoisted_2 = {
  key: 1,
  class: "absolute inset-0 flex flex-col items-center justify-center text-center text-gray-500"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute inset-0 flex items-center justify-center text-center text-xxxl text-gray-500"
}
const _hoisted_4 = { class: "relative m-15 basis-[400px] rounded-md border border-gray-200 bg-gray-100 dark:border-gray-700 dark:bg-gray-800" }

import { useExploreStore } from "@/reader/stores/explore";
import VisualizationConfig from "../viz-config/VisualizationConfig.vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { computed, Ref, ref } from "vue";
import { ResizeObserver } from "vue-resize";
import { every, isArray, isUndefined, omitBy } from "lodash";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { propertyValueMenu } from "@/reader/lib/exploreMenus";
import { MenuItem } from "@/common/lib/menus";
import { findDeepColumnByAlias } from "@/common/lib/query";
import { formatValue } from "@/common/lib/format";
import { GraphValue } from "@/common/lib/value";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'Visualization',
  setup(__props) {

const width = ref(0);
const height = ref(0);
const dropdownX = ref(0);
const dropdownY = ref(0);
const menuItems: Ref<MenuItem[]> = ref([]);
const containerEl: Ref<HTMLDivElement | null> = ref(null);
const dropdownShown = ref(false);

const appStore = useAppStore();
const exploreStore = useExploreStore();

const { isEmpty, results, queryResults, isDone } = useQuery(
  appStore.module!,
  () => exploreStore.query!,
  UserAction.Load_Visualization
);

const isValid = computed(function () {
  const config = exploreStore.visualizationConfig;
  if (exploreStore.visualizationType == null || config == null) return false;
  const configDef = VISUALIZATION_CONFIG_DEFS[exploreStore.visualizationType];
  const requiredFields = configDef.controls.filter((c) => !c.optional).map((c) => c.key);
  return every(requiredFields, (f) => config[f] != null);
});

const vizComponent = computed(() => `visualization-${exploreStore.visualizationType}`);

const visualization = computed(() => ({
  type: exploreStore.visualizationType!,
  title: "",
  query: exploreStore.query!,
  config: omitBy(exploreStore.visualizationConfig!, isUndefined),
}));

function handleResize({ width: x, height: y }: { width: number; height: number }) {
  width.value = x;
  height.value = y;
}

function updateDropdownPosition(event: MouseEvent) {
  if (containerEl.value == null) return;
  const containerPos = containerEl.value.getBoundingClientRect();
  dropdownX.value = event.clientX - containerPos.x;
  dropdownY.value = event.clientY - containerPos.y;
}

function handleSelection(alias: string, value: GraphValue | [GraphValue, GraphValue] | null) {
  if (isArray(value)) {
    // TODO Support ranges
  } else if (value == null) {
    // TODO Support non-exists filtering
  } else {
    const column = findDeepColumnByAlias(exploreStore.query!, alias);
    const fvalue = formatValue(column!.property_type, value);
    menuItems.value = propertyValueMenu(fvalue.originalValue, fvalue.formattedValue, column);
    dropdownShown.value = true;
  }
}

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "containerEl",
      ref: containerEl,
      class: "relative m-15 basis-full overflow-hidden",
      onMousemove: updateDropdownPosition
    }, [
      _createVNode(_unref(ResizeObserver), {
        "emit-on-mount": true,
        onNotify: handleResize
      }),
      (isValid.value)
        ? (_openBlock(), _createBlock(AsyncWrapper, {
            key: 0,
            async: _unref(queryResults)
          }, {
            default: _withCtx(() => [
              (_unref(isDone))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_unref(isEmpty))
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(vizComponent.value), {
                          key: 0,
                          visualization: visualization.value,
                          results: _unref(results),
                          width: width.value,
                          height: height.value,
                          onSelect: handleSelection
                        }, null, 40, ["visualization", "results", "width", "height"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "absolute h-1 w-1",
                      style: _normalizeStyle({
              left: `${dropdownX.value}px`,
              top: `${dropdownY.value}px`,
              pointerEvents: dropdownShown.value ? 'all' : 'none',
            })
                    }, [
                      _createVNode(_unref(Dropdown), {
                        shown: dropdownShown.value,
                        "onUpdate:shown": _cache[0] || (_cache[0] = ($event: any) => ((dropdownShown).value = $event)),
                        triggers: []
                      }, {
                        popper: _withCtx(() => [
                          _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
                            [_directive_close_popper]
                          ])
                        ]),
                        _: 1
                      }, 8, ["shown"])
                    ], 4),
                    (_unref(isEmpty))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
                          _createElementVNode("div", { class: "text-xxl" }, "No results", -1),
                          _createElementVNode("div", { class: "mt-5" }, "Consider changing or removing filters.", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["async"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, " To visualize your data, please make all required selections at right. "))
    ], 544),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(VisualizationConfig, {
        type: _unref(exploreStore).visualizationType,
        "onUpdate:type": _cache[1] || (_cache[1] = ($event: any) => ((_unref(exploreStore).visualizationType) = $event)),
        config: _unref(exploreStore).visualizationConfig,
        "onUpdate:config": _cache[2] || (_cache[2] = ($event: any) => ((_unref(exploreStore).visualizationConfig) = $event)),
        query: _unref(exploreStore).query!
      }, null, 8, ["type", "config", "query"])
    ])
  ]))
}
}

})