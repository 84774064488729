import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "py-15 pl-20 pr-15" }
const _hoisted_2 = { class: "max-h-fit flex-initial basis-full overflow-y-auto" }
const _hoisted_3 = { class: "overflow-y-auto" }
const _hoisted_4 = {
  class: "mx-0 my-10 max-h-fit select-none",
  "data-test": "system-resources"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex items-center gap-10 self-stretch" }
const _hoisted_7 = { class: "flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all font-medium text-gray-200" }
const _hoisted_8 = { class: "text-right text-sm text-gray-500" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex items-center gap-10 self-stretch" }
const _hoisted_11 = { class: "flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all text-base text-gray-200" }
const _hoisted_12 = { class: "text-right text-sm text-gray-500" }
const _hoisted_13 = ["data-test", "onClick"]
const _hoisted_14 = { class: "flex items-center gap-5 self-stretch break-all" }
const _hoisted_15 = { class: "max-w-[300px]" }
const _hoisted_16 = { class: "mb-5 break-all" }
const _hoisted_17 = { class: "flex flex-row justify-between" }
const _hoisted_18 = {
  key: 0,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_19 = {
  key: 1,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_20 = {
  key: 2,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_21 = {
  key: 0,
  class: "p-10 text-center text-gray-500"
}
const _hoisted_22 = {
  key: 1,
  class: "p-10 text-center text-gray-500"
}
const _hoisted_23 = {
  key: 1,
  class: "text-center"
}
const _hoisted_24 = { key: 2 }
const _hoisted_25 = {
  key: 0,
  class: "sticky bottom-0 m-20 ml-30 flex items-center bg-gray-800"
}
const _hoisted_26 = { class: "flex-initial basis-full text-gray-200" }

import Icon from "@/common/components/Icon.vue";
import MessageBar from "@/common/components/MessageBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { AsyncFailed, asyncResultOr, AsyncStatus } from "@/common/lib/async";
import Disclosure from "@/common/components/Disclosure.vue";
import Spinner from "@/common/components/Spinner.vue";
import { Catalog, Schema, Table } from "@/common/stores/catalog";
import { SourceSelection, SystemTable, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import pluralize from "pluralize";
import { computed, onMounted, toRefs } from "vue";
import { Tooltip } from "floating-vue";
import { every, find } from "lodash";
import { environment } from "../environments/environmentLoader";
import { formatBytes } from "@/common/lib/text";
import { getMapSection, MapSectionKey, SourceType } from "@/common/lib/map";
import Textbox from "./Textbox.vue";
import useDataset from "@/editor/composables/useDataset";
import { useAppStore } from "@/editor/stores/app";


export default /*@__PURE__*/_defineComponent({
  __name: 'SourceCatalog',
  props: {
    workspaceId: {}
  },
  emits: ["select-tables"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { workspaceId } = toRefs(props);

const emit = __emit;

const sourceBrowserStore = useSourceBrowserStore();
const appStore = useAppStore();

const status = computed(() => sourceBrowserStore.catalogs.status);
const provider = computed(() => sourceBrowserStore.provider);
const catalogs = computed(() => {
  const catalogs = asyncResultOr(sourceBrowserStore.catalogs, []);
  const filteredCatalogs = filterCatalogs(catalogs, sourceBrowserStore.search);
  return filteredCatalogs;
});
const hasSearch = computed(() => sourceBrowserStore.search.length > 0);

function filterCatalogs(catalogs: Catalog[], searchString: string): Catalog[] {
  if (searchString === "") {
    return catalogs;
  }
  return catalogs.flatMap((catalog) => {
    const schemas = catalog.schemas.flatMap((s) => filterSchema(s, searchString, catalog.name));
    if (schemas.length === 0) {
      return [];
    }
    catalog = Object.assign({}, catalog, { schemas });
    return [catalog];
  });
}

function filterSchema(schema: Schema, searchString: string, catalogName: string): Schema[] {
  const tables = schema.tables.filter((t) =>
    filterTable(t.name, searchString, catalogName, schema.name)
  );
  if (tables.length === 0) {
    return [];
  }
  schema = Object.assign({}, schema, { tables });
  return [schema];
}

function listSystemTables(provider: SourceType, catalog: Catalog, schema: Schema): SystemTable[] {
  return schema.tables.map((t) => toSystemTable(provider, catalog, schema, t));
}

function toSystemTable(
  provider: SourceType,
  catalog: Catalog,
  schema: Schema,
  table: Table
): SystemTable {
  const loaded = loadedPaths.value.includes(`${catalog.name}.${schema.name}.${table.name}`);
  return {
    provider,
    catalog: catalog.name,
    schema: schema.name,
    group: `${catalog.name}:${schema.name}`,
    name: table.name,
    table,
    loaded,
  };
}

function filterTable(
  tableName: string,
  searchString: string,
  catalogName: string,
  schemaName: string
): boolean {
  const searchTokens = searchString.toLowerCase().split(/ +/);
  const names = [tableName.toLowerCase()];
  if (environment.requireBoolean("SOURCES_SEARCH_CATALOGS_AND_SCHEMAS")) {
    names.push(schemaName.toLowerCase(), catalogName.toLowerCase());
  }
  return every(searchTokens, (token) => find(names, (name) => name.includes(token)));
}

function toggleSelected(table: SystemTable) {
  if (table.loaded) {
    return;
  }
  sourceBrowserStore.toggleSelected(table);
}

function toggleGroupCollapsed(group: string) {
  sourceBrowserStore.toggleGroupCollapsed(group);
}

const isSelected = (table: SystemTable) => sourceBrowserStore.isSelected(table);

const isGroupExpanded = (group: string) => sourceBrowserStore.collapsedGroups.includes(group);

function rowCount(rowCount: number): string | undefined {
  if (!rowCount) {
    return undefined;
  }
  return `${rowCount.toLocaleString()} ${pluralize("row", rowCount)}`;
}

function refreshTables() {
  sourceBrowserStore.loadSystemTables(workspaceId.value, true);
}

function commitTables() {
  emit("select-tables", sourceBrowserStore.selection);
}

const selectionCount = computed(() => sourceBrowserStore.selection.length);

const loadedPaths = computed(() => {
  const clauses = getMapSection(appStore.mapOrEmptyMap, MapSectionKey.InEncodings);
  return Object.keys(clauses).map(toCatalogPath);
});

function toCatalogPath(id: string) {
  const fullDataset = useDataset(() => id);
  const path = fullDataset.catalogPath();
  if (!path) {
    return "";
  }
  return `${path.catalog}.${path.schema}.${path.table}`;
}

onMounted(function () {
  sourceBrowserStore.loadSystemTables(workspaceId.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Textbox, {
        modelValue: _unref(sourceBrowserStore).search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(sourceBrowserStore).search) = $event)),
        type: "search",
        disabled: !hasSearch.value && catalogs.value.length === 0
      }, null, 8, ["modelValue", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (status.value === _unref(AsyncStatus).Succeeded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(catalogs.value, (catalog) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: catalog.name
                  }, [
                    _createElementVNode("div", {
                      class: "flex flex-col items-start justify-center px-10 py-5 hover:bg-gray-900",
                      onClick: ($event: any) => (toggleGroupCollapsed(catalog.name))
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(Disclosure, {
                          class: "h-20 w-20 justify-center",
                          expanded: isGroupExpanded(catalog.name) || hasSearch.value
                        }, null, 8, ["expanded"]),
                        _createVNode(Icon, { name: "menu_book" }),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", null, _toDisplayString(catalog.name), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(catalog.schemas.length) + " " + _toDisplayString(_unref(pluralize)("Schemas", catalog.schemas.length)), 1)
                      ])
                    ], 8, _hoisted_5),
                    (isGroupExpanded(catalog.name) || hasSearch.value)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(catalog.schemas, (schema) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: schema.name
                          }, [
                            _createElementVNode("div", {
                              class: "my-5 ml-20 mr-15 flex flex-col items-start justify-center hover:bg-gray-900",
                              onClick: ($event: any) => (toggleGroupCollapsed(`${catalog.name}:${schema.name}`))
                            }, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(Disclosure, {
                                  class: "disclosure",
                                  expanded: isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch.value
                                }, null, 8, ["expanded"]),
                                _createVNode(Icon, {
                                  name: "dataset",
                                  color: "white"
                                }),
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("span", null, _toDisplayString(schema.name), 1)
                                ]),
                                _createElementVNode("div", _hoisted_12, _toDisplayString(schema.tables.length) + " " + _toDisplayString(_unref(pluralize)("Table", schema.tables.length)), 1)
                              ])
                            ], 8, _hoisted_9),
                            (isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch.value)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(listSystemTables(provider.value!, catalog, schema), (table) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(["flex flex-col items-start justify-center gap-5 py-5 pl-[60px] pr-15 hover:bg-gray-900", { '-selected': isSelected(table), 'cursor-pointer': !table.loaded }]),
                                    key: `${table.group}-${table.name}`,
                                    "data-test": `table-${table.name}`,
                                    onClick: ($event: any) => (toggleSelected(table))
                                  }, [
                                    _createElementVNode("div", _hoisted_14, [
                                      (table.loaded)
                                        ? (_openBlock(), _createBlock(Icon, {
                                            key: 0,
                                            name: "table",
                                            color: "orange"
                                          }))
                                        : (isSelected(table))
                                          ? (_openBlock(), _createBlock(Icon, {
                                              key: 1,
                                              name: "checkbox-selected",
                                              color: "none"
                                            }))
                                          : (_openBlock(), _createBlock(Icon, {
                                              key: 2,
                                              name: "table",
                                              color: "white"
                                            })),
                                      _createVNode(_unref(Tooltip), {
                                        placement: "right-start",
                                        delay: { show: 500, hide: 100 }
                                      }, {
                                        popper: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_15, [
                                            _createElementVNode("div", _hoisted_16, _toDisplayString(`${catalog.name}.${schema.name}.${table.name}`), 1),
                                            _createTextVNode(" " + _toDisplayString(table.table.comment), 1)
                                          ])
                                        ]),
                                        default: _withCtx(() => [
                                          _createElementVNode("span", {
                                            class: _normalizeClass({ 'text-orange': table.loaded })
                                          }, _toDisplayString(table.name), 3)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      (table.table.column_count)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(table.table.column_count) + " " + _toDisplayString(_unref(pluralize)("col", table.table.column_count)), 1))
                                        : _createCommentVNode("", true),
                                      (table.table.rows)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(rowCount(table.table.rows)), 1))
                                        : _createCommentVNode("", true),
                                      (table.table.bytes)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_unref(formatBytes)(table.table.bytes)), 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ], 10, _hoisted_13))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (hasSearch.value && catalogs.value.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, " No resources match your search "))
                  : _createCommentVNode("", true),
                (!hasSearch.value && catalogs.value.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, " Catalog empty "))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (status.value === _unref(AsyncStatus).InProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(Spinner)
              ]))
            : _createCommentVNode("", true),
          (status.value === _unref(AsyncStatus).Failed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createVNode(MessageBar, { mode: "error" }, {
                  title: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Error loading tables ")
                  ])),
                  actions: _withCtx(() => [
                    _createVNode(TextButton, {
                      label: "Refresh",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (refreshTables())),
                      mode: "error"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString((_unref(sourceBrowserStore).catalogs as AsyncFailed).message) + " ", 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (selectionCount.value > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(pluralize)("data source", selectionCount.value, true)) + " selected ", 1),
          _createVNode(TextButton, {
            label: "Load",
            "data-test": "load",
            onClick: commitTables
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})