import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-5 flex rounded-md bg-gray-300 dark:bg-gray-900" }
const _hoisted_2 = ["onClick"]

type Value = string | number | symbol | boolean;

interface Option {
  label: string;
  value: Value;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectStrip',
  props: /*@__PURE__*/_mergeModels({
    options: {}
  }, {
    "modelValue": { type: [String, Number, Symbol, Boolean], ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel<Value>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ label, value }) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex basis-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md px-10 text-gray-500", {
        'border border-gray-500 bg-white font-bold text-orange dark:border-black dark:bg-gray-800':
          value === model.value,
        'hover:text-orange': value !== model.value,
      }]),
        key: String(value),
        onClick: ($event: any) => (model.value = value)
      }, _toDisplayString(label), 11, _hoisted_2))
    }), 128))
  ]))
}
}

})