import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "py-[3px] pr-4" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "max-w-[80px] overflow-hidden text-ellipsis pl-5 text-right font-semibold" }

import { ValueWithFormattedValue } from "@/common/lib/format";
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";

export interface LegendItem {
  label: ValueWithFormattedValue;
  identifier?: GraphValue;
  value?: ValueWithFormattedValue;
  color: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Legend',
  props: {
    items: {},
    total: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { items, total } = toRefs(props);

const emit = __emit;

const itemDetails = computed(function () {
  return items.value.map(function (item, index) {
    return {
      key: index,
      label: stringifyValue(item.label.formattedValue),
      value: item.value ? stringifyValue(item.value.formattedValue) : null,
      identifier: item.identifier,
      color: item.color,
    };
  });
});

const totalString = computed(() => (total.value ? stringifyValue(total.value.formattedValue) : ""));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemDetails.value, (item) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: item.key,
          class: _normalizeClass(["group/legenditem whitespace-nowrap", { 'cursor-pointer': item.identifier != null }]),
          onClick: ($event: any) => {if (item.identifier != null) emit('select', item.identifier);}
        }, [
          _createElementVNode("td", _hoisted_2, [
            _createElementVNode("div", {
              style: _normalizeStyle({ backgroundColor: item.color, outlineColor: item.color }),
              class: _normalizeClass(["h-15 w-15 rounded-full outline-2", { 'group-hover/legenditem:outline': item.identifier != null }])
            }, null, 6)
          ]),
          _createElementVNode("td", {
            class: _normalizeClass(["max-w-[100px] overflow-hidden text-ellipsis", { 'font-semibold': item.identifier != null }]),
            title: item.label
          }, _toDisplayString(item.label), 11, _hoisted_3),
          (item.value)
            ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(item.value), 1))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      }), 128)),
      (_unref(total))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("td", { class: "py-4 text-right font-semibold" }, "Total", -1)),
            _createElementVNode("td", _hoisted_6, _toDisplayString(totalString.value), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})