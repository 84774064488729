import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex shrink-0 grow basis-0 flex-col items-start gap-5 p-10" }
const _hoisted_2 = { class: "self-stretch text-lg font-bold text-black dark:text-gray-200" }
const _hoisted_3 = { class: "text-base text-black dark:text-gray-200" }


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageBar',
  props: {
    mode: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-200 flex items-center justify-between border-b-[3px] border-orange pr-10 dark:bg-gray-900", {
      'border-red-500 bg-red-100 dark:bg-red-900': _ctx.mode === 'error',
      'border-yellow-500 bg-yellow-100 dark:bg-yellow-900': _ctx.mode === 'warning',
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _renderSlot(_ctx.$slots, "actions")
  ], 2))
}
}

})