import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "file-menu",
  "data-test": "project-menu"
}

import BookmarksList from "../explore/BookmarksList.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import { Bookmark } from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarkMenu',
  props: {
    projectId: {}
  },
  emits: ["updateBookmark", "displayError", "deleteBookmark"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function updateBookmark(bookmark: Bookmark) {
  emit("updateBookmark", bookmark);
}

function displayError(bookmark: Bookmark, errors: string[]) {
  emit("displayError", bookmark, errors);
}

function deleteBookmark(bookmark: Bookmark) {
  emit("deleteBookmark", bookmark);
}

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_unref(Dropdown), { class: "flex justify-start self-center" }, {
    popper: _withCtx(() => [
      _withDirectives(_createVNode(BookmarksList, {
        onUpdateBookmark: updateBookmark,
        onDisplayError: displayError,
        onDeleteBookmark: deleteBookmark,
        projectId: props.projectId
      }, null, 8, ["projectId"]), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(Tooltip), { delay: { show: 1000, hide: 100 } }, {
        popper: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Show bookmarks")
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(IconButton2, {
              name: "md:folder_special",
              size: "xl"
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})