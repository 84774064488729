import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Spinner from "@/common/components/Spinner.vue";
import { computed, inject, Ref, toRefs } from "vue";
import { DarkMode } from "@/common/lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpinnerButton',
  props: {
    label: {},
    icon: { type: Boolean }
  },
  setup(__props: any) {

/*
 * This isn't actually a button, but a status block that is meant to replace a button.
 */
const props = __props;

const { icon, label } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const textColor = computed(function () {
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  return "orange";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-flex items-center justify-center gap-5 rounded px-[6px] py-[4px] text-center leading-4", [
      `text-${textColor.value}`,
      {
        'border-none text-lg uppercase': !_unref(icon),
      },
    ]])
  }, [
    _createVNode(Spinner, {
      size: "l",
      class: "mr-1 px-2"
    }),
    _createElementVNode("span", null, _toDisplayString(_unref(label)), 1)
  ], 2))
}
}

})