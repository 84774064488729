import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }

import { DerivedPropertyTerm, AddPropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import MultiTerm from "./MultiTerm.vue";
import { isNull } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddPropertyDef',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const OP = PropertyOpType.Add;

const terms: Ref<(DerivedPropertyTerm | null)[]> = ref([null, null]);

const model = _useModel<AddPropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (terms.value.some((t) => isNull(t))) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      terms: terms.value as DerivedPropertyTerm[],
    };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "mb-5 block" }, "Terms:", -1)),
    _createVNode(MultiTerm, {
      "parent-op": _unref(OP),
      modelValue: terms.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((terms).value = $event))
    }, null, 8, ["parent-op", "modelValue"])
  ]))
}
}

})