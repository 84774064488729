import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-fit-content flex justify-center" }
const _hoisted_2 = ["src"]

import { computed, inject, Ref, toRefs } from "vue";
import { getIconSizePx, IconColor, IconColorSpec, IconSize, parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";
import { isString } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    name: {},
    size: { default: "l" },
    color: { default: () => ({ dark: "white", light: "black" }) }
  },
  setup(__props: any) {

const props = __props;

const { name, size, color } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const src = computed(() => parseIconName(name.value));
const sizePx = computed(() => getIconSizePx(size.value));
const colorClass = computed(function () {
  let effectiveColor: IconColor;
  if (isString(color.value)) {
    effectiveColor = color.value;
  } else {
    effectiveColor = darkMode.value ? color.value.dark : color.value.light;
  }
  return `svg-to-${effectiveColor}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("img", {
      src: src.value,
      style: _normalizeStyle({ width: `${sizePx.value}px`, height: `${sizePx.value}px` }),
      class: _normalizeClass(["pointer-events-none block max-w-none", colorClass.value])
    }, null, 14, _hoisted_2)
  ]))
}
}

})