import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = { class: "mx-auto" }

import {
  generateValue,
  generateValues,
  generatorName,
  generatorOutputType,
  ValueGenerator,
} from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import { last, tail } from "lodash";
import { stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { GraphValue, QUANTITATIVE_VALUE_TYPES, toValue } from "@/common/lib/value";
import SubtableComponent from "./table/Subtable.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { TableVisualization, TableVisualizationGroup } from "@/reader/lib/visualizationTypes";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";

export interface Subtable {
  rows?: (ValueWithFormattedValue | null)[][];
  groups?: TableGroup[];
}

export interface Column {
  generator: ValueGenerator;
  label: string;
  isQuantitative: boolean;
  hideBars: boolean;
}

interface TableGroup {
  id: string;
  name: GraphValue;
  contents: Subtable;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    visualization: {},
    results: {},
    width: {},
    height: {}
  },
  emits: ["select"],
  setup(__props: any) {

const props = __props;



const { visualization, results } = toRefs(props);

const columns = computed(function (): Column[] {
  const query = combinedQuerySignature(visualization.value.query);
  return visualization.value.config.columns.map(function (colspec) {
    const valueType = generatorOutputType(colspec.value, query);
    return {
      generator: colspec.value,
      label: colspec.label ?? generatorName(colspec.value, query),
      isQuantitative: valueType ? QUANTITATIVE_VALUE_TYPES.includes(valueType) : false,
      hideBars: colspec.hide_bars ?? false,
    };
  });
});

function buildRows(results: UseQueryResult[]) {
  const query = combinedQuerySignature(visualization.value.query);
  return results.map(function (row) {
    return columns.value.map((col) => generateValue(col.generator, row, query));
  });
}

function buildGroups(
  results: UseQueryResult[],
  groupspecs: TableVisualizationGroup[]
): TableGroup[] {
  const query = combinedQuerySignature(visualization.value.query);
  const groupspec = groupspecs[0];
  const rowGroups: UseQueryResult[][] = [];
  let currentId: string | null | undefined = undefined;
  for (const row of results) {
    const category = row.valuesByAlias[groupspec.category];
    const id = category ? stringifyValueOrCompositeValue(category[0]) : null;
    if (id !== currentId) {
      currentId = id;
      rowGroups.push([]);
    }
    last(rowGroups)!.push(row);
  }
  return rowGroups.map(function (rowGroup) {
    const firstRow = rowGroup[0];
    const values = generateValues({ ...groupspec }, firstRow, query);
    return {
      id: values.category ? stringifyValueOrCompositeValue(values.category.originalValue) : "",
      name: (values.category_name ?? values.category)?.formattedValue ?? toValue("-"),
      contents: buildSubtable(rowGroup, tail(groupspecs)),
    };
  });
}

function buildSubtable(results: UseQueryResult[], groupspecs: TableVisualizationGroup[]): Subtable {
  if (groupspecs.length == 0) {
    return { rows: buildRows(results) };
  } else {
    return { groups: buildGroups(results, groupspecs) };
  }
}

const table = computed(() => buildSubtable(results.value, visualization.value.config.groups ?? []));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute left-0 right-0 top-0 z-0 h-[24px] bg-gray-200 dark:bg-gray-800" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (colDetails, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: index,
                class: "sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex h-[25px] items-center justify-between whitespace-nowrap border-b border-r border-white px-10 dark:border-gray-900", { 'justify-end text-right': colDetails.isQuantitative }])
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(colDetails.label), 1)
                ], 2)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createVNode(SubtableComponent, {
            subtable: table.value,
            columns: columns.value,
            depth: 0
          }, null, 8, ["subtable", "columns"])
        ])
      ])
    ])
  ], 64))
}
}

})