import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row" }

import TextButton from "@/common/components/TextButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SaveButtons',
  props: {
    mode: { default: "edit" },
    needsSave: { type: Boolean },
    canSave: { type: Boolean, default: true },
    label: {}
  },
  emits: ["save", "cancelled"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const showConfirmDialog = ref(false);

function cancel() {
  if (props.needsSave) {
    showConfirmDialog.value = true;
  } else {
    emit("cancelled");
  }
}

function handleCancelClose() {
  showConfirmDialog.value = false;
}

function handleConfirmClose() {
  showConfirmDialog.value = false;
  emit("cancelled");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(TextButton, {
          secondary: true,
          label: "Cancel",
          onClick: cancel
        }),
        _createVNode(TextButton, {
          label: _ctx.mode === 'create' ? 'Create' : 'Save',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('save'))),
          secondary: !_ctx.needsSave,
          disabled: !_ctx.canSave
        }, null, 8, ["label", "secondary", "disabled"])
      ])
    ]),
    (showConfirmDialog.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: "Discard changes?",
          "success-label": "Discard",
          onSucceeded: handleConfirmClose,
          onCancelled: handleCancelClose,
          icon: "md:warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label ? `You have unsaved changes to ${_ctx.label}.` : "You have unsaved changes.") + " ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _cache[2] || (_cache[2] = _createTextVNode(" Are you sure you want to discard them? "))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})