import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_3 = { class: "flex flex-col gap-5" }
const _hoisted_4 = {
  key: 0,
  class: "text-500 italic"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_6 = { class: "flex flex-col items-start gap-15" }

import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import MessageBar from "./MessageBar.vue";

interface ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSettings',
  setup(__props) {

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();
const editingChangePassword = ref<ChangePassword | undefined>();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

async function resync() {
  await userStore.resyncUser();
}

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

async function finishChangePassword() {
  if (!editingChangePassword.value) {
    return;
  }
  editingChangePassword.value.error = undefined;
  const result = await userStore.updateUserPassword(editingChangePassword.value.newPassword);
  if (result.error) {
    editingChangePassword.value.error = result.error;
    return;
  }
  editingChangePassword.value = undefined;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(user))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-xl" }, "Name", -1)),
          _createElementVNode("div", null, _toDisplayString(_unref(user).name), 1),
          _createElementVNode("div", null, [
            _createVNode(TextButton, {
              label: "Update name",
              onClick: editUserName
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(user))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-xl" }, "Email", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_unref(user).email), 1),
            (_unref(user).email_verified)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "verified"))
              : _createCommentVNode("", true)
          ]),
          _createVNode(TextButton, {
            label: "Resync",
            onClick: resync
          })
        ]))
      : _createCommentVNode("", true),
    (_unref(user))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-xl" }, "Password", -1)),
          _createElementVNode("div", null, [
            _createVNode(TextButton, {
              label: "Change password",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (editingChangePassword.value = { newPassword: '', confirmNewPassword: '' }))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-xl" }, "Session", -1)),
      _createVNode(TextButton, {
        label: "Logout",
        onClick: logout
      })
    ]),
    (editingUserName.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 3,
          title: "Update name",
          "success-label": "Update",
          onSucceeded: finishEditUserName,
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => (editingUserName.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Name",
              modelValue: editingUserName.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editingUserName).value = $event)),
              autoselect: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (editingChangePassword.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 4,
          title: "Change password",
          "success-label": "Update",
          onSucceeded: finishChangePassword,
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => (editingChangePassword.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "New password",
              modelValue: editingChangePassword.value.newPassword,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((editingChangePassword.value.newPassword) = $event)),
              autoselect: true,
              autocomplete: "new-password"
            }, null, 8, ["modelValue"]),
            (editingChangePassword.value.error)
              ? (_openBlock(), _createBlock(MessageBar, {
                  key: 0,
                  mode: "error"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(editingChangePassword.value.error), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})