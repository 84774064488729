import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString } from "vue"

import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StringValue',
  props: {
    value: {}
  },
  setup(__props: any) {

const props = __props;
const { value } = toRefs(props);

const string = computed(() => stringifyValue(value.value));

return (_ctx: any,_cache: any) => {
  return _toDisplayString(string.value)
}
}

})