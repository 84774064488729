import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mx-2 my-4 bg-white text-gray-800 dark:bg-black dark:text-gray-200" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "mb-2 flex min-w-[16px]"
}
const _hoisted_4 = {
  key: 2,
  class: "cursor-default opacity-50"
}

import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  props: {
    items: {},
    minWidth: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const useIcons = props.items.find((item) => !!item.icon) ?? false;

function select(item: MenuItem) {
  if (!item.disabled) {
    item.action?.();
    emit("select");
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Menu = _resolveComponent("Menu", true)!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_unref(Dropdown), {
        key: item.key,
        disabled: !item.submenu,
        placement: "right-start",
        style: _normalizeStyle({ 'min-width': _ctx.minWidth }),
        distance: 10
      }, {
        popper: _withCtx(() => [
          _withDirectives(_createVNode(_component_Menu, {
            items: item.submenu!,
            onSelect: _cache[0] || (_cache[0] = ($event: any) => (emit('select')))
          }, null, 8, ["items"]), [
            [
              _directive_close_popper,
              void 0,
              void 0,
              { all: true }
            ]
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("li", {
            onClick: ($event: any) => (select(item)),
            class: _normalizeClass(["group flex cursor-pointer items-center gap-10 px-10 leading-[25px]", {
            'cursor-default text-gray-500': item.disabled,
            'hover:bg-orange hover:text-white': !item.disabled,
            'border-b border-b-gray-300 dark:border-b-gray-700': item.section,
          }])
          }, [
            (_unref(useIcons))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (item.icon)
                    ? (_openBlock(), _createBlock(Icon, {
                        key: 0,
                        name: item.icon,
                        size: "l"
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["basis-full overflow-hidden whitespace-nowrap", { 'text-end': item.align === 'end' }])
            }, _toDisplayString(item.label), 3),
            (item.submenu)
              ? (_openBlock(), _createBlock(Icon, {
                  key: 1,
                  name: "submenu",
                  size: "xxs",
                  class: "ml-5 flex-shrink-0 flex-grow"
                }))
              : _createCommentVNode("", true),
            (item.shortcut)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.shortcut), 1))
              : _createCommentVNode("", true)
          ], 10, _hoisted_2)
        ]),
        _: 2
      }, 1032, ["disabled", "style"]))
    }), 128))
  ]))
}
}

})