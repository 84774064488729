import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex max-h-screen w-[450px] flex-col scroll-auto p-10" }
const _hoisted_2 = { class: "flex flex-row gap-5" }
const _hoisted_3 = {
  key: 0,
  class: "px-10 py-[7px] text-gray-500"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex flex-row items-center justify-between" }

import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { Dropdown, Tooltip } from "floating-vue";
import { computed, Ref, inject } from "vue";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import { useExploreStore } from "../../stores/explore";
import { AsyncStatus } from "@/common/lib/async";
import { DarkMode } from "@/common/lib/keys";
import { isEmpty } from "lodash";
import { Query } from "@/common/lib/query";
import { validateQuery } from "@/common/lib/queryValidator";
import { environment } from "@/common/environments/environmentLoader";
import { UserAction } from "@/common/lib/userAction";
interface submenuItem {
  key: string;
  label: string;
  action?: () => void;
}

interface BookmarkItem {
  label: string;
  key: string;
  disabled?: boolean;
  action?: () => void;
  submenu: submenuItem[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarksList',
  props: {
    projectId: {}
  },
  emits: ["updateBookmark", "displayError", "deleteBookmark"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;
const userModuleStore = useUserModuleStore();
const exploreStore = useExploreStore();

const bookmarks = computed(() => {
  const items: BookmarkItem[] = [];
  const bookmarks = userModuleStore.bookmarks;
  if (bookmarks.status === AsyncStatus.Succeeded) {
    bookmarks.result.forEach((bookmark) => {
      const errors = validateQuery(bookmark.state, exploreStore.metagraph);
      items.push({
        key: bookmark.id,
        label: bookmark.metadata.name,
        disabled: !isEmpty(errors),
        action: () => openBookmark(bookmark.state),
        submenu: menuItems(bookmark, errors),
      });
    });
  } else if (bookmarks.status === AsyncStatus.Failed) {
    return bookmarks.message;
  } else if (isEmpty(items)) {
    return "No bookmarks yet.";
  }
  return items;
});

function select(item: BookmarkItem) {
  if (!item.disabled) {
    item.action?.();
  }
}

function openBookmark(query: Query) {
  exploreStore.loadQuery(query, UserAction.Load_Bookmark);
}

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => {
  if (darkMode.value) {
    return "light-gray";
  } else {
    return "gray2";
  }
});

function menuItems(bookmark: Bookmark, errors: string[]) {
  const items = [
    {
      key: "rename",
      label: "Rename",
      action: () => emit("updateBookmark", bookmark),
    },
    {
      key: "rename",
      label: "Delete",
      action: () => emit("deleteBookmark", bookmark),
    },
  ];
  if (environment.requireBoolean("BOOKMARKS_SHOW_COPY_JSON")) {
    items.push({
      key: "get-json",
      label: "Copy JSON",
      action: () => copyJson(bookmark),
    });
  }
  if (!isEmpty(errors)) {
    items.push({
      key: "errors",
      label: "Error details",
      action: () => emit("displayError", bookmark, errors),
    });
  }

  return items;
}

async function copyJson(bookmark: Bookmark) {
  await navigator.clipboard.writeText(JSON.stringify(bookmark.state, undefined, 2));
}

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Icon, {
        name: "star_filled",
        size: "xl",
        class: "icon",
        color: iconColor.value
      }, null, 8, ["color"]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "self-center text-xxl text-gray-700 dark:text-gray-300" }, "Bookmarks", -1))
    ]),
    (typeof bookmarks.value === 'string')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(bookmarks.value), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bookmarks.value, (bookmark) => {
            return (_openBlock(), _createElementBlock("div", {
              key: bookmark.key,
              class: "flex items-center px-10"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["basis-full bg-white p-[7px] dark:bg-black", {
            'cursor-default text-gray-300 dark:text-gray-700': bookmark.disabled,
            'text-gray-800 hover:cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-900':
              !bookmark.disabled,
          }]),
                onClick: ($event: any) => (select(bookmark))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(bookmark.label), 1),
                  (bookmark.submenu.length > 0)
                    ? (_openBlock(), _createBlock(_unref(Dropdown), {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                      }, {
                        popper: _withCtx(() => [
                          _withDirectives(_createVNode(Menu, {
                            items: bookmark.submenu
                          }, null, 8, ["items"]), [
                            [
                              _directive_close_popper,
                              void 0,
                              void 0,
                              { all: true }
                            ]
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_unref(Tooltip), { delay: { show: 1000, hide: 100 } }, {
                            popper: _withCtx(() => [
                              _createTextVNode("Manage " + _toDisplayString(bookmark.label), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(IconButton, {
                                name: bookmark.disabled ? 'md:error' : 'md:more_vert',
                                size: "m"
                              }, null, 8, ["name"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ], 10, _hoisted_5)
            ]))
          }), 128))
        ]))
  ]))
}
}

})