import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center self-stretch" }
const _hoisted_2 = { class: "flex shrink-0 flex-grow basis-0 items-center gap-10 px-5 py-0 text-xxl font-bold text-gray-800 dark:text-gray-200" }
const _hoisted_3 = {
  key: 0,
  class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
}
const _hoisted_4 = { class: "flex items-start justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }
const _hoisted_5 = { class: "flex flex-col items-start gap-10 self-stretch" }
const _hoisted_6 = { class: "flex items-center justify-end gap-10 self-stretch" }

import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import EditConnectionSettings from "@/common/components/settings/EditConnectionSettings.vue";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { onMounted, ref, inject, Ref, computed, watch } from "vue";
import Dialog from "@/common/components/Dialog.vue";
import { DarkMode } from "@/common/lib/keys";
import { storeToRefs } from "pinia";
import { asyncResultOr, AsyncStatus, asyncValue } from "@/common/lib/async";
import WorkspaceChooser from "../WorkspaceChooser.vue";
import WarehouseChooser from "../WarehouseChooser.vue";
import { isNil } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectionSettings',
  setup(__props) {

const workspaceStore = useWorkspaceStore();
const { currentWorkspace, currentWorkspaceId, workspaces } = storeToRefs(workspaceStore);

const mode = ref<"view" | "edit" | "create">("view");
const deletingWorkspace = ref(false);

function updateWorkspace() {
  mode.value = "view";
}

async function deleteWorkspace() {
  await workspaceStore.deleteCurrentWorkspace();
  deletingWorkspace.value = false;
}

watch(
  () => workspaces.value,
  (workspacesAsync) => {
    const workspaces = asyncValue(workspacesAsync);
    if (isNil(workspaces)) {
      return;
    }
    if (workspaces.length === 0) {
      mode.value = "create";
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
});

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => (darkMode.value ? "white" : "black"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(Icon, {
          name: "electrical_services",
          size: "xl",
          class: "icon",
          color: iconColor.value
        }, null, 8, ["color"]),
        _cache[4] || (_cache[4] = _createTextVNode(" Connect to workspace "))
      ])
    ]),
    (
      [_unref(AsyncStatus).Succeeded, _unref(AsyncStatus).Failed].includes(_unref(currentWorkspace).status) &&
      _unref(asyncResultOr)(_unref(workspaces), []).length &&
      mode.value === 'view'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, " Select a workspace ", -1)),
            _createVNode(TextButton, {
              label: "Add workspace",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (mode.value = 'create'))
            })
          ]),
          _createElementVNode("form", _hoisted_5, [
            _createVNode(WorkspaceChooser),
            _createVNode(WarehouseChooser)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_unref(currentWorkspaceId))
              ? (_openBlock(), _createBlock(TextButton, {
                  key: 0,
                  label: "Delete",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (deletingWorkspace.value = true))
                }))
              : _createCommentVNode("", true),
            _createVNode(TextButton, {
              label: "Edit",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (mode.value = 'edit'))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (mode.value !== 'view')
      ? (_openBlock(), _createBlock(EditConnectionSettings, {
          key: 1,
          mode: mode.value,
          workspaceId: _unref(currentWorkspaceId),
          onSaveWorkspace: updateWorkspace
        }, null, 8, ["mode", "workspaceId"]))
      : _createCommentVNode("", true),
    (deletingWorkspace.value && _unref(currentWorkspace).status === _unref(AsyncStatus).Succeeded)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 2,
          title: "Delete workspace?",
          "success-label": "Delete",
          onSucceeded: deleteWorkspace,
          onCancelled: _cache[3] || (_cache[3] = ($event: any) => (deletingWorkspace.value = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete workspace " + _toDisplayString(_unref(currentWorkspace).result?.nickname) + "? This cannot be undone. ", 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})