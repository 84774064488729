import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10 max-w-[275px] text-black dark:text-white" }
const _hoisted_2 = { class: "my-5" }
const _hoisted_3 = { class: "flex flex-row justify-end" }

import TextButton from "@/common/components/TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterEditor',
  props: {
    valid: { type: Boolean },
    label: {}
  },
  emits: ["cancel", "commit"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.label) + ": ", 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TextButton, {
        label: "Cancel",
        secondary: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel'))),
        size: "l",
        class: "mr-5"
      }),
      _createVNode(TextButton, {
        label: "Set Filter",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('commit'))),
        disabled: !_ctx.valid
      }, null, 8, ["disabled"])
    ])
  ]))
}
}

})