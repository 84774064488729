import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex max-w-full items-center rounded-[20px] bg-gray-700 p-[3px] pr-[6px] text-[5px]" }
const _hoisted_2 = { class: "ml-5 truncate pr-5 text-xs" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-gray-500"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusPill',
  props: {
    color: {},
    label: {},
    sublabel: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({ color: _ctx.color })
    }, "⬤", 4),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    (_ctx.sublabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.sublabel), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})