import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = ["onClick", "onDragstart", "onDrop"]
const _hoisted_4 = { class: "mx-auto" }
const _hoisted_5 = { class: "flex content-center items-center" }
const _hoisted_6 = {
  key: 0,
  class: "max-w-[400px] overflow-hidden overflow-ellipsis"
}
const _hoisted_7 = { class: "justify-end p-10 text-right align-baseline text-gray-200 group-hover:text-gray-500 dark:text-gray-500" }
const _hoisted_8 = { key: 0 }

import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncNotStarted, asyncResultOr } from "@/common/lib/async";
import { GraphValueType, QUANTITATIVE_VALUE_TYPES } from "@/common/lib/value";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, Ref, ref } from "vue";
import PropertyValue from "./PropertyValue.vue";
import Textbox from "@/common/components/Textbox.vue";
import { propertyValueType } from "@/common/lib/derived";
import { columnName, QueryColumn } from "@/common/lib/query";
import { isFunction } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  setup(__props) {

const dropdownShown = ref<string | null>(null);
const exploreStore = useExploreStore();
const renamingColumnAlias: Ref<string | undefined> = ref(undefined);
const renamingColumn: Ref<string | undefined> = ref(undefined);

const columns = computed(() =>
  (exploreStore.query?.columns ?? []).map((columnDef) => ({
    column: columnDef,
    label: columnName(columnDef),
    menuItems: columnMenuItems(columnDef.alias),
    sort: exploreStore.columnSortState(columnDef.alias),
    isQuantitative: QUANTITATIVE_VALUE_TYPES.includes(
      propertyValueType(columnDef.property_type) as GraphValueType
    ),
  }))
);

const columnMenuItems = (columnAlias: string) => {
  const items = exploreMenu(ExploreMenuSubject.Column, { columnAlias });
  items.push({
    key: "rename",
    label: "Rename",
    action: () => {
      renamingColumnAlias.value = columnAlias;
    },
  });
  return items;
};

const table = computed(() => asyncResultOr(exploreStore.table, []));

const isDropdownShown = (alias: string) => {
  return alias === dropdownShown.value;
};

const toggleDropdownShown = (alias: string, shown: boolean) => {
  dropdownShown.value = shown ? alias : null;
};

const sortOrderLabel = (order: number | undefined) => {
  if (order !== undefined && exploreStore.query!.order_by.length > 1) {
    return String(order + 1);
  }
};

function deselectAndRename(event: Event) {
  if (event.target && "blur" in event.target && isFunction(event.target.blur)) {
    event.target.blur();
  }
}

function rename(column: QueryColumn) {
  if (renamingColumn.value) {
    exploreStore.renameColumn(column.alias, renamingColumn.value);
    renamingColumn.value = undefined;
    exploreStore.sqlData = asyncNotStarted();
  }
  renamingColumnAlias.value = undefined;
}

function toggleAscendingAt(index: number) {
  const newOrderBy = [...exploreStore.query!.order_by];
  const target = newOrderBy[index];
  newOrderBy.splice(index, 1, { ...target, asc: !target.asc });
  exploreStore.setOrderBy(newOrderBy);
}

function formatWithLeadingZeros(number: number, length: number) {
  return String(number).padStart(length, "0");
}

const draggedColumnIndex = ref<number | null>(null);

function onColumnDragStart(index: number) {
  draggedColumnIndex.value = index;
}

function onColumnDrop(index: number) {
  if (draggedColumnIndex.value === null || exploreStore.query == null) return;

  const queryColumns = [...exploreStore.query.columns];
  const [movedColumn] = queryColumns.splice(draggedColumnIndex.value, 1);
  queryColumns.splice(index, 0, movedColumn);
  exploreStore.setColumnOrder(queryColumns);

  draggedColumnIndex.value = null;
}

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute left-0 right-0 top-0 z-0 h-[32px] bg-gray-200 dark:bg-gray-800" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[3] || (_cache[3] = _createElementVNode("th", null, [
              _createElementVNode("div", { class: "absolute inset-0 border-b border-r border-white dark:border-gray-900" })
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (colDetails, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: colDetails.column.alias,
                class: "align-baseline",
                onClick: ($event: any) => (toggleDropdownShown(colDetails.column.alias, true)),
                draggable: "true",
                onDragstart: ($event: any) => (onColumnDragStart(index)),
                onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                onDrop: ($event: any) => (onColumnDrop(index))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center justify-between whitespace-nowrap border-b border-r border-white pl-10 dark:border-gray-900", { 'justify-end text-right': colDetails.isQuantitative }])
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, [
                      (colDetails.column.alias !== renamingColumnAlias.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(colDetails.label), 1))
                        : _createCommentVNode("", true),
                      (colDetails.column.alias !== renamingColumnAlias.value)
                        ? (_openBlock(), _createBlock(_unref(Dropdown), {
                            key: 1,
                            placement: "bottom",
                            class: "cursor-pointer flex-row",
                            shown: isDropdownShown(colDetails.column.alias),
                            "onUpdate:shown": ($event: any) => (toggleDropdownShown(colDetails.column.alias, $event)),
                            triggers: []
                          }, {
                            popper: _withCtx(() => [
                              _withDirectives(_createVNode(Menu, {
                                items: colDetails.menuItems
                              }, null, 8, ["items"]), [
                                [_directive_close_popper]
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(IconButton, {
                                name: "chevron-down",
                                size: "xs"
                              })
                            ]),
                            _: 2
                          }, 1032, ["shown", "onUpdate:shown"]))
                        : (_openBlock(), _createBlock(Textbox, {
                            key: 2,
                            autoselect: true,
                            modelValue: renamingColumn.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((renamingColumn).value = $event)),
                            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (deselectAndRename($event)), ["enter"])),
                            onBlur: ($event: any) => (rename(colDetails.column))
                          }, null, 8, ["modelValue", "onBlur"]))
                    ])
                  ]),
                  (colDetails.sort !== undefined)
                    ? (_openBlock(), _createBlock(IconButton, {
                        key: 0,
                        name: colDetails.sort.asc ? 'md:arrow_upward' : 'md:arrow_downward',
                        label: colDetails.sort.asc ? 'Switch to descending' : 'Switch to ascending',
                        preText: sortOrderLabel(colDetails.sort!.index),
                        size: "s",
                        onClick: _withModifiers(($event: any) => (toggleAscendingAt(colDetails.sort!.index)), ["stop"])
                      }, null, 8, ["name", "label", "preText", "onClick"]))
                    : _createCommentVNode("", true)
                ], 2)
              ], 40, _hoisted_3))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(table.value, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
            }, [
              _createElementVNode("td", _hoisted_7, _toDisplayString(formatWithLeadingZeros(index + 1, 2)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (colDetails) => {
                return (_openBlock(), _createElementBlock("td", {
                  class: "p-10 align-baseline",
                  key: colDetails.column.alias
                }, [
                  (row[colDetails.column.alias].values.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[colDetails.column.alias].values, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createVNode(PropertyValue, {
                              value: value,
                              column: colDetails.column,
                              quantitative: colDetails.isQuantitative
                            }, null, 8, ["value", "column", "quantitative"])
                          ]))
                        }), 128)),
                        (row[colDetails.column.alias].isTruncated)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, "..."))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["opacity-50", { 'text-right': colDetails.isQuantitative }])
                      }, " - ", 2))
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ])
    ])
  ], 64))
}
}

})