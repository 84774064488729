import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center gap-5" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col items-center"
}

import Icon from "@/common/components/Icon.vue";
import { ProviderType, useWorkspaceStore, Warehouse, Workspace } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { environment } from "@/common/environments/environmentLoader";
import { AsyncStatus } from "../lib/async";
import Select from "@/common/components/Select.vue";
import Spinner from "./Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkspaceChooser',
  setup(__props) {

const workspaceStore = useWorkspaceStore();
const { workspaces, currentWorkspaceId } = storeToRefs(workspaceStore);

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

async function selectWorkspace(workspaceName: string) {
  await workspaceStore.setCurrentWorkspace(workspaceName);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(workspaces).status === _unref(AsyncStatus).Succeeded)
      ? (_openBlock(), _createBlock(Select, {
          key: 0,
          label: "Workspace",
          options: _unref(workspaces).result,
          "option-value": (w) => w.id,
          "model-value": _unref(currentWorkspaceId),
          "onUpdate:modelValue": selectWorkspace,
          "option-label": "nickname",
          clearable: false,
          class: "min-h-[62px]"
        }, {
          "option-data": _withCtx((workspace) => [
            _createElementVNode("span", _hoisted_1, [
              (providerIcon(workspace.provider))
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    name: providerIcon(workspace.provider)!
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(workspace.nickname), 1),
              (!workspace.connected)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(disconnected)"))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["options", "option-value", "model-value"]))
      : _createCommentVNode("", true),
    (_unref(workspaces).status === _unref(AsyncStatus).InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Spinner)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})