import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "absolute left-[-50px] top-[25px] mt-10 w-[100px] text-center" }

import Icon from "@/common/components/Icon.vue";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, ref, toRefs } from "vue";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metaconcept',
  props: {
    conceptType: {},
    positionX: {},
    positionY: {}
  },
  emits: ["move"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { conceptType, positionX, positionY } = toRefs(props);

const exploreStore = useExploreStore();

const emit = __emit;

const dragStartX = ref(0);
const dragStartY = ref(0);
const preDragPositionX = ref(0);
const preDragPositionY = ref(0);
const isDragging = ref(false);

const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);

const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const top = computed(() => `${positionY.value}px`);
const left = computed(() => `${positionX.value}px`);

const isRoot = computed(() => exploreStore.query?.root_concept_type === conceptType.value);
const propertyCount = computed(
  () => (getConceptsOfType(conceptType.value)[0].properties ?? []).length
);
const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function startDragging(event: MouseEvent) {
  if (event.button != 0) return; // Only start dragging on left click
  dragStartX.value = event.pageX;
  dragStartY.value = event.pageY;
  preDragPositionX.value = positionX.value;
  preDragPositionY.value = positionY.value;
  document.addEventListener("mousemove", continueDragging);
  document.addEventListener("mouseup", finishDragging);
}

function continueDragging(event: MouseEvent) {
  const deltaX = event.pageX - dragStartX.value;
  const deltaY = event.pageY - dragStartY.value;
  if (deltaX != 0 || deltaY != 0) isDragging.value = true;
  emit("move", preDragPositionX.value + deltaX, preDragPositionY.value + deltaY);
  event.preventDefault();
}

function finishDragging(event: MouseEvent) {
  document.removeEventListener("mouseup", finishDragging);
  document.removeEventListener("mousemove", continueDragging);
  continueDragging(event); // Final position update
}

function click(event: MouseEvent) {
  if (isDragging.value) {
    // The 'click' at the end of a drag should have no effect
    event.stopImmediatePropagation();
    isDragging.value = false;
    return;
  }
  exploreStore.setRootConceptType(conceptType.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ top: top.value, left: left.value }),
    class: "absolute z-30 cursor-pointer"
  }, [
    _createElementVNode("div", {
      onMousedown: _withModifiers(startDragging, ["prevent"]),
      onClick: click
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute left-[-25px] top-[-25px] z-0 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-800", { 'border-2 border-white': isRoot.value }]),
        style: _normalizeStyle({ backgroundColor: color.value })
      }, [
        _createVNode(Icon, {
          class: "icon",
          name: iconName.value,
          size: "xxl",
          color: "gray2"
        }, null, 8, ["name"])
      ], 6),
      (propertyCount.value > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "relative right-[-8px] top-[13px] z-10 min-w-20 rounded-full border-2 border-gray-200 text-center text-gray-900 dark:border-gray-900",
            style: _normalizeStyle({ backgroundColor: color.value })
          }, _toDisplayString(propertyCount.value), 5))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, _toDisplayString(name.value), 1)
    ], 32)
  ], 4))
}
}

})