<template>
  <div class="absolute inset-0 flex flex-col">
    <div class="mx-10 my-5 flex min-h-30 items-center">
      <Icon :name="iconName" :color="{ dark: 'white', light: 'black' }" />
      <div class="mx-5 shrink grow-0 basis-full text-xxl font-bold">{{ title }}</div>
      <IconButtonV2 v-if="showCloseButton" name="close" size="l" @click="emit('close')" />
    </div>

    <div class="basis-full overflow-y-auto p-10 pt-0">
      <div class="flex justify-center p-15" v-if="queryResults.status === AsyncStatus.InProgress">
        <Spinner />
      </div>

      <div v-if="queryResults.status === AsyncStatus.Failed">{{ queryResults.message }}</div>
      <div v-if="queryResults.status === AsyncStatus.Succeeded">
        <div class="properties">
          <ValueGroup
            v-for="(properties, type) of propertyValuesByType"
            :values="properties"
            :concept-type="address.conceptType"
            :property-type="type as PropertyKnowledgeRef"
            :key="type"
          />
        </div>

        <NeighborhoodSummary
          v-for="(query, key) of neighborhoods"
          :key="key"
          :query="query"
          @navigate="(address) => emit('navigate', address)"
          @pivot="() => pivotTo(query)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { AsyncStatus, asyncHasValue } from "@/common/lib/async";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useQuery from "@/reader/composables/useQuery";
import {
  ConceptAddress,
  buildConceptQuery,
  buildNeighborhoodQueries,
  readerConceptTitle,
} from "@/reader/lib/concept";
import { groupBy, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import NeighborhoodSummary from "./NeighborhoodSummary.vue";
import ValueGroup from "./ValueGroup.vue";
import { useAppStore } from "@/reader/stores/app";
import { formatValue } from "@/common/lib/format";
import { Query, QueryFilter } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { UserAction } from "@/common/lib/userAction";

const props = withDefaults(defineProps<{ address: ConceptAddress; showCloseButton?: boolean }>(), {
  showCloseButton: false,
});
const { address } = toRefs(props);

const emit = defineEmits<{
  (e: "navigate", address: ConceptAddress): void;
  (e: "pivot", conceptType: ConceptKnowledgeRef, filters: QueryFilter[]): void;
  (e: "close"): void;
}>();

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcept } = useQuery(
  appStore.module!,
  () => buildConceptQuery(address.value),
  UserAction.Load_Concept_Page
);

const neighborhoods = computed(() => buildNeighborhoodQueries(address.value));

const title = computed(function () {
  const conceptLabel = typeLabel(address.value.conceptType);
  if (asyncHasValue(queryResults.value)) {
    return readerConceptTitle(rootConcept()) ?? conceptLabel;
  } else {
    return `Loading ${conceptLabel}...`;
  }
});

const propertyValuesByType = computed(function () {
  const grouped = groupBy(rootConcept().properties ?? [], "type");
  return mapValues(grouped, (props, type) =>
    props.map((prop) => formatValue(type as PropertyKnowledgeRef, prop.value!))
  );
});

const iconName = computed(() => getConceptIconName(address.value.conceptType));

function pivotTo(query: Query) {
  emit("pivot", query.root_concept_type, query.filters);
}
</script>
