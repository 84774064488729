import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full bg-gray-200 dark:bg-gray-800" }
const _hoisted_2 = { class: "user-details" }
const _hoisted_3 = { class: "flex bg-gray-100 dark:bg-gray-900" }
const _hoisted_4 = { class: "m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200" }

import TabSelect from "@/common/components/TabSelect.vue";
import { SelectedTab, useSettingsStore } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";
import AdminPage from "@/common/components/admin/AdminPage.vue";
import UserPreferences from "./UserPreferences.vue";
import UserSettings from "./UserSettings.vue";
import QuickBooksTab from "./QuickBooksTab.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props) {

const userStore = useUserStore();
const router = useRouter();

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

watch(selectedTab, (newValue) => {
  router.replace({ name: newValue });
});
onMounted(() => {
  userStore.reloadUser();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(TabSelect, {
          tabs: _unref(availableTabs),
          modelValue: _unref(selectedTab),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedTab) ? (selectedTab).value = $event : null))
        }, null, 8, ["tabs", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_unref(selectedTab) === 'user-settings')
          ? (_openBlock(), _createBlock(UserSettings, { key: 0 }))
          : _createCommentVNode("", true),
        (_unref(selectedTab) === _unref(SelectedTab).Connections)
          ? (_openBlock(), _createBlock(ConnectionSettings, { key: 1 }))
          : _createCommentVNode("", true),
        (_unref(selectedTab) === _unref(SelectedTab).Preferences)
          ? (_openBlock(), _createBlock(UserPreferences, { key: 2 }))
          : _createCommentVNode("", true),
        (_unref(selectedTab) === _unref(SelectedTab).Admin)
          ? (_openBlock(), _createBlock(AdminPage, { key: 3 }))
          : _createCommentVNode("", true),
        (_unref(selectedTab) === _unref(SelectedTab).QuickBooks)
          ? (_openBlock(), _createBlock(QuickBooksTab, { key: 4 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})