import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }

import { propertyName, propertyValueType } from "@/common/lib/derived";
import { EqualityFilter } from "@/common/lib/fetchApi";
import { columnName, QueryFilter } from "@/common/lib/query";
import { GraphValueType, stringifyValue, toNative, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import SelectStrip from "@/editor/components/SelectStrip.vue";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'EqualityFilter',
  props: {
    filter: {}
  },
  setup(__props: any) {

const props = __props;
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | boolean | undefined> = ref(undefined);
const stringValueUnderEdit = valueUnderEdit as Ref<string | undefined>;
const booleanValueUnderEdit = valueUnderEdit as Ref<boolean>;
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);

const exploreStore = useExploreStore();

function negatedDescription() {
  return filter.value.negated ? "is not" : "is";
}

const valueType = computed(function () {
  if (filter.value.property_type != null) {
    return propertyValueType(filter.value.property_type) as GraphValueType;
  } else {
    return propertyValueType(
      exploreStore.columnByAlias(filter.value.on!)!.property_type
    ) as GraphValueType;
  }
});

function subjectName() {
  if (filter.value.property_type != null) {
    return propertyName(filter.value.property_type);
  } else {
    return columnName(exploreStore.columnByAlias(filter.value.on!)!);
  }
}

const opDescription = computed(function () {
  let desc = negatedDescription();
  if (filter.value.values.length > 1) desc = desc + " any of";
  return desc;
});

const formLabel = computed(function () {
  const bits = [subjectName(), negatedDescription()];
  if (valueType.value === GraphValueType.String) bits.push("any of");
  return bits.join(" ");
});

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);

const isValid = computed(
  () => valueType.value === GraphValueType.Bool || cleanStringInput().length > 0
);

function startEditing() {
  if (valueType.value === GraphValueType.Bool) {
    if (filter.value.values.length > 0) {
      valueUnderEdit.value = toNative(filter.value.values[0].value) as boolean;
    } else {
      valueUnderEdit.value = true; // Arbitrary default
    }
  } else {
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  }
  isEditing.value = true;
}

function cleanStringInput() {
  return stringValueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  if (valueType.value === GraphValueType.Bool) {
    filter.value.values = [{ value: toValue(booleanValueUnderEdit.value) }];
  } else {
    filter.value.values = cleanStringInput().map((v) => ({ value: toValue(v) }));
  }
  isEditing.value = false;
  exploreStore.load(UserAction.Update_Eq_Filter);
}

function focusFieldIfPresent() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    placement: "right",
    shown: isEditing.value,
    "onUpdate:shown": _cache[3] || (_cache[3] = ($event: any) => ((isEditing).value = $event)),
    onApplyShow: focusFieldIfPresent
  }, {
    popper: _withCtx(() => [
      _createVNode(FilterEditor, {
        label: formLabel.value,
        onCancel: _cache[2] || (_cache[2] = ($event: any) => (isEditing.value = false)),
        onCommit: commit,
        valid: isValid.value
      }, {
        default: _withCtx(() => [
          (valueType.value === _unref(GraphValueType).String)
            ? (_openBlock(), _createBlock(Textbox, {
                key: 0,
                label: "Values",
                "helper-text": "Enter one value per line",
                textarea: "",
                modelValue: _unref(stringValueUnderEdit),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(stringValueUnderEdit) ? (stringValueUnderEdit).value = $event : null)),
                ref_key: "valueInputEl",
                ref: valueInputEl
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (valueType.value === _unref(GraphValueType).Bool)
            ? (_openBlock(), _createBlock(SelectStrip, {
                key: 1,
                options: [
            { label: 'True', value: true },
            { label: 'False', value: false },
          ],
                modelValue: _unref(booleanValueUnderEdit),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(booleanValueUnderEdit) ? (booleanValueUnderEdit).value = $event : null)),
                class: "mb-5 h-[28px]"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500",
        onClick: startEditing
      }, [
        _createTextVNode(_toDisplayString(opDescription.value) + " ", 1),
        _createElementVNode("span", _hoisted_1, _toDisplayString(valueList.value), 1)
      ])
    ]),
    _: 1
  }, 8, ["shown"]))
}
}

})