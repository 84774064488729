import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10 w-[400px]" }
const _hoisted_2 = { class: "p-10 font-medium" }
const _hoisted_3 = { class: "text-right" }

import { DerivedPropertyTerm, propertyName } from "@/common/lib/derived";
import TextButton from "@/common/components/TextButton.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { expandTreePath } from "@/reader/lib/exploreTree";


export default /*@__PURE__*/_defineComponent({
  __name: 'CalculationForm',
  setup(__props) {

const exploreStore = useExploreStore();

const derivedTerm: Ref<DerivedPropertyTerm | null> = ref(null);

const isInvalid = computed(() => derivedTerm.value == null);

const description = computed(function () {
  if (derivedTerm.value == null) return "Please complete all fields above.";
  return "Property Name: " + propertyName(derivedTerm.value);
});

function commit() {
  const term = derivedTerm.value;
  if (term == null) return;
  exploreStore.addColumn({
    alias: uuidv4(),
    property_type: term,
    path: expandTreePath(exploreStore.creatingCalculation!),
  });
  exploreStore.creatingCalculation = undefined;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-lg font-bold uppercase" }, "Add Calculation", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(DerivedPropertyDef, {
        modelValue: derivedTerm.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((derivedTerm).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(description.value), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TextButton, {
        label: "Cancel",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(exploreStore).creatingCalculation = undefined), ["stop"])),
        secondary: true
      }),
      _createVNode(TextButton, {
        label: "Add",
        onClick: _withModifiers(commit, ["stop"]),
        disabled: isInvalid.value
      }, null, 8, ["disabled"])
    ])
  ], 64))
}
}

})