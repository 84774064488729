import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pl-10"
}

import GraphValueComponent from "@/common/components/GraphValue.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncValue } from "@/common/lib/async";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import { propertyValueMenu } from "../../lib/exploreMenus";
import { useExploreStore } from "../../stores/explore";
import MiniBar from "../MiniBar.vue";
import { last } from "lodash";
import { conceptPropertyValueDisplay, uniquelyIdentifiesConcept } from "../../lib/concept";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { FloatValue, toNative } from "@/common/lib/value";
import { columnIsDerived, QueryColumn } from "@/common/lib/query";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";


export default /*@__PURE__*/_defineComponent({
  __name: 'PropertyValue',
  props: {
    value: {},
    column: {},
    quantitative: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const { column, value } = toRefs(props);

const exploreStore = useExploreStore();

function conceptType() {
  if (column.value == null) return null;
  return column.value.path == null
    ? exploreStore.query!.root_concept_type
    : last(column.value.path)!.concept_type;
}

const menuItems = computed(() =>
  propertyValueMenu(value.value.originalValue, value.value.formattedValue, column?.value)
);

const hasBarChart = computed(
  () => column?.value != null && asyncValue(exploreStore.columnStats)![column.value.alias] != null
);

const hasConceptPage = computed(function () {
  if (column.value == null) return false;
  if (columnIsDerived(column.value)) return false;
  return uniquelyIdentifiesConcept(
    conceptType()!,
    column.value.property_type as PropertyKnowledgeRef
  );
});

const max = computed(() => {
  const stats = asyncValue(exploreStore.columnStats)![column.value?.alias ?? 0];
  return toNative(stats.max) as number;
});

const color = computed(function () {
  if (column.value == null || columnIsDerived(column.value)) return null;
  const ctype = conceptType();
  if (ctype == null) return null;
  const display = conceptPropertyValueDisplay(
    ctype,
    column.value.property_type as PropertyKnowledgeRef,
    value.value.originalValue
  );
  return display?.color ?? null;
});

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_unref(Dropdown), { placement: "auto" }, {
    popper: _withCtx(() => [
      _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex cursor-pointer items-baseline", {
        'justify-end': _ctx.quantitative,
        underline: hasConceptPage.value,
      }])
      }, [
        (color.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle({ backgroundColor: color.value }),
              class: "mr-4 h-10 w-10 rounded-full"
            }, null, 4))
          : _createCommentVNode("", true),
        _createVNode(GraphValueComponent, {
          value: _unref(value).formattedValue
        }, null, 8, ["value"]),
        (hasBarChart.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(MiniBar, {
                value: _unref(value).originalValue as FloatValue,
                max: max.value
              }, null, 8, ["value", "max"])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }))
}
}

})