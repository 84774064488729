import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "my-10" }
const _hoisted_3 = { class: "my-10" }
const _hoisted_4 = { class: "selection" }

import {
  DerivedPropertyTerm,
  DateDiffPropertyType,
  PropertyOpType,
  TimeUnit,
} from "@/common/lib/derived";
import { computed, inject, Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { DarkMode } from "@/common/lib/keys";
import vSelect from "vue-select";
import { capitalize } from "lodash";
import pluralize from "pluralize";


export default /*@__PURE__*/_defineComponent({
  __name: 'DateDiffPropertyDef',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const darkMode = inject(DarkMode) as Ref<boolean>;

const OP = PropertyOpType.DateDiff;

const unit = ref<TimeUnit>(TimeUnit.Day);
const start: Ref<DerivedPropertyTerm | null> = ref(null);
const end: Ref<DerivedPropertyTerm | null> = ref(null);

const model = _useModel<DateDiffPropertyType | null>(__props, "modelValue");

const options = computed(() => Object.values(TimeUnit));

function formatUnit(unit: TimeUnit) {
  return pluralize(capitalize(unit));
}

watchEffect(function () {
  if (start.value == null || end.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      unit: unit.value,
      start: start.value,
      end: end.value,
    };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "mb-5 block" }, "Start:", -1)),
      _createVNode(DerivedPropertyDef, {
        "parent-op": _unref(OP),
        modelValue: start.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((start).value = $event))
      }, null, 8, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "mb-5 block" }, "End:", -1)),
      _createVNode(DerivedPropertyDef, {
        "parent-op": _unref(OP),
        modelValue: end.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((end).value = $event))
      }, null, 8, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("label", { class: "mb-5 block" }, "Unit:", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(vSelect), {
          modelValue: unit.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((unit).value = $event)),
          options: options.value,
          appendToBody: true,
          class: _normalizeClass(["select", { 'light-select': !_unref(darkMode) }]),
          getOptionLabel: formatUnit
        }, null, 8, ["modelValue", "options", "class"])
      ])
    ])
  ], 64))
}
}

})