import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-0 flex flex-col"
}
const _hoisted_2 = {
  key: 0,
  class: "border-b border-b-gray-900 bg-gray-800 px-20 py-10"
}
const _hoisted_3 = { class: "flex flex-row justify-center gap-15" }
const _hoisted_4 = { class: "flex flex-row justify-center gap-15" }
const _hoisted_5 = { class: "flex min-h-0 basis-full flex-row" }
const _hoisted_6 = {
  key: 0,
  class: "relative flex-none basis-[325px] overflow-y-auto border-r border-gray-200 bg-gray-100 p-15 dark:border-r-gray-900 dark:bg-gray-800"
}
const _hoisted_7 = { class: "relative isolate flex basis-full flex-col" }
const _hoisted_8 = {
  key: 0,
  class: "absolute inset-0 z-10 bg-gray-200 dark:bg-gray-900"
}
const _hoisted_9 = { class: "absolute inset-0 z-0 flex flex-col" }
const _hoisted_10 = { class: "flex flex-none flex-row justify-between border-b border-b-gray-200 bg-gray-100 dark:border-b-gray-900 dark:bg-gray-800" }
const _hoisted_11 = { class: "flex flex-row" }
const _hoisted_12 = { class: "flex items-center justify-between gap-10 pr-5" }
const _hoisted_13 = {
  key: 1,
  class: "min-w-[120px]"
}
const _hoisted_14 = { class: "relative basis-full bg-white dark:bg-gray-900" }
const _hoisted_15 = {
  key: 1,
  class: "relative flex-none basis-[400px] border-l border-gray-300 dark:border-gray-900"
}

import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import Modal from "@/common/components/Modal.vue";
import CalculationForm from "../components/explore/calculation/CalculationForm.vue";
import MetagraphPane from "../components/explore/metagraph/MetagraphPane.vue";
import QueryBasics from "../components/explore/QueryBasics.vue";
import QueryProblems from "../components/explore/QueryProblems.vue";
import SqlView from "../components/explore/SqlView.vue";
import Table from "../components/explore/Table.vue";
import BookmarkMenu from "../components/explore/BookmarkMenu.vue";
import { Mode, useExploreStore, ExploreContext } from "../stores/explore";
import { useExploreMetagraphStore } from "../stores/exploreMetagraph";
import { computed, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import TabSelect from "@/common/components/TabSelect.vue";
import ExploreTools from "../components/explore/ExploreTools.vue";
import IconButton from "@/common/components/IconButton.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import AskBar from "../components/explore/AskBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import Textbox from "@/common/components/Textbox.vue";
import Dialog from "@/common/components/Dialog.vue";
import { cloneDeep } from "lodash";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import { validateQuery } from "@/common/lib/queryValidator";
import { consumeStoredExploreState, StoredExploreState } from "../lib/storage";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { storeExploreState } from "../lib/storage";
import Visualization from "../components/explore/Visualization.vue";
import ExploreFooter from "./ExploreFooter.vue";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'Explore',
  props: {
    bookmark: {},
    module: {},
    moduleId: {}
  },
  setup(__props: any) {

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();
const userModuleStore = useUserModuleStore();
const router = useRouter();
const route = useRoute();

const projectId = computed(() => exploreStore.module);
const bookmarksEnabled = computed(() => projectId.value);
const createTestEnabled = environment.requireBoolean("EXPLORER_CREATE_TEST_ENABLED");
const exportEnabled = environment.requireBoolean("EXCEL_EXPORT_ENABLED");
const showCreateTestDialog = ref(false);
const testName = ref("");

const bookmarkStatus: Ref<"create" | "update" | "showInvalidBookmark" | "failure" | undefined> =
  ref(undefined);
const bookmarkErrors: Ref<string[]> = ref([]);
const bookmarkName = ref<string>();
const currentBookmark: Ref<Bookmark | undefined> = ref(undefined);

const props = __props;
const exporting = ref(false);

const tabs = computed(() => {
  return [
    {
      label: "Table",
      key: Mode.Table,
      icon: "md:table",
      disabled: exploreStore.query == null,
    },
    {
      label: "Visualization",
      key: Mode.Visualization,
      icon: "md:insert_chart",
      disabled: exploreStore.query == null,
    },
    {
      label: "SQL",
      key: Mode.SQL,
      icon: "SQL",
      disabled: exploreStore.query == null,
    },
  ];
});

function createExplorerTest() {
  exploreStore.load(UserAction.Create_Explorer_Test, true, { testName: testName.value });
  testName.value = "";
  showCreateTestDialog.value = false;
}
async function loadBookmark(bookmarkId: string) {
  if (projectId.value) {
    try {
      const response = await userModuleStore.getBookmark(projectId.value, bookmarkId);
      const errors = validateQuery(response.state, exploreStore.metagraph);
      if (errors.length > 0) {
        bookmarkStatus.value = "showInvalidBookmark";
        bookmarkErrors.value = errors;
        currentBookmark.value = response;
      } else {
        router.push({
          name: route.name,
          params: { module: projectId.value },
        });

        exploreStore.loadQuery(response.state, UserAction.Load_Bookmark);
      }
    } catch (error: unknown) {
      handleBookmarkError(error);
    }
  }
}

async function handleBookmark() {
  if (bookmarkStatus.value == "create") {
    await addBookmark();
  } else {
    await updateBookmark();
  }
}

async function addBookmark() {
  if (!projectId.value || !bookmarkName.value) {
    return;
  }
  try {
    const bookmark = cloneDeep(exploreStore.query);
    if (!bookmark) return;
    const bookmarkId = await userModuleStore.newBookmark(projectId.value, {
      name: bookmarkName.value,
      description: "",
      state: bookmark,
    });

    if (bookmarkId) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error: unknown) {
    handleBookmarkError(error);
  }
}
async function updateBookmark() {
  const bookmarkToUpdate = currentBookmark.value;
  if (!projectId.value || !bookmarkName.value || !bookmarkToUpdate) {
    return;
  }
  try {
    const bookmarkId = await userModuleStore.updateBookmark(projectId.value, bookmarkToUpdate.id, {
      id: bookmarkToUpdate.id,
      name: bookmarkName.value,
      description: "",
    });

    if (bookmarkId && projectId.value) {
      userModuleStore.loadProjectBookmarks(projectId.value);
    }

    clearBookmark();
  } catch (error: unknown) {
    handleBookmarkError(error);
  }
}

async function deleteBookmark(bookmark: Bookmark | undefined) {
  if (projectId.value && bookmark) {
    try {
      await userModuleStore.deleteBookmark(projectId.value, bookmark.id);
      userModuleStore.loadProjectBookmarks(projectId.value);
    } catch (error: unknown) {
      handleBookmarkError(error);
    }

    clearBookmark();
  }
}

function openUpdateBookmark(bookmark: Bookmark) {
  bookmarkStatus.value = "update";
  currentBookmark.value = bookmark;
  bookmarkName.value = bookmark.metadata.name;
}

function displayError(invalidBookmark: Bookmark, errors: string[]) {
  bookmarkStatus.value = "showInvalidBookmark";
  bookmarkErrors.value = errors;
  currentBookmark.value = invalidBookmark;
}

function handleBookmarkError(error: unknown) {
  bookmarkStatus.value = "failure";
  bookmarkErrors.value = [String(error)];
}

function clearBookmark() {
  bookmarkName.value = undefined;
  bookmarkStatus.value = undefined;
}

async function downloadExcel() {
  exporting.value = true;
  try {
    await exploreStore.downloadExcel();
  } finally {
    exporting.value = false;
  }
}

const maybeState: Ref<StoredExploreState | null> = ref(null);

watch(
  () => exploreStore.metagraph,
  () => {
    if (exploreStore.module) {
      userModuleStore.loadProjectBookmarks(exploreStore.module);
    }
    if (props.bookmark) {
      loadBookmark(props.bookmark);
    } else if (maybeState.value != null) {
      exploreStore.restoreState(maybeState.value);
    }
  },
  { immediate: true }
);

watch(
  () => props.bookmark,
  (newVal) => {
    if (newVal) {
      loadBookmark(newVal);
    }
  }
);

const storeCurrentQuery = environment.requireBoolean("EXPLORER_STORE_CURRENT_QUERY");

onMounted(function () {
  if (storeCurrentQuery) {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }
  if (props.bookmark) {
    loadBookmark(props.bookmark);
  } else {
    maybeState.value = consumeStoredExploreState();
    if (maybeState.value != null) exploreStore.restoreState(maybeState.value);
  }
});

const handleBeforeUnload = () => {
  const query = exploreStore.query;
  query && storeExploreState(query);
};

if (storeCurrentQuery) {
  onUnmounted(function () {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (projectId.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_unref(exploreStore).context === _unref(ExploreContext).Embedded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(AskBar, { class: "flex basis-6/12" }),
                  _createVNode(BookmarkMenu, {
                    onUpdateBookmark: openUpdateBookmark,
                    onDisplayError: displayError,
                    onDeleteBookmark: deleteBookmark,
                    projectId: projectId.value
                  }, null, 8, ["projectId"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(exploreStore).context === _unref(ExploreContext).Standalone)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 1,
                to: "#title-bar-controls"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(AskBar, { class: "flex basis-6/12" }),
                  _createVNode(BookmarkMenu, {
                    onUpdateBookmark: openUpdateBookmark,
                    onDisplayError: displayError,
                    onDeleteBookmark: deleteBookmark,
                    projectId: projectId.value
                  }, null, 8, ["projectId"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_unref(exploreStore).showSidebars())
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(QueryBasics)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_unref(exploreMetagraphStore).visible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(MetagraphPane)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(TabSelect, {
                      tabs: tabs.value,
                      modelValue: _unref(exploreStore).mode,
                      "onUpdate:modelValue": _unref(exploreStore).setMode,
                      disabled: _unref(exploreStore).query == null
                    }, null, 8, ["tabs", "modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    (bookmarksEnabled.value && _unref(exploreStore).query)
                      ? (_openBlock(), _createBlock(IconButton2, {
                          key: 0,
                          name: "md:star",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (bookmarkStatus.value = 'create')),
                          size: "l"
                        }))
                      : _createCommentVNode("", true),
                    (_unref(exportEnabled))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          (!exporting.value)
                            ? (_openBlock(), _createBlock(TextButton, {
                                key: 0,
                                label: "Export to Excel",
                                icon: "table_export",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (downloadExcel()))
                              }))
                            : (_openBlock(), _createBlock(SpinnerButton, {
                                key: 1,
                                label: "Exporting…",
                                icon: true
                              }))
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(createTestEnabled))
                      ? (_openBlock(), _createBlock(TextButton, {
                          key: 2,
                          label: "Create test",
                          icon: "md:labs",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (showCreateTestDialog.value = true))
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(IconButton, {
                      class: "rotate-90",
                      name: _unref(exploreStore).toolsVisible ? 'reveal-panel' : 'hide-panel',
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(exploreStore).toolsVisible = !_unref(exploreStore).toolsVisible))
                    }, null, 8, ["name"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  (_unref(exploreStore).mode === _unref(Mode).Table)
                    ? (_openBlock(), _createBlock(AsyncWrapper, {
                        key: 0,
                        async: _unref(exploreStore).table
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Table)
                        ]),
                        _: 1
                      }, 8, ["async"]))
                    : _createCommentVNode("", true),
                  (_unref(exploreStore).mode === _unref(Mode).Visualization)
                    ? (_openBlock(), _createBlock(Visualization, { key: 1 }))
                    : _createCommentVNode("", true),
                  (_unref(exploreStore).mode === _unref(Mode).SQL)
                    ? (_openBlock(), _createBlock(AsyncWrapper, {
                        key: 2,
                        async: _unref(exploreStore).sqlData
                      }, {
                        default: _withCtx(() => [
                          _createVNode(SqlView)
                        ]),
                        _: 1
                      }, 8, ["async"]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(QueryProblems),
                _createVNode(ExploreFooter)
              ])
            ]),
            (_unref(exploreStore).toolsVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(ExploreTools)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(exploreStore).creatingCalculation !== undefined)
      ? (_openBlock(), _createBlock(Modal, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(CalculationForm)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (showCreateTestDialog.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 2,
          title: "Create Query Engine Test",
          "success-label": "Create Test",
          onSucceeded: _cache[5] || (_cache[5] = ($event: any) => (createExplorerTest())),
          onCancelled: _cache[6] || (_cache[6] = ($event: any) => (showCreateTestDialog.value = false))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Name of integration test",
              modelValue: testName.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((testName).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (bookmarkStatus.value === 'create' || bookmarkStatus.value === 'update')
      ? (_openBlock(), _createBlock(Dialog, {
          key: 3,
          title: bookmarkStatus.value === 'create' ? 'Add Bookmark' : 'Rename Bookmark',
          icon: "md:star",
          "success-label": "Done",
          onSucceeded: handleBookmark,
          onCancelled: clearBookmark
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Bookmark Name",
              modelValue: bookmarkName.value,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((bookmarkName).value = $event)),
              autoselect: true,
              required: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (bookmarkStatus.value === 'showInvalidBookmark' || bookmarkStatus.value === 'failure')
      ? (_openBlock(), _createBlock(Dialog, {
          key: 4,
          title: "Error Details",
          icon: "md:error",
          "cancel-label": bookmarkStatus.value === 'showInvalidBookmark' ? 'Delete bookmark' : null,
          onSucceeded: _cache[8] || (_cache[8] = ($event: any) => (bookmarkStatus.value = undefined)),
          onCancelled: _cache[9] || (_cache[9] = ($event: any) => (deleteBookmark(currentBookmark.value)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bookmarkErrors.value, (error, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(error), 1))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["cancel-label"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})