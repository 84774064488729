import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString } from "vue"

import { ExistenceFilter } from "@/common/lib/fetchApi";
import { QueryFilter } from "@/common/lib/query";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExistenceFilter',
  props: {
    filter: {}
  },
  setup(__props: any) {

const props = __props;
const { filter } = toRefs(props);

const opDescription = computed(function () {
  return filter.value.negated ? "has no value" : "has any value";
});

return (_ctx: any,_cache: any) => {
  return _toDisplayString(opDescription.value)
}
}

})