import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative inline-block h-[14px] w-[22px]" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "ml-20 mt-10 text-gray-700 dark:text-gray-200"
}

import { computed, ref, useSlots } from "vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Toggle',
  props: {
    label: {},
    size: { default: "s" },
    modelValue: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const slots = useSlots();

const showDescription = ref(false);

function toggleDescription() {
  showDescription.value = !showDescription.value;
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row items-center gap-5", { 'opacity-50': _ctx.disabled }])
    }, [
      _createElementVNode("label", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          disabled: _ctx.disabled,
          class: "size-0 opacity-0"
        }, null, 8, _hoisted_2), [
          [_vModelCheckbox, value.value]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["track absolute inset-0 cursor-pointer rounded-full transition duration-300", { 'bg-orange': value.value, 'bg-gray-500 dark:bg-gray-700': !value.value }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["knob absolute bottom-4 left-4 size-[6px] rounded-full bg-white transition duration-300 dark:bg-gray-800", { 'translate-x-10': value.value }])
          }, null, 2)
        ], 2)
      ]),
      _createElementVNode("label", {
        class: _normalizeClass(["pb-1 text-gray-500", { 'text-base font-bold': _ctx.size === 'l', 'text-xs': _ctx.size === 's' }])
      }, _toDisplayString(_ctx.label), 3),
      (_unref(slots).help)
        ? (_openBlock(), _createBlock(Icon, {
            key: 0,
            class: "ml-5 opacity-50",
            name: "help",
            color: "white",
            size: "m",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleDescription()))
          }))
        : _createCommentVNode("", true)
    ], 2),
    (showDescription.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "help")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})