import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "sticky" }
const _hoisted_3 = {
  key: 1,
  class: "w-[25px]"
}
const _hoisted_4 = { class: "p-10 align-baseline" }
const _hoisted_5 = {
  key: 0,
  class: "select-text text-nowrap text-right"
}
const _hoisted_6 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_7 = {
  key: 0,
  class: "p-10 align-baseline"
}
const _hoisted_8 = {
  key: 0,
  class: "select-text text-nowrap text-right opacity-75"
}
const _hoisted_9 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_10 = {
  key: 1,
  class: "p-10 align-baseline"
}
const _hoisted_11 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_12 = {
  key: 0,
  class: "bg-red select-text text-nowrap text-right"
}
const _hoisted_13 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_14 = { class: "p-10 align-baseline" }
const _hoisted_15 = {
  key: 0,
  class: "select-text text-nowrap text-right opacity-75"
}
const _hoisted_16 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_17 = { class: "p-10 align-baseline" }
const _hoisted_18 = {
  key: 1,
  class: "text-right opacity-50"
}

import { FinComputedCell, FinComputedRow } from "../FinancialStatement.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { isNumeric, isValue, toNative } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialSubtable',
  props: {
    rows: {},
    columns: {},
    trailingColumns: {},
    depth: {},
    expandedMap: {},
    skipMonths: {}
  },
  emits: ["update-expanded"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { depth, expandedMap } = toRefs(props);

const emits = __emit;

function cells(row: FinComputedRow): FinComputedCell[] {
  return props.columns.map((_, index) => row.cells[index]);
}

function isAsset(cell: FinComputedCell): number {
  const originalValue = cell.overPreviousPeriod?.originalValue;
  if (!isValue(originalValue) || !isNumeric(originalValue)) {
    return 0;
  }
  return cell.asset * toNative(originalValue);
}

function isAssetVariance(value: ValueWithFormattedValue | undefined, asset: number): number {
  const originalValue = value?.originalValue;
  if (!isValue(originalValue) || !isNumeric(originalValue)) {
    return 0;
  }
  return asset * toNative(originalValue);
}

function expandMap(rowLabel: string) {
  emits("update-expanded", rowLabel);
}

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));

return (_ctx: any,_cache: any) => {
  const _component_FinancialSubtable = _resolveComponent("FinancialSubtable", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndex }, [
      _createElementVNode("tr", {
        class: _normalizeClass([{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight,
      }, "cursor-pointer hover:bg-finance-highlight"]),
        onClick: ($event: any) => (row.contents != null && expandMap(row.label))
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex flex-row items-center text-nowrap font-bold",
            style: _normalizeStyle(indentStyle.value)
          }, [
            (row.contents != null)
              ? (_openBlock(), _createBlock(Icon, {
                  key: 0,
                  name: 
              _unref(expandedMap).has(row.label) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'
            ,
                  color: "medium-gray",
                  size: "xl"
                }, null, 8, ["name"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3)),
            _createTextVNode(" " + _toDisplayString(row.label), 1)
          ], 4)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cells(row), (cell, colIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: colIndex }, [
            (!_ctx.skipMonths || colIndex >= _ctx.skipMonths)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("td", _hoisted_4, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(colIndex == 0 ? indentStyle.value : undefined)
                    }, [
                      (cell.value != null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(GraphValueComponent, {
                              value: cell.value.formattedValue
                            }, null, 8, ["value"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                    ], 4)
                  ]),
                  (colIndex > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                        (
                cell.overComparisonValue != null &&
                cell.overComparisonValue.formattedValue.value !== '100%'
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(GraphValueComponent, {
                                value: cell.overComparisonValue?.formattedValue
                              }, null, 8, ["value"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                      ]))
                    : _createCommentVNode("", true),
                  (colIndex > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                        (cell.overPreviousPeriod != null)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["select-text text-nowrap text-right opacity-75", {
                'text-red-500': isAsset(cell) < 0,
                'text-green-500': isAsset(cell) > 0,
              }])
                            }, [
                              _createVNode(GraphValueComponent, {
                                value: cell.overPreviousPeriod?.formattedValue
                              }, null, 8, ["value"])
                            ], 2))
                          : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trailingColumns, (colName, colIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: colName }, [
            _createElementVNode("td", {
              class: _normalizeClass(["p-10 align-baseline", { 'border-l-[8px] border-solid border-l-finance-highlight': colIndex === 0 }])
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(colIndex == 0 ? indentStyle.value : undefined)
              }, [
                (row.cells[colIndex + _ctx.columns.length].value?.formattedValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(GraphValueComponent, {
                        value: row.cells[colIndex + _ctx.columns.length].value!.formattedValue
                      }, null, 8, ["value"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
              ], 4)
            ], 2),
            _createElementVNode("td", _hoisted_14, [
              (
              row.cells[colIndex + _ctx.columns?.length].overComparisonValue != null &&
              row.cells[colIndex + _ctx.columns?.length].overComparisonValue!.formattedValue.value !==
                '100%'
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(GraphValueComponent, {
                      value: row.cells[colIndex + _ctx.columns?.length].overComparisonValue!.formattedValue
                    }, null, 8, ["value"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
            ])
          ], 64))
        }), 128)),
        _createElementVNode("td", _hoisted_17, [
          (row.variance != null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["select-text text-nowrap text-right font-bold", {
            'text-red-500': isAssetVariance(row.variance, row.cells[0].asset) < 0,
            'text-green-500': isAssetVariance(row.variance, row.cells[0].asset) > 0,
          }])
              }, [
                _createVNode(GraphValueComponent, {
                  value: row.variance?.formattedValue
                }, null, 8, ["value"])
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
        ])
      ], 10, _hoisted_1),
      (row.contents != null && _unref(expandedMap).has(row.label))
        ? (_openBlock(), _createBlock(_component_FinancialSubtable, {
            key: 0,
            rows: row.contents,
            columns: _ctx.columns,
            depth: _unref(depth) + 1,
            trailingColumns: _ctx.trailingColumns,
            expandedMap: _unref(expandedMap),
            onUpdateExpanded: _cache[0] || (_cache[0] = (rowLabel) => emits('update-expanded', rowLabel)),
            "skip-months": _ctx.skipMonths
          }, null, 8, ["rows", "columns", "depth", "trailingColumns", "expandedMap", "skip-months"]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}
}

})