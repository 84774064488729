import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }

import { MinPropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";


export default /*@__PURE__*/_defineComponent({
  __name: 'MinPropertyDef',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const OP = PropertyOpType.Min;

const term: Ref<PropertyKnowledgeRef | null> = ref(null);

const model = _useModel<MinPropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (term.value == null) {
    model.value = null;
  } else {
    model.value = { op: OP, term: term.value };
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "mb-5 block" }, "Smallest value of:", -1)),
    _createVNode(DerivedPropertyDef, {
      "parent-op": _unref(OP),
      modelValue: term.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((term).value = $event))
    }, null, 8, ["parent-op", "modelValue"])
  ]))
}
}

})