import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "font-bold" }

import { TextFilterMatch, textFilterMatchOptions } from "@/common/lib/query";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import Select from "@/common/components/Select.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import { QueryFilter } from "@/common/lib/query";
import { TextFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import { propertyName } from "@/common/lib/derived";
import Textbox from "@/common/components/Textbox.vue";
import { UserAction } from "@/common/lib/userAction";
import { columnName } from "@/common/lib/query";

interface TextFilterMatchDetails {
  label: string;
  description: string;
  negatedDescription: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextFilter',
  props: {
    filter: {}
  },
  setup(__props: any) {

const props = __props;
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | undefined> = ref(undefined);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const matchType = ref<TextFilterMatch>(TextFilterMatch.Full);
const ignoreCase = ref(true);

function optionDetails(option: TextFilterMatch): TextFilterMatchDetails {
  switch (option) {
    case TextFilterMatch.Contain:
      return {
        label: "Contains",
        description: "contains",
        negatedDescription: "doesn't contain",
      };
    case TextFilterMatch.Start:
      return {
        label: "Starts with",
        description: "starts with",
        negatedDescription: "doesn't start with",
      };
    case TextFilterMatch.End:
      return {
        label: "Ends with",
        description: "ends with",
        negatedDescription: "doesn't end with",
      };
    case TextFilterMatch.Full:
      return {
        label: "Exact match",
        description: "exactly matches",
        negatedDescription: "doesn't exactly match",
      };
  }
}

function subjectName() {
  if (filter.value.property_type != null) {
    return propertyName(filter.value.property_type);
  } else {
    return columnName(exploreStore.columnByAlias(filter.value.on!)!);
  }
}

const opDescription = computed(() => {
  const value = filter.value.values[0];
  const match = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  return makeDescription(match, filter.value.values.length > 1);
});

const editingDescription = computed(
  () => `${subjectName()} ${makeDescription(matchType.value, true)}`
);

function makeDescription(option: TextFilterMatch, multiple: boolean) {
  let desc = "";
  const details = optionDetails(option);
  desc = filter.value.negated ? details.negatedDescription : details.description;
  if (multiple) {
    desc = desc + " any of";
  }
  return desc;
}

function optionLabel(option: TextFilterMatch) {
  return optionDetails(option).label;
}

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function focusFirstField() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

function startEditing() {
  const value = filter.value.values[0];
  valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  matchType.value = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  ignoreCase.value = !(value?.case_sensitive ?? false);
  isEditing.value = true;
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({
    value: toValue(v),
    match: matchType.value,
    case_sensitive: !ignoreCase.value,
  }));
  isEditing.value = false;
  exploreStore.load(UserAction.Update_Text_Filter);
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    placement: "right",
    shown: isEditing.value,
    "onUpdate:shown": _cache[4] || (_cache[4] = ($event: any) => ((isEditing).value = $event)),
    onApplyShow: focusFirstField
  }, {
    popper: _withCtx(() => [
      _createVNode(FilterEditor, {
        label: editingDescription.value,
        valid: isValid.value,
        onCancel: _cache[3] || (_cache[3] = ($event: any) => (isEditing.value = false)),
        onCommit: commit
      }, {
        default: _withCtx(() => [
          _createVNode(Textbox, {
            label: "Values",
            "helper-text": "Enter one value per line",
            textarea: "",
            modelValue: valueUnderEdit.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valueUnderEdit).value = $event)),
            ref_key: "valueInputEl",
            ref: valueInputEl
          }, null, 8, ["modelValue"]),
          _createVNode(Select, {
            modelValue: matchType.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((matchType).value = $event)),
            label: "Matching mode",
            options: _unref(textFilterMatchOptions),
            "option-label": optionLabel,
            clearable: false
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(Checkbox, {
              label: "Ignore case",
              modelValue: ignoreCase.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ignoreCase).value = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: startEditing,
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
      }, [
        _createTextVNode(_toDisplayString(opDescription.value) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(valueList.value), 1)
      ])
    ]),
    _: 1
  }, 8, ["shown"]))
}
}

})