import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "isolate" }
const _hoisted_2 = {
  key: 0,
  class: "text-xxxl"
}
const _hoisted_3 = { class: "absolute inset-0" }

import { Async, AsyncStatus } from "@/common/lib/async";
import { toRefs } from "vue";
import Spinner from "./Spinner.vue";
import WarehouseLoading from "./WarehouseLoading.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncWrapper',
  props: {
    async: {},
    overlay: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const { async } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(async).status !== _unref(AsyncStatus).Succeeded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute inset-0 z-10 flex flex-col items-center justify-center gap-10 text-black dark:text-white", { 'bg-white bg-opacity-85 dark:bg-black dark:bg-opacity-85': _ctx.overlay }])
        }, [
          (_unref(async).status === _unref(AsyncStatus).Failed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(async).message), 1))
            : _createCommentVNode("", true),
          (_unref(async).status === _unref(AsyncStatus).InProgress)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(Spinner, { size: "xxl" }),
                _createElementVNode("div", {
                  class: _normalizeClass(["mt-10 text-xxxl", { 'opacity-60': _ctx.overlay }])
                }, _toDisplayString(_unref(async).message), 3),
                _createElementVNode("div", {
                  class: _normalizeClass({ 'opacity-60': _ctx.overlay })
                }, [
                  _createVNode(WarehouseLoading)
                ], 2)
              ], 64))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})