import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-around" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "max-w-[400px] p-5" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "hidden whitespace-nowrap text-base group-focus-within:block"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 3,
  class: "opacity-25 group-focus-within:hidden"
}

import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { AsyncStatus, asyncFailureMessage, asyncNotStarted } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { trim } from "lodash";
import { computed, ref } from "vue";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { environment } from "@/common/environments/environmentLoader";
import { Tooltip } from "floating-vue";
import TextButton from "@/common/components/TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AskBar',
  setup(__props) {

const inputText = ref("");
const askBar = ref<HTMLTextAreaElement | undefined>(undefined);
const askBarKeyCommand = KeyCommand.readKeyCommand(environment.require("KEY_COMMAND_ASK_BAR"));
useKeyHandler().registerHandler(askBarKeyCommand, () => askBar.value?.select(), true, false);

const exploreStore = useExploreStore();

const sanitizedInput = computed(() => trim(inputText.value));
const hasInput = computed(() => sanitizedInput.value.length > 0);
const inProgress = computed(() => exploreStore.askResponse.status === AsyncStatus.InProgress);
const succeeded = computed(() => exploreStore.askResponse.status === AsyncStatus.Succeeded);
const errorMessage = computed(() => asyncFailureMessage(exploreStore.askResponse));

function submitQuestion() {
  exploreStore.askQuestion(sanitizedInput.value);
}

function prepareForNewQuestion() {
  if (exploreStore.askResponse.status != AsyncStatus.NotStarted) {
    askBar.value?.select();
    exploreStore.askResponse = asyncNotStarted();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Tooltip), {
      shown: errorMessage.value != null,
      triggers: [],
      placement: "bottom",
      class: "max-w-4xl flex-none basis-full"
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(errorMessage.value), 1),
          _createVNode(TextButton, {
            label: "OK",
            onClick: prepareForNewQuestion
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["group flex h-[35px] items-center rounded-full border border-gray-700 px-10 text-xl", {
          'bg-white dark:bg-gray-900': inProgress.value || succeeded.value,
          'focus-within:border-orange focus-within:bg-white dark:focus-within:bg-gray-900':
            !inProgress.value,
        }])
        }, [
          _createVNode(Icon, {
            name: "ct-chat",
            size: "xl",
            color: "orange"
          }),
          _withDirectives(_createElementVNode("input", {
            ref_key: "askBar",
            ref: askBar,
            class: "basis-full border-none bg-transparent p-5 outline-none",
            placeholder: "Ask a question about your data",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputText).value = $event)),
            disabled: inProgress.value,
            onKeyup: _withKeys(submitQuestion, ["enter"]),
            onFocus: prepareForNewQuestion,
            "data-test": "explore-llm-textbox"
          }, null, 40, _hoisted_4), [
            [_vModelText, inputText.value]
          ]),
          (hasInput.value && !inProgress.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                _createTextVNode(" Press "),
                _createElementVNode("span", { class: "rounded-sm border border-gray-500 px-[4px] py-2" }, "Enter", -1),
                _createTextVNode(" to query ")
              ])))
            : _createCommentVNode("", true),
          (inProgress.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(Spinner)
              ]))
            : _createCommentVNode("", true),
          (succeeded.value)
            ? (_openBlock(), _createBlock(Icon, {
                key: 2,
                name: "check",
                size: "xl",
                color: "orange"
              }))
            : _createCommentVNode("", true),
          (!hasInput.value && !inProgress.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(askBarKeyCommand).format()), 1))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["shown"])
  ]))
}
}

})