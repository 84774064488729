import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
}
const _hoisted_2 = { class: "item-start flex flex-col justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }
const _hoisted_3 = {
  key: 0,
  class: "m-10 flex flex-col justify-between gap-10"
}
const _hoisted_4 = {
  key: 1,
  class: "m-10 flex flex-col justify-between gap-10"
}
const _hoisted_5 = { class: "flex items-center justify-end gap-10 self-stretch" }

import TextButton from "@/common/components/TextButton.vue";
import { ref, onMounted } from "vue";
import DbxEditConnectionSettings from "./DbxEditConnectionSettings.vue";
import SnowflakeEditConnectionSettings from "./SnowflakeEditConnectionSettings.vue";
import {
  useWorkspaceStore,
  FullWorkspace,
  WorkspacesState,
  ProviderType,
} from "@/common/stores/workspaceStore";
import ProviderButton from "./ProviderButton.vue";
import { storeToRefs } from "pinia";
import MySqlEditConnectionSettings from "./MySqlEditConnectionSettings.vue";
import { environment } from "@/common/environments/environmentLoader";
import StarRocksConnectionSettings from "./StarRocksConnectionSettings.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EditConnectionSettings',
  props: {
    mode: {},
    workspaceId: {}
  },
  emits: ["saveWorkspace"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const enabledProviders: string[] = environment.requireJson("PROVIDER_ENABLED_SET");

const selectedProvider = ref<ProviderType | undefined>();
const workspaceStore = useWorkspaceStore();
const { workspacesState } = storeToRefs(workspaceStore);

const workspaceSettings = ref<FullWorkspace>();

function cancelEdit() {
  if (props.mode === "edit") {
    cancelEditWorkspace();
  } else {
    selectedProvider.value = undefined;
  }
}

function cancelEditWorkspace() {
  emits("saveWorkspace");
}

onMounted(async () => {
  if (props.mode === "create") {
    return;
  }
  if (props.workspaceId) {
    const workspace = await workspaceStore.loadFullWorkspace(props.workspaceId);
    workspaceSettings.value = workspace;
    selectedProvider.value = workspace.metadata?.provider;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mode === 'create')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[9] || (_cache[9] = _createElementVNode("span", null, " Select your data provider: ", -1)),
            (!selectedProvider.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_unref(enabledProviders).includes('dbx'))
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 0,
                        "img-src": "/images/providers/dbx/databricks-wide.svg",
                        height: "40px",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectedProvider.value = _unref(ProviderType).Databricks))
                      }))
                    : _createCommentVNode("", true),
                  (_unref(enabledProviders).includes('snowflake'))
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 1,
                        "img-src": "/images/providers/snowflake/snowflake-wide.svg",
                        height: "50px",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedProvider.value = _unref(ProviderType).Snowflake))
                      }))
                    : _createCommentVNode("", true),
                  (_unref(enabledProviders).includes('mysql'))
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 2,
                        "img-src": "/images/providers/mysql/mysql-wide.svg",
                        height: "100px",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (selectedProvider.value = _unref(ProviderType).MySQL))
                      }))
                    : _createCommentVNode("", true),
                  (_unref(enabledProviders).includes('starrocks'))
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 3,
                        "img-src": "/images/providers/starrocks/starrocks-wide.svg",
                        height: "35px",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (selectedProvider.value = _unref(ProviderType).StarRocks))
                      }))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (selectedProvider.value === _unref(ProviderType).Databricks)
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 0,
                        "img-src": "/images/providers/dbx/databricks-wide.svg",
                        height: "40px",
                        static: true
                      }))
                    : _createCommentVNode("", true),
                  (selectedProvider.value === _unref(ProviderType).Snowflake)
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 1,
                        "img-src": "/images/providers/snowflake/snowflake-wide.svg",
                        height: "50px",
                        static: true
                      }))
                    : _createCommentVNode("", true),
                  (selectedProvider.value == _unref(ProviderType).MySQL)
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 2,
                        "img-src": "/images/providers/mysql/mysql-wide.svg",
                        height: "100px",
                        static: true
                      }))
                    : _createCommentVNode("", true),
                  (selectedProvider.value == _unref(ProviderType).StarRocks)
                    ? (_openBlock(), _createBlock(ProviderButton, {
                        key: 3,
                        "img-src": "/images/providers/starrocks/starrocks-wide.svg",
                        height: "35px",
                        static: true
                      }))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1)),
            (selectedProvider.value === undefined && _unref(workspacesState) !== _unref(WorkspacesState).NoWorkspaces)
              ? (_openBlock(), _createBlock(TextButton, {
                  key: 0,
                  label: "Cancel",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (cancelEditWorkspace()))
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (selectedProvider.value === 'dbx')
      ? (_openBlock(), _createBlock(DbxEditConnectionSettings, {
          key: 1,
          "current-workspace": workspaceSettings.value,
          mode: _ctx.mode,
          onCancel: cancelEdit,
          onSaveWorkspace: _cache[5] || (_cache[5] = ($event: any) => (cancelEditWorkspace()))
        }, null, 8, ["current-workspace", "mode"]))
      : _createCommentVNode("", true),
    (selectedProvider.value === 'snowflake')
      ? (_openBlock(), _createBlock(SnowflakeEditConnectionSettings, {
          key: 2,
          "current-workspace": workspaceSettings.value,
          mode: _ctx.mode,
          onCancel: cancelEdit,
          onSaveWorkspace: _cache[6] || (_cache[6] = ($event: any) => (cancelEditWorkspace()))
        }, null, 8, ["current-workspace", "mode"]))
      : _createCommentVNode("", true),
    (selectedProvider.value === 'mysql')
      ? (_openBlock(), _createBlock(MySqlEditConnectionSettings, {
          key: 3,
          "current-workspace": workspaceSettings.value,
          mode: _ctx.mode,
          onCancel: cancelEdit,
          onSaveWorkspace: _cache[7] || (_cache[7] = ($event: any) => (cancelEditWorkspace()))
        }, null, 8, ["current-workspace", "mode"]))
      : _createCommentVNode("", true),
    (selectedProvider.value === 'starrocks')
      ? (_openBlock(), _createBlock(StarRocksConnectionSettings, {
          key: 4,
          "current-workspace": workspaceSettings.value,
          mode: _ctx.mode,
          onCancel: cancelEdit,
          onSaveWorkspace: _cache[8] || (_cache[8] = ($event: any) => (cancelEditWorkspace()))
        }, null, 8, ["current-workspace", "mode"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})