import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IconButton from "@/common/components/IconButtonV2.vue";
import { toRefs } from "vue";
import { IconSize } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Disclosure',
  props: {
    expanded: { type: Boolean },
    size: { default: "l" }
  },
  emits: ["toggle"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { expanded, size } = toRefs(props);

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(IconButton, {
    name: _unref(expanded) ? 'arrow_drop_down' : 'arrow_right',
    color: "white",
    size: _unref(size),
    "target-size": 20,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('toggle')))
  }, null, 8, ["name", "size"]))
}
}

})