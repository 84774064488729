import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = { class: "flex h-[25px] items-center justify-start whitespace-nowrap border-b border-r border-white px-10 text-right" }
const _hoisted_4 = { class: "mx-10" }
const _hoisted_5 = { class: "my-10 flex gap-10" }

import { generateValue } from "@/reader/lib/visualization";
import {
  FinancialReportVisualization,
  FinancialStatementRow,
} from "@/reader/lib/visualizationTypes";
import { computed, onMounted, ref, toRefs } from "vue";
import { GraphValue, isValue, stringifyValue, toNative } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { range, sortBy, keys, uniqBy, groupBy, entries, isNil, isNumber } from "lodash";
import { toggle } from "@/common/lib/set";
import { getCookie, setCookie } from "@/common/lib/cookie";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";
import FinancialSubreport from "./financial-statement/FinancialSubreport.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { environment } from "@/common/environments/environmentLoader";
import { excelExport, ExportType } from "@/common/lib/export";
import { useUserStore } from "@/common/stores/userStore";
import TextButton from "@/common/components/TextButton.vue";
import ExcelJS from "exceljs";

export interface FinComputedCell {
  value?: ValueWithFormattedValue;
  overComparisonValue?: ValueWithFormattedValue;
  overPreviousPeriod?: ValueWithFormattedValue;
  asset: number;
}

export interface FinComputedRow {
  label: string;
  cells: FinComputedCell[];
  contents?: FinComputedRow[];
  highlight: boolean;
  isTotal?: boolean;
  variance?: ValueWithFormattedValue;
}

interface RawLine {
  account: string;
  parentAccount?: string;
  amounts?: Record<string, ValueWithFormattedValue>;
}

interface RawLineSingle {
  account: string;
  parentAccount?: string;
  amount?: ValueWithFormattedValue;
  depth: number;
}

interface HRawLine extends RawLine {
  contents: HRawLine[];
  isTotal?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialReport',
  props: {
    visualization: {},
    results: {},
    width: {},
    height: {}
  },
  emits: ["select"],
  setup(__props: any) {

const props = __props;



const { visualization, results } = toRefs(props);
const columnValues = computed(function () {
  const query = combinedQuerySignature(visualization.value.query);
  const gen = visualization.value.config.columns;
  const allResults = results.value.map(function (period) {
    return generateValue(gen, period, query);
  });
  return uniqBy(allResults, (r) => r?.originalValue?.value);
});

const expandedMap = ref(new Set<string>());

function updateExpanded(rowLabel: string) {
  toggle(expandedMap.value, rowLabel);
  const maxAge = environment.requireNumber("FINANCIAL_REPORT_EXPANSION_MAX_AGE_SECONDS");
  setCookie(
    visualization.value.config.alias,
    JSON.stringify(Array.from(expandedMap.value)),
    maxAge
  );
}

const columns = computed(function () {
  return columnValues.value.map(function (value) {
    return value ? stringifyValue(value.formattedValue) : "???";
  });
});

function buildRow(rowDef: FinancialStatementRow): FinComputedRow {
  const cells = buildCells(rowDef);

  return {
    label: rowDef.label,
    cells,
    contents: rowDef.contents == null ? undefined : buildSubtable(rowDef.contents),
    highlight: rowDef.highlight ?? false,
  };
}

function buildSubtable(rowDefs: FinancialStatementRow[]): FinComputedRow[] {
  return rowDefs.map((rowDef) => buildRow(rowDef));
}

function buildCells(rowDef: FinancialStatementRow): FinComputedCell[] {
  const query = combinedQuerySignature(visualization.value.query);
  const cells: FinComputedCell[] = [];

  for (const index of range(results.value.length)) {
    const period = results.value[index];
    const value = generateValue(rowDef.value, period, query) ?? undefined;

    cells.push({
      value,
      asset: rowDef.asset === true ? 1 : -1,
    });
  }
  return cells;
}

function toFinComputedRow(line: HRawLine, keys: string[], highlight = true): FinComputedRow {
  let contents: FinComputedRow[] | undefined = line.contents.map((c) =>
    toFinComputedRow(c, keys, false)
  );
  contents = sortBy(contents, (row) => row.label);
  if (contents.length === 0) {
    contents = undefined;
  }
  const cells: FinComputedCell[] = keys.map((key) => {
    const amount = line.amounts?.[key];
    return { value: amount, asset: 0 };
  });
  return {
    label: line.account,
    cells,
    contents,
    highlight,
    isTotal: line.isTotal,
  };
}

const table = computed(() => {
  const query = combinedQuerySignature(visualization.value.query);
  const resultMap: Record<string, RawLineSingle[]> = {};
  for (const index of range(results.value.length)) {
    const period = results.value[index];
    const amount = generateValue("amount", period, query) ?? undefined;
    const account = stringifyValue(generateValue("account", period, query)?.formattedValue);
    const parentAccount = stringifyValue(
      generateValue("parent_account", period, query)?.formattedValue
    );
    const month = stringifyValue(generateValue("month", period, query)?.formattedValue);
    const lines = resultMap[month] ?? [];
    lines.push({
      account,
      parentAccount,
      depth: 0,
      amount,
    });
    resultMap[month] = lines;
  }
  return indexData(mergeLines(resultMap)).map((line) => toFinComputedRow(line, keys(resultMap)));
});

function indexData(lines: RawLine[]): HRawLine[] {
  // Index by parent account
  const linesByParent = groupBy(lines, (line) => line.parentAccount);
  const output: HRawLine[] = [];

  function indexDatum(parent: RawLine, children: RawLine[]): HRawLine {
    const contents = children.map((child) => {
      const grandChildren = linesByParent[child.account] ?? [];
      return indexDatum(child, grandChildren);
    });
    return Object.assign({ contents }, parent);
  }

  function getSubtotal(account: string, isTotal = false) {
    const accountLine = lines.find((line) => line.account === account)!;
    const accountLines = linesByParent[account] ?? [];
    const subtotal = indexDatum(accountLine, accountLines);
    subtotal.isTotal = isTotal;
    return subtotal;
  }

  for (const account of visualization.value.config.accounts) {
    output.push(getSubtotal(account.account, account.is_total));
  }
  return output;
}

function mergeLines(linesByDate: Record<string, RawLineSingle[]>): RawLine[] {
  function toKey(account: string, parentAccount?: string): string {
    return `${account}--${parentAccount}`;
  }

  const allLines = new Map<string, RawLine>();
  for (const [date, lines] of entries(linesByDate)) {
    for (const line of lines) {
      if (isNil(line.amount)) {
        continue;
      }
      const key = toKey(line.account, line.parentAccount);
      const newLine: RawLine = allLines.get(key) ?? {
        account: line.account,
        parentAccount: line.parentAccount,
        amounts: {},
      };
      if (isNil(newLine.amounts)) {
        newLine.amounts = {};
      }
      newLine.amounts[date] = line.amount;
      allLines.set(key, newLine);
    }
  }
  return Array.from(allLines.values());
}

function formatValue(value: ValueWithFormattedValue | undefined) {
  if (isNil(value)) {
    return undefined;
  }
  if (!isValue(value.originalValue)) {
    return toNative(value.formattedValue);
  }
  const result = toNative(value.originalValue);
  if (isNumber(result)) {
    return Math.floor(result * 100) / 100;
  }
  return result;
}

async function exportData(exportType: ExportType) {
  const userName = useUserStore().user?.name;
  const exportColumns: Partial<ExcelJS.Column>[] = [
    {
      width: visualization.value.config.exports?.title_column_width ?? 32,
      style: { font: { bold: true } },
    }, // Label
  ];

  const colsInRange = columns.value;
  const rowsToProcess = table.value;
  const data: unknown[][] = [];
  while (rowsToProcess.length > 0) {
    const row = rowsToProcess.shift()!;
    const dataRow: unknown[] = [];
    dataRow.push(row.label);
    const cellsInRange = row.cells;
    const baseCells = cellsInRange;
    for (const cell of baseCells) {
      dataRow.push(formatValue(cell.value));
    }

    if (row.contents) {
      rowsToProcess.unshift(...row.contents);
    }
    data.push(dataRow);
  }
  const expandedColumns = [""];

  for (const col of colsInRange) {
    expandedColumns.push(col);
    exportColumns.push(currencyColumn);
  }

  data.unshift(expandedColumns);

  await excelExport(data, {
    filename: visualization.value.config.alias,
    worksheetTitle: visualization.value.title,
    exportType,
    author: userName,
    columns: exportColumns,
  });
}

const currencyColumn: Partial<ExcelJS.Column> = {
  width: 14,
  style: {
    numFmt: '"$"#,##0.00;[Red]-"$"#,##0.00',
  },
};

onMounted(() => {
  const jsonValue = getCookie(visualization.value.config.alias);
  if (jsonValue) {
    const value = JSON.parse(jsonValue);
    expandedMap.value = new Set(value);
  } else {
    expandedMap.value = new Set(visualization.value.config.expanded ?? []);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[2] || (_cache[2] = _createElementVNode("th", { class: "sticky top-0 z-10 bg-gray-200 p-0" }, [
            _createElementVNode("div", { class: "flex h-[25px] border-b border-r border-white px-10" })
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (colName, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: index,
              class: "sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(colName), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        _createVNode(FinancialSubreport, {
          rows: table.value,
          columns: columns.value,
          depth: 0,
          "expanded-map": expandedMap.value,
          onUpdateExpanded: updateExpanded
        }, null, 8, ["rows", "columns", "expanded-map"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(TextButton, {
        label: "CSV Export",
        secondary: true,
        onClick: _cache[0] || (_cache[0] = () => exportData(_unref(ExportType).CSV))
      }),
      _createVNode(TextButton, {
        label: "Excel Export",
        secondary: true,
        onClick: _cache[1] || (_cache[1] = () => exportData(_unref(ExportType).Excel))
      })
    ])
  ]))
}
}

})