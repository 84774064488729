import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "uppercase text-gray-500" }
const _hoisted_3 = { class: "values" }

import useKnowledge from "@/common/composables/useKnowledge";
import { computed, toRefs } from "vue";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { conceptPropertyValueDisplay } from "@/reader/lib/concept";


export default /*@__PURE__*/_defineComponent({
  __name: 'ValueGroup',
  props: {
    values: {},
    conceptType: {},
    propertyType: {}
  },
  setup(__props: any) {

const props = __props;
const { values, propertyType, conceptType } = toRefs(props);

const { typeLabel } = useKnowledge();

const propTypeLabel = computed(() => typeLabel(propertyType.value));

const valueDetails = computed(function () {
  return values.value.map(function (value) {
    const display = conceptPropertyValueDisplay(
      conceptType.value,
      propertyType.value,
      value.originalValue
    );
    return {
      value: value.formattedValue,
      color: display?.color ?? null,
    };
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(propTypeLabel.value), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(valueDetails.value, ({ value, color }, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex items-baseline"
        }, [
          color
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ backgroundColor: color }),
                class: "mr-4 h-10 w-10 rounded-full"
              }, null, 4))
            : _createCommentVNode("", true),
          _createVNode(GraphValueComponent, { value: value }, null, 8, ["value"])
        ]))
      }), 128))
    ])
  ]))
}
}

})