import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-row" }

import { isNil } from "lodash";
import { useNavigation } from "../composables/useNavigation";
import { environment } from "../environments/environmentLoader";
import { httpClient } from "../http/http";
import { useWorkspaceStore } from "../stores/workspaceStore";
import TextButton from "./TextButton.vue";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import IconButtonV2 from "./IconButtonV2.vue";

interface QuickBooksConnection {
  company_name: string;
  realm_id: string;
  access_token: string | undefined;
  refresh_token: string | undefined;
  created_by: string;
  modified_by: string;
  created: string;
  modified: string;
}

interface QuickBooksPreAuthResponse {
  code: string;
}

interface GetQuickBooksConnectionsResponse {
  connections: QuickBooksConnection[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickBooksTab',
  setup(__props) {

const { currentWorkspaceId } = storeToRefs(useWorkspaceStore());

const connections = ref<QuickBooksConnection[]>([]);

async function connectToQuickBooks() {
  const connectUrl = environment.require("QUICKBOOKS_CONNECT_URL");
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  // Preauthorize first
  const body = {
    workspace_id: workspaceId,
    redirect_url: window.location.href,
  };
  const response = await httpClient.post<QuickBooksPreAuthResponse>(
    `/api/quickbooks/${workspaceId}/authorize`,
    body
  );
  const code = response.data.code;
  return useNavigation().goToUrl(`${connectUrl}?request_code=${code}`);
}

async function getQuickBooksConnections(workspaceId: string) {
  const response = await httpClient.get<GetQuickBooksConnectionsResponse>(
    `/api/quickbooks/${workspaceId}/connections`
  );
  return response.data.connections;
}

async function refreshConnection(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/refresh`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

async function disconnect(connection: QuickBooksConnection) {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  await httpClient.post(`/api/quickbooks/${workspaceId}/realms/${connection.realm_id}/disconnect`);
  connections.value = await getQuickBooksConnections(workspaceId);
}

watch(currentWorkspaceId, async (workspaceId) => {
  if (isNil(workspaceId)) {
    connections.value = [];
  } else {
    connections.value = await getQuickBooksConnections(workspaceId);
  }
});

onMounted(async () => {
  const workspaceId = currentWorkspaceId.value;
  if (isNil(workspaceId)) {
    return;
  }
  connections.value = await getQuickBooksConnections(workspaceId);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TextButton, {
      label: "Connect to QuickBooks",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (connectToQuickBooks()))
    }),
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "Connections", -1)),
    _createElementVNode("table", null, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, "Company"),
          _createElementVNode("td", null, "Realm ID"),
          _createElementVNode("td", null, "Status"),
          _createElementVNode("td", null, "Actions")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(connections.value, (connection) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: connection.realm_id
          }, [
            _createElementVNode("td", null, _toDisplayString(connection.company_name), 1),
            _createElementVNode("td", null, _toDisplayString(connection.realm_id), 1),
            _createElementVNode("td", null, _toDisplayString(connection.access_token === undefined ? "⚠" : "✅"), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(IconButtonV2, {
                  name: "md:refresh",
                  label: "Refresh connection",
                  onClick: ($event: any) => (refreshConnection(connection))
                }, null, 8, ["onClick"]),
                _createVNode(IconButtonV2, {
                  name: "md:close",
                  label: "Disconnect",
                  onClick: ($event: any) => (disconnect(connection))
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}
}

})