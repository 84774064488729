import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 m-15 mt-10 flex flex-col" }
const _hoisted_2 = { class: "mb-10 flex flex-row items-center" }
const _hoisted_3 = { class: "basis-full overflow-y-auto" }

import Select from "@/common/components/Select.vue";
import { PropertyValueGenerator } from "@/reader/lib/visualization";
import { Visualization, VisualizationType } from "@/reader/lib/visualizationTypes";
import { VISUALIZATION_CONFIG_DEFS } from "@/reader/lib/visualizationTypes";
import { fromPairs } from "lodash";
import { computed } from "vue";
import { Query } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { Widget } from "@/reader/lib/page";
import { v4 as uuidv4 } from "uuid";
import { useExploreStore } from "@/reader/stores/explore";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualizationConfig',
  props: /*@__PURE__*/_mergeModels({
    query: {}
  }, {
    "type": { required: true },
    "typeModifiers": {},
    "config": { required: true },
    "configModifiers": {},
  }),
  emits: ["update:type", "update:config"],
  setup(__props: any) {

const exploreStore = useExploreStore();

const type = _useModel<VisualizationType | undefined>(__props, "type");
const config = _useModel<Record<string, unknown> | undefined>(__props, "config");


const types = computed(() => Object.keys(VISUALIZATION_CONFIG_DEFS));
const controls = computed(() => configDef()?.controls ?? []);

function configDef() {
  if (type.value == null) return undefined;
  return VISUALIZATION_CONFIG_DEFS[type.value];
}

function typeName(vizType: string) {
  return VISUALIZATION_CONFIG_DEFS[vizType].label;
}

function prepareNewType(newType?: VisualizationType) {
  if (newType == null) {
    config.value = {};
  } else {
    const pairs = VISUALIZATION_CONFIG_DEFS[newType].controls.map((c) => [c.key, undefined]);
    config.value = fromPairs(pairs);
  }
}

function copyWidgetJSON() {
  const widget: Widget = {
    key: uuidv4(),
    top: 0,
    left: 0,
    width: 4,
    height: 4,
    visualization: {
      type: type.value!,
      title: "Explored Visualization",
      config: config.value!,
      query: exploreStore.query!,
    } as Visualization,
  };
  navigator.clipboard.writeText(JSON.stringify(widget, undefined, 2));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "basis-full text-xl text-gray-500" }, "Visualization Settings", -1)),
      _createVNode(IconButtonV2, {
        name: "md:content_copy",
        size: "l",
        label: "Copy Widget JSON",
        onClick: copyWidgetJSON,
        disabled: type.value == null
      }, null, 8, ["disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Select, {
        options: types.value,
        "option-label": typeName,
        required: "",
        label: "Visualization Type",
        placeholder: "Choose a type to get started",
        modelValue: type.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((type).value = $event)),
          prepareNewType
        ]
      }, null, 8, ["options", "modelValue"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(controls.value, (control) => {
        return (_openBlock(), _createElementBlock("div", {
          key: control.key,
          class: "my-10"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(`viz-config-control-${control.type}`), {
            control: control,
            query: _ctx.query,
            "model-value": config.value![control.key] as PropertyValueGenerator,
            "onUpdate:modelValue": 
            (value) =>
              value === undefined ? delete config.value![control.key] : (config.value![control.key] = value)
          
          }, null, 8, ["control", "query", "model-value", "onUpdate:modelValue"]))
        ]))
      }), 128))
    ])
  ]))
}
}

})