import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import { DerivedPropertyTerm, PropertyOpType } from "@/common/lib/derived";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import IconButton from "@/common/components/IconButton.vue";
import { Ref, ref, watchEffect } from "vue";
import { v4 as uuidv4 } from "uuid";

interface MultiTerm {
  id: string;
  term: DerivedPropertyTerm | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MultiTerm',
  props: /*@__PURE__*/_mergeModels({
    parentOp: {}
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel(__props, "modelValue");
const terms: Ref<MultiTerm[]> = ref([newTerm(), newTerm()]);

function newTerm() {
  return {
    id: uuidv4(),
    term: null,
  };
}

function setTerm(id: string, value: DerivedPropertyTerm | null) {
  terms.value.find((t) => t.id === id)!.term = value;
}

function deleteTerm(id: string) {
  terms.value.splice(
    terms.value.findIndex((t) => t.id === id),
    1
  );
}

function addTerm() {
  terms.value.push(newTerm());
}

watchEffect(() => (model.value = terms.value.map((t) => t.term)));

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(terms.value, (term, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "mb-5 flex items-start",
      key: term.id
    }, [
      _createVNode(DerivedPropertyDef, {
        class: "basis-full",
        "parent-op": _ctx.parentOp,
        "model-value": term.term,
        "onUpdate:modelValue": (value) => setTerm(term.id, value)
      }, null, 8, ["parent-op", "model-value", "onUpdate:modelValue"]),
      (terms.value.length > 1)
        ? (_openBlock(), _createBlock(IconButton, {
            key: 0,
            class: "ml-10 h-[40px]",
            name: "delete",
            size: "l",
            onClick: ($event: any) => (deleteTerm(term.id))
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(IconButton, {
        class: _normalizeClass(["ml-10 h-[40px]", { invisible: index != terms.value.length - 1 }]),
        name: "add-line",
        size: "l",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (addTerm()))
      }, null, 8, ["class"])
    ]))
  }), 128))
}
}

})