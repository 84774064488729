import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["colspan", "onClick"]
const _hoisted_2 = { class: "flex flex-row items-center font-bold" }
const _hoisted_3 = { class: "px-5 py-10" }
const _hoisted_4 = {
  key: 0,
  class: "pl-10"
}

import { Subtable as SubtableType, Column } from "../Table.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";
import MiniBar from "../../MiniBar.vue";
import { toNative, isNumeric, FloatValue } from "@/common/lib/value";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { isNil } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Subtable',
  props: {
    subtable: {},
    columns: {},
    depth: {}
  },
  setup(__props: any) {

const props = __props;
const { depth } = toRefs(props);

const collapsed: Ref<Set<string>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${20 * depth.value}px` }));

const maxValueForColumn = (colIndex: number) => {
  const validValues: number[] = (props.subtable.rows ?? [])
    .map((row) => row[colIndex]?.originalValue)
    .filter((originalValue) => !isNil(originalValue) && isNumeric(originalValue))
    .map((originalValue) => toNative(originalValue));
  return Math.max(...validValues, -Infinity);
};
function showMinibar(col: Column, row: ValueWithFormattedValue) {
  return isNumeric(row.originalValue) && !col.hideBars;
}

return (_ctx: any,_cache: any) => {
  const _component_Subtable = _resolveComponent("Subtable", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subtable.groups ?? [], (group) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: group.id
      }, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", {
            class: "cursor-pointer hover:bg-gray-100",
            colspan: _ctx.columns.length,
            onClick: ($event: any) => (_unref(toggle)(collapsed.value, group.id)),
            style: _normalizeStyle(indentStyle.value)
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(Icon, {
                name: collapsed.value.has(group.id) ? 'md:keyboard_arrow_up' : 'md:keyboard_arrow_down',
                color: "medium-gray",
                size: "xl"
              }, null, 8, ["name"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(GraphValueComponent, {
                  value: group.name
                }, null, 8, ["value"])
              ])
            ])
          ], 12, _hoisted_1)
        ]),
        (!collapsed.value.has(group.id))
          ? (_openBlock(), _createBlock(_component_Subtable, {
              key: 0,
              subtable: group.contents,
              columns: _ctx.columns,
              depth: _unref(depth) + 1
            }, null, 8, ["subtable", "columns", "depth"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subtable.rows ?? [], (row, rowIndex) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: rowIndex,
        class: "group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, colIndex) => {
          return (_openBlock(), _createElementBlock("td", {
            class: "p-10 align-baseline",
            key: colIndex
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(colIndex == 0 ? indentStyle.value : undefined)
            }, [
              (row[colIndex] != null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({ 'text-right': col.isQuantitative })
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass({ 'flex flex-row justify-end': showMinibar(col, row[colIndex]) })
                    }, [
                      _createVNode(GraphValueComponent, {
                        value: row[colIndex].formattedValue
                      }, null, 8, ["value"]),
                      (showMinibar(col, row[colIndex]))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(MiniBar, {
                              value: row[colIndex].originalValue as FloatValue,
                              max: maxValueForColumn(colIndex)
                            }, null, 8, ["value", "max"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["opacity-50", { 'text-right': col.isQuantitative }])
                  }, "-", 2))
            ], 4)
          ]))
        }), 128))
      ]))
    }), 128))
  ], 64))
}
}

})