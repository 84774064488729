import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col dark:bg-gray-800" }
const _hoisted_2 = { class: "relative basis-full overflow-y-auto p-10" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "p-20 text-center text-xl text-gray-500"
}
const _hoisted_5 = { key: 1 }

import TabSelect from "@/common/components/TabSelect.vue";
import { computed } from "vue";
import { Tool, useExploreStore } from "@/reader/stores/explore";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import ConceptPage from "../concept/ConceptPage.vue";
import { QueryFilter } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExploreTools',
  setup(__props) {

const exploreStore = useExploreStore();

const tabs = computed(() => [
  { label: "Insights", key: Tool.Insights },
  { label: "Concept", key: Tool.Concept },
]);

function pivot(ctype: ConceptKnowledgeRef, filters: QueryFilter[]) {
  exploreStore.pivot(ctype, filters[0]); // This will fail on multi-key merges
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TabSelect, {
      tabs: tabs.value,
      modelValue: _unref(exploreStore).activeTool,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(exploreStore).activeTool) = $event)),
      class: "border-b border-b-gray-200 light:bg-gray-100 dark:border-b-gray-900"
    }, null, 8, ["tabs", "modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (_unref(exploreStore).activeTool === _unref(Tool).Concept)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_unref(exploreStore).conceptPage)
              ? (_openBlock(), _createBlock(ConceptPage, {
                  key: 0,
                  address: _unref(exploreStore).conceptPage,
                  onNavigate: _unref(exploreStore).showConceptPage,
                  onPivot: pivot
                }, null, 8, ["address", "onNavigate"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, " Click on an underlined value and choose the \"View\" menu item to see Concept details here. "))
          ]))
        : _createCommentVNode("", true),
      (_unref(exploreStore).activeTool === _unref(Tool).Insights)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "p-20 text-center text-xl text-gray-500" }, " No visualizations are available for the current perspective. ", -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})