import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bar-track relative h-[10px] w-[100px] overflow-hidden rounded-sm border border-gray-200 bg-white dark:border-0 dark:bg-gray-700" }

import { FloatValue, IntegerValue, toNative } from "@/common/lib/value";
import { computed, inject, Ref, toRefs } from "vue";
import { visualizationTheme } from "../lib/visualization";
import { DarkMode } from "@/common/lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'MiniBar',
  props: {
    value: {},
    max: {}
  },
  setup(__props: any) {

const props = __props;
const { value, max } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const width = computed(function () {
  const thisValue = toNative(value.value);
  const percentage = (thisValue / max.value) * 100;
  return `${percentage}%`;
});

const color = computed(() => visualizationTheme(darkMode.value).datum);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bar absolute bottom-0 left-0 top-0",
      style: _normalizeStyle({ width: width.value, backgroundColor: color.value })
    }, null, 4)
  ]))
}
}

})