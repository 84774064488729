import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { rootColumns } from "@/reader/lib/explore";
import { computed, inject, Ref, ComputedRef } from "vue";
import vSelect from "vue-select";
import { DarkMode } from "@/common/lib/keys";
import { columnName, QueryColumn } from "@/common/lib/query";
import { v4 as uuid } from "uuid";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupBy',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const darkMode = inject(DarkMode) as Ref<boolean>;
const model = _useModel<QueryColumn | null>(__props, "modelValue");

const options: ComputedRef<QueryColumn[]> = computed(() =>
  rootColumns().map((column) => ({
    alias: uuid(),
    property_type: column.property_type,
  }))
);

function groupByLabel(col: QueryColumn) {
  return columnName(col);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(vSelect), {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    class: _normalizeClass(["select", { 'light-select': !_unref(darkMode) }]),
    options: options.value,
    getOptionLabel: groupByLabel,
    clearable: false,
    placeholder: "Choose a Property"
  }, null, 8, ["modelValue", "class", "options"]))
}
}

})