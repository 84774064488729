import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex items-center justify-center" }
const _hoisted_2 = { key: 1 }

import { generateValue } from "@/reader/lib/visualization";
import { IndicatorVisualization } from "@/reader/lib/visualizationTypes";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { stringifyValue } from "@/common/lib/value";
import { combinedQuerySignature } from "@/common/lib/combiningQuery";


export default /*@__PURE__*/_defineComponent({
  __name: 'Indicator',
  props: {
    visualization: {},
    results: {},
    height: {},
    width: {}
  },
  setup(__props: any) {

const props = __props;
const { visualization, results, height, width } = toRefs(props);

const datum = computed(function () {
  if (results.value.length !== 1) return null;
  const viz = visualization.value;
  const query = combinedQuerySignature(viz.query);
  return generateValue(viz.config.value, results.value[0], query);
});
const textHeight = computed(() => {
  let size = height.value / 5 + 25;
  const stringLength = stringifyValue(datum.value?.formattedValue).length;

  if (stringLength * (size / 1.5) > width.value) {
    size = (1.5 * width.value) / stringLength;
  }
  return size;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (datum.value != null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ fontSize: `${textHeight.value}px` })
        }, [
          _createVNode(GraphValueComponent, {
            value: datum.value.formattedValue
          }, null, 8, ["value"])
        ], 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "—"))
  ]))
}
}

})