import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start gap-15" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "w-full table-fixed border-collapse" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "p-5" }
const _hoisted_6 = { class: "p-5" }
const _hoisted_7 = { class: "p-5" }
const _hoisted_8 = { class: "p-5" }
const _hoisted_9 = { class: "p-5" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex flex-col gap-5" }

import { storeToRefs } from "pinia";
import { useAdminStore } from "../../stores/adminStore";
import { AsyncStatus, asyncValue } from "../../lib/async";
import { computed, onMounted, ref } from "vue";
import AdminSetting from "./AdminSetting.vue";
import { isNil, toString } from "lodash";
import Dialog from "../Dialog.vue";
import MessageBar from "../MessageBar.vue";
import AdminWarehouseChooser from "./AdminWarehouseChooser.vue";
import Spinner from "../Spinner.vue";
import TextButton from "../TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminPage',
  setup(__props) {

const adminStore = useAdminStore();
const { users } = storeToRefs(adminStore);
const loading = ref(false);

const currentUserId = ref<string | undefined>();
const currentUser = computed(() => {
  const currentUsers = asyncValue(users.value);
  if (isNil(currentUsers)) {
    return undefined;
  }
  return currentUsers.find((u) => u.user_id === currentUserId.value);
});

const errorMessage = ref("");

async function setHomepage(userId: string, homepage: string) {
  await doUpdate(() => adminStore.setHomepage(userId, { default_homepage: homepage }));
}

async function changePassword(userId: string, password: string) {
  await doUpdate(async () => {
    const result = await adminStore.changePassword(userId, password);
    if (result.error) {
      errorMessage.value = result.error;
    }
  });
}

async function updateName(userId: string, name: string) {
  await doUpdate(() => adminStore.updateName(userId, name));
}

async function resync(userId: string) {
  await doUpdate(() => adminStore.resyncUser(userId));
}

async function doUpdate(action: () => Promise<unknown>) {
  loading.value = true;
  errorMessage.value = "";
  try {
    await action();
    await adminStore.loadUsers();
  } finally {
    loading.value = false;
  }
}

onMounted(() => adminStore.loadUsers());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-xl" }, "Users", -1)),
      (_unref(users).status === _unref(AsyncStatus).Succeeded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("table", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "text-left" }, [
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Full name"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Email"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Status"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Organization"),
                  _createElementVNode("th", {
                    scope: "col",
                    class: "p-5"
                  }, "Default workspace")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(users).result, (user) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: user.user_id,
                    onClick: ($event: any) => (currentUserId.value = user.user_id),
                    class: _normalizeClass(["hover:bg-orange", { 'bg-orange': user.user_id === currentUserId.value }])
                  }, [
                    _createElementVNode("td", _hoisted_5, _toDisplayString(user.name), 1),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(user.email), 1),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(user.status), 1),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(user.organization ?? "-"), 1),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(user.settings?.["workspace_id"] ?? "-"), 1)
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(users).status === _unref(AsyncStatus).InProgress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(Spinner)
          ]))
        : _createCommentVNode("", true)
    ]),
    (currentUser.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          icon: "md:settings",
          title: `Settings for ${currentUser.value.name}`,
          "success-label": null,
          "cancel-label": "Done",
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => (currentUserId.value = undefined))
        }, {
          default: _withCtx(() => [
            (errorMessage.value)
              ? (_openBlock(), _createBlock(MessageBar, {
                  key: 0,
                  mode: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(errorMessage.value), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(AdminSetting, {
                label: "ID",
                value: currentUser.value.user_id,
                readonly: true,
                copyable: true
              }, null, 8, ["value"]),
              _createVNode(AdminSetting, {
                label: "Admin",
                value: _unref(toString)(currentUser.value.status === 'admin'),
                readonly: true
              }, null, 8, ["value"]),
              _createVNode(AdminSetting, {
                label: "Name",
                value: currentUser.value.name,
                onUpdate: _cache[0] || (_cache[0] = (name) => updateName(currentUser.value!.user_id, name)),
                loading: loading.value
              }, null, 8, ["value", "loading"]),
              _createVNode(AdminSetting, {
                label: "Email",
                value: currentUser.value.email,
                readonly: true
              }, null, 8, ["value"]),
              _createVNode(AdminSetting, {
                label: "Password",
                value: "••••••••",
                onUpdate: _cache[1] || (_cache[1] = (pw) => changePassword(currentUser.value!.user_id, pw)),
                loading: loading.value
              }, null, 8, ["loading"]),
              _createVNode(AdminSetting, {
                label: "Homepage",
                value: currentUser.value.settings?.homepage,
                onUpdate: _cache[2] || (_cache[2] = (url) => setHomepage(currentUser.value!.user_id, url)),
                loading: loading.value
              }, null, 8, ["value", "loading"]),
              _createVNode(AdminSetting, {
                label: "Default workspace",
                value: currentUser.value.settings?.workspace_name,
                readonly: true,
                class: "border-t border-t-gray-900 pt-10"
              }, null, 8, ["value"]),
              (currentUser.value.settings?.workspace_id)
                ? (_openBlock(), _createBlock(AdminWarehouseChooser, {
                    key: 0,
                    "user-id": currentUser.value.user_id,
                    "workspace-id": currentUser.value.settings.workspace_id,
                    "current-warehouse-id": currentUser.value.settings.warehouse_id,
                    onWarehouseUpdated: _unref(adminStore).loadUsers
                  }, null, 8, ["user-id", "workspace-id", "current-warehouse-id", "onWarehouseUpdated"]))
                : _createCommentVNode("", true),
              _createVNode(TextButton, {
                label: "Resync",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (resync(currentUser.value!.user_id)))
              })
            ])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})