import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "dialog",
  style: {"z-index":"3000"}
}
const _hoisted_4 = { class: "failure" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "flex gap-10 break-all text-xxl font-bold" }
const _hoisted_7 = { class: "mx-0 my-10 max-h-[300px] overflow-y-scroll text-xxl" }
const _hoisted_8 = {
  key: 0,
  class: "mx-0 my-10 text-xxl"
}
const _hoisted_9 = { class: "buttons" }
const _hoisted_10 = { class: "buttons" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "buttons" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "buttons" }

import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { computed, Ref, ref } from "vue";
import Textbox from "@/common/components/Textbox.vue";
import { useSentry } from "@/common/monitoring/sentry/sentryStore";
import { environment } from "@/common/environments/environmentLoader";
import { storeToRefs } from "pinia";
import { useFailureStore } from "../stores/failureStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'FailureDialog',
  setup(__props) {

const copyableText: Ref<HTMLTextAreaElement | undefined> = ref();
const showErrorDetails = ref(false);
const showBugReporter = ref(false);

const failureStore = useFailureStore();
const { failure, additionalContext, canUndo, undo } = storeToRefs(failureStore);

const userBugReport = ref("");

const details = computed(() => {
  if (failure?.value === undefined) {
    return undefined;
  }
  const components = {
    "Error Type": failure.value.type,
    Description: failure.value.description,
    Endpoint: failure.value.endpoint,
    "Exception Name": failure.value.exceptionName,
    "Exception Message": failure.value.message,
    "Exception Cause": failure.value.exceptionCause,
    "Exception Stack": failure.value.exceptionStack,
    Request: JSON.stringify(failure.value.request, undefined, 2),
    Response: JSON.stringify(failure.value.response, undefined, 2),
  };
  if (additionalContext?.value) {
    Object.assign(components, additionalContext.value());
  }
  return Object.entries(components)
    .filter((entry) => entry[1] !== undefined)
    .map(([key, value]) => `## ${key}\n\n${value}\n\n`)
    .join("");
});

function copyReport() {
  copyableText.value?.select();
  document.execCommand("copy");
}

function submitBugReport(eventId: string) {
  if (failure?.value == undefined) {
    return;
  }
  useSentry().sentry?.captureUserFeedback(failure.value.source, eventId, userBugReport.value);
  close();
}

function handleUndo() {
  undo.value();
  close();
}

function close() {
  failureStore.clearFailure();
}

return (_ctx: any,_cache: any) => {
  return (_unref(failure))
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#modal-container"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "shade" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(Icon, {
                      name: "error",
                      size: "xl"
                    }),
                    _createTextVNode(_toDisplayString(_unref(failure).title), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(failure).message), 1),
                  (_unref(environment).requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, " We track these errors automatically, but please feel free to add more information about what caused the error. "))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    (_unref(environment).requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                      ? (_openBlock(), _createBlock(TextButton, {
                          key: 0,
                          label: "Add Details",
                          icon: "settings_applications",
                          selected: showBugReporter.value,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (showBugReporter.value = !showBugReporter.value))
                        }, null, 8, ["selected"]))
                      : _createCommentVNode("", true),
                    (_unref(environment).requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS'))
                      ? (_openBlock(), _createBlock(TextButton, {
                          key: 1,
                          label: "View Details",
                          icon: "settings_applications",
                          selected: showErrorDetails.value,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (showErrorDetails.value = !showErrorDetails.value))
                        }, null, 8, ["selected"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_10, [
                      (!_unref(failure).hideUndo && _unref(canUndo)())
                        ? (_openBlock(), _createBlock(TextButton, {
                            key: 0,
                            label: "Undo",
                            onClick: handleUndo
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(TextButton, {
                        label: "Ok",
                        onClick: close
                      })
                    ])
                  ])
                ]),
                (_unref(environment).requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS'))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (showErrorDetails.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _withDirectives(_createElementVNode("textarea", {
                              class: "details",
                              ref_key: "copyableText",
                              ref: copyableText,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((details).value = $event))
                            }, null, 512), [
                              [_vModelText, details.value]
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(TextButton, {
                                label: "Copy Bug Report",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (copyReport()))
                              })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_unref(environment).requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (showBugReporter.value && _unref(failure).eventId)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(Textbox, {
                              textarea: true,
                              "min-height": "100px",
                              label: "Details",
                              modelValue: userBugReport.value,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((userBugReport).value = $event))
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(TextButton, {
                                label: "Submit Bug Report",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (submitBugReport(_unref(failure)!.eventId!))),
                                disabled: userBugReport.value === ''
                              }, null, 8, ["disabled"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})