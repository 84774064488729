import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col" }
const _hoisted_2 = { class: "mx-10 my-5 flex min-h-30 items-center" }
const _hoisted_3 = { class: "mx-5 shrink grow-0 basis-full text-xxl font-bold" }
const _hoisted_4 = { class: "basis-full overflow-y-auto p-10 pt-0" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-center p-15"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "properties" }

import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { AsyncStatus, asyncHasValue } from "@/common/lib/async";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useQuery from "@/reader/composables/useQuery";
import {
  ConceptAddress,
  buildConceptQuery,
  buildNeighborhoodQueries,
  readerConceptTitle,
} from "@/reader/lib/concept";
import { groupBy, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import NeighborhoodSummary from "./NeighborhoodSummary.vue";
import ValueGroup from "./ValueGroup.vue";
import { useAppStore } from "@/reader/stores/app";
import { formatValue } from "@/common/lib/format";
import { Query, QueryFilter } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConceptPage',
  props: {
    address: {},
    showCloseButton: { type: Boolean, default: false }
  },
  emits: ["navigate", "pivot", "close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { address } = toRefs(props);

const emit = __emit;

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcept } = useQuery(
  appStore.module!,
  () => buildConceptQuery(address.value),
  UserAction.Load_Concept_Page
);

const neighborhoods = computed(() => buildNeighborhoodQueries(address.value));

const title = computed(function () {
  const conceptLabel = typeLabel(address.value.conceptType);
  if (asyncHasValue(queryResults.value)) {
    return readerConceptTitle(rootConcept()) ?? conceptLabel;
  } else {
    return `Loading ${conceptLabel}...`;
  }
});

const propertyValuesByType = computed(function () {
  const grouped = groupBy(rootConcept().properties ?? [], "type");
  return mapValues(grouped, (props, type) =>
    props.map((prop) => formatValue(type as PropertyKnowledgeRef, prop.value!))
  );
});

const iconName = computed(() => getConceptIconName(address.value.conceptType));

function pivotTo(query: Query) {
  emit("pivot", query.root_concept_type, query.filters);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Icon, {
        name: iconName.value,
        color: { dark: 'white', light: 'black' }
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(title.value), 1),
      (_ctx.showCloseButton)
        ? (_openBlock(), _createBlock(IconButtonV2, {
            key: 0,
            name: "close",
            size: "l",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_unref(queryResults).status === _unref(AsyncStatus).InProgress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(Spinner)
          ]))
        : _createCommentVNode("", true),
      (_unref(queryResults).status === _unref(AsyncStatus).Failed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(queryResults).message), 1))
        : _createCommentVNode("", true),
      (_unref(queryResults).status === _unref(AsyncStatus).Succeeded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propertyValuesByType.value, (properties, type) => {
                return (_openBlock(), _createBlock(ValueGroup, {
                  values: properties,
                  "concept-type": _unref(address).conceptType,
                  "property-type": type as PropertyKnowledgeRef,
                  key: type
                }, null, 8, ["values", "concept-type", "property-type"]))
              }), 128))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(neighborhoods.value, (query, key) => {
              return (_openBlock(), _createBlock(NeighborhoodSummary, {
                key: key,
                query: query,
                onNavigate: _cache[1] || (_cache[1] = (address) => emit('navigate', address)),
                onPivot: () => pivotTo(query)
              }, null, 8, ["query", "onPivot"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})