import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border-t border-t-gray-900 pt-10" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between gap-10" }
const _hoisted_3 = { class: "text-sm text-gray-300" }
const _hoisted_4 = {
  key: 1,
  class: "flex w-full flex-col gap-5"
}
const _hoisted_5 = {
  key: 1,
  class: "flex w-full flex-col"
}
const _hoisted_6 = { class: "flex flex-col items-center" }

import { Warehouse } from "@/common/stores/workspaceStore";
import { Async, asyncNotStarted, AsyncStatus } from "@/common/lib/async";
import { useAdminStore } from "@/common/stores/adminStore";
import { onMounted, ref, toRefs } from "vue";
import { isNil } from "lodash";
import Select from "../Select.vue";
import Label from "../Label.vue";
import Spinner from "../Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminWarehouseChooser',
  props: {
    userId: {},
    workspaceId: {},
    currentWarehouseId: {}
  },
  emits: ["warehouseUpdated"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { userId, workspaceId } = toRefs(props);
const emits = __emit;
const currentWarehouseId = ref<string | undefined>();

const adminStore = useAdminStore();

const warehouses = ref<Async<Warehouse[]>>(asyncNotStarted());

async function updateWarehouse() {
  if (isNil(currentWarehouseId.value)) {
    return;
  }
  await adminStore.setCurrentWarehouse({
    userId: userId.value,
    workspaceId: workspaceId.value,
    warehouseId: currentWarehouseId.value,
  });
  emits("warehouseUpdated");
}

onMounted(async () => {
  currentWarehouseId.value = props.currentWarehouseId;
  warehouses.value = await adminStore.listWarehouses(workspaceId.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (warehouses.value.status === _unref(AsyncStatus).Succeeded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (warehouses.value.result.length > 0)
            ? (_openBlock(), _createBlock(Select, {
                key: 0,
                label: "Warehouse",
                modelValue: currentWarehouseId.value,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((currentWarehouseId).value = $event)),
                  updateWarehouse
                ],
                options: warehouses.value.result,
                "option-label": (w) => w.name,
                "option-value": (w) => w.id,
                clearable: false
              }, {
                "option-data": _withCtx((warehouse) => [
                  _createElementVNode("span", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(warehouse.name), 1),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(warehouse.cluster_size), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "options", "option-label", "option-value"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(Label, { label: "Warehouse" }),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center text-gray-100 opacity-[0.6]" }, "No warehouses", -1))
              ]))
        ], 64))
      : _createCommentVNode("", true),
    (warehouses.value.status === _unref(AsyncStatus).InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(Label, { label: "Warehouse" }),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(Spinner)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})