import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start gap-5 border-t border-t-gray-900 pt-10" }
const _hoisted_2 = { class: "flex w-full flex-row items-center justify-between gap-10" }
const _hoisted_3 = { class: "font-bold text-gray-500" }
const _hoisted_4 = {
  key: 1,
  class: "italic"
}

import { isNil } from "lodash";
import { computed, ref } from "vue";
import TextButton from "../TextButton.vue";
import Dialog from "../Dialog.vue";
import Textbox from "../Textbox.vue";
import Spinner from "../Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminSetting',
  props: {
    label: {},
    value: {},
    readonly: { type: Boolean, default: false },
    copyable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const editMode = ref(false);
const valueToEdit = ref("");

const formattedValue = computed(() => props.value ?? "None");

function saveDialog() {
  emit("update", valueToEdit.value);
  closeDialog();
}

function openDialog() {
  editMode.value = true;
  valueToEdit.value = props.value ?? "";
}

function closeDialog() {
  editMode.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1),
        (!_ctx.readonly)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(TextButton, {
                    key: 0,
                    label: "Update",
                    onClick: openDialog
                  }))
                : (_openBlock(), _createBlock(Spinner, { key: 1 }))
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.copyable)
          ? (_openBlock(), _createBlock(TextButton, {
              key: 1,
              label: "Copy"
            }))
          : _createCommentVNode("", true)
      ]),
      (!_unref(isNil)(_ctx.value))
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass({ 'font-extralight': _ctx.readonly })
          }, _toDisplayString(formattedValue.value), 3))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "Not set"))
    ]),
    (editMode.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: `Edit ${_ctx.label}`,
          onSucceeded: saveDialog,
          onCancelled: closeDialog
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              modelValue: valueToEdit.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valueToEdit).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})