import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center gap-10"
}

import { useTimer } from "@/common/composables/useTimer";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import Spinner from "./Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseLoading',
  props: {
    spinner: { type: Boolean }
  },
  setup(__props: any) {


const workspaceStore = useWorkspaceStore();
const { currentWarehouse } = storeToRefs(workspaceStore);

const timer = useTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => currentWarehouse?.value,
  (warehouse) => {
    if (warehouse?.state !== "RUNNING") {
      timer.start(async () => await workspaceStore.refreshCurrentWarehouse());
    } else {
      timer.stop();
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_unref(currentWarehouse) && _unref(currentWarehouse).state !== 'RUNNING')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.spinner)
          ? (_openBlock(), _createBlock(Spinner, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, "Warehouse \"" + _toDisplayString(_unref(currentWarehouse).name) + "\" is " + _toDisplayString(_unref(currentWarehouse).state), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})