<template>
  <div class="neighborhood">
    <div class="loading" v-if="queryResults.status === AsyncStatus.InProgress">
      <Spinner />
    </div>
    <div v-if="queryResults.status === AsyncStatus.Failed">{{ queryResults.message }}</div>
    <div v-if="queryResults.status === AsyncStatus.Succeeded && conceptCount > 0">
      <div class="header">
        <Icon :name="conceptIconName" :color="{ dark: 'white', light: 'black' }" />
        <div class="label">{{ conceptTypeLabel }}</div>
        <IconButton name="table" size="l" tooltip="View all in table" @click="emit('pivot')" />
      </div>
      <div class="neighbors">
        <div
          v-for="neighbor of neighbors"
          class="neighbor"
          :class="{ '-with-link': neighbor.address != null }"
          :key="neighbor.key"
          @click="clickLink(neighbor.address)"
        >
          {{ neighbor.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.neighborhood {
  margin-top: $normal-margin;
}

.header {
  display: flex;
  align-items: center;

  .label {
    flex: 0 1 100%;
    text-transform: uppercase;
    color: $gray8;
    margin-left: $thin-margin;
  }
}

.neighbor {
  padding: 2px 0;

  &.-with-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<script setup lang="ts">
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { ConceptAddress, conceptAddress, readerConceptTitle } from "@/reader/lib/concept";
import pluralize from "pluralize";
import { computed, toRefs } from "vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { AsyncStatus } from "@/common/lib/async";
import Spinner from "@/common/components/Spinner.vue";
import { Query } from "@/common/lib/query";
import { UserAction } from "@/common/lib/userAction";

const props = defineProps<{ query: Query }>();
const { query } = toRefs(props);
const emit = defineEmits<{ (e: "navigate", address: ConceptAddress): void; (e: "pivot"): void }>();

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcepts } = useQuery(
  appStore.module!,
  () => query.value,
  UserAction.Load_Neighborhood_Summary
);

const conceptType = () => query.value.root_concept_type;

const conceptCount = computed(() => rootConcepts().length);
const conceptIconName = computed(() => getConceptIconName(conceptType()));
const conceptTypeLabel = computed(() => pluralize(typeLabel(conceptType()), conceptCount.value));

const neighbors = computed(function () {
  return rootConcepts().map((concept) => ({
    key: concept.id,
    title: readerConceptTitle(concept),
    address: conceptAddress(concept),
  }));
});

function clickLink(address?: ConceptAddress) {
  if (address != null) emit("navigate", address);
}
</script>
