import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between gap-10" }
const _hoisted_2 = { class: "text-sm text-gray-300" }
const _hoisted_3 = {
  key: 1,
  class: "flex w-full flex-col gap-5"
}
const _hoisted_4 = {
  key: 1,
  class: "flex w-full flex-col"
}
const _hoisted_5 = { class: "flex flex-col items-center" }

import { useWorkspaceStore, Warehouse } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { AsyncStatus } from "../lib/async";
import Select from "./Select.vue";
import Spinner from "./Spinner.vue";
import Label from "./Label.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseChooser',
  setup(__props) {

const workspaceStore = useWorkspaceStore();
const { warehouses, currentWorkspaceSync: currentWorkspace } = storeToRefs(workspaceStore);

async function updateWarehouse() {
  if (!currentWorkspace?.value) {
    return;
  }
  if (!currentWorkspace.value.current_warehouse_id) {
    return;
  }
  await workspaceStore.setCurrentWarehouse(
    currentWorkspace.value.id,
    currentWorkspace.value.current_warehouse_id
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(currentWorkspace) && _unref(warehouses).status === _unref(AsyncStatus).Succeeded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_unref(warehouses).result.length > 0)
            ? (_openBlock(), _createBlock(Select, {
                key: 0,
                label: "Warehouse",
                modelValue: _unref(currentWorkspace).current_warehouse_id,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_unref(currentWorkspace).current_warehouse_id) = $event)),
                  updateWarehouse
                ],
                options: _unref(warehouses).result,
                "option-label": (w) => w.name,
                "option-value": (w) => w.id,
                clearable: false
              }, {
                "option-data": _withCtx((warehouse) => [
                  _createElementVNode("span", _hoisted_1, [
                    _createElementVNode("span", null, _toDisplayString(warehouse.name), 1),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(warehouse.cluster_size), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "options", "option-label", "option-value"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(Label, { label: "Warehouse" }),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center text-gray-100 opacity-[0.6]" }, "No warehouses", -1))
              ]))
        ], 64))
      : _createCommentVNode("", true),
    (_unref(warehouses).status === _unref(AsyncStatus).InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(Label, { label: "Warehouse" }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(Spinner)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})