import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "list-builder" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body" }

import { Ref, computed, ref, watchEffect } from "vue";
import IconButtonV2 from "./IconButtonV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListBuilder',
  props: /*@__PURE__*/_mergeModels({
    title: {}
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const list: Ref<Array<T | null>> = ref([]);


const model = _useModel<Array<T | null>>(__props, "modelValue");


function addNullItem() {
  list.value.push(null);
}

function removeItem(index: number) {
  list.value.splice(index, 1);
  updateModel();
}

function updateItem(index: number, newValue: T | null) {
  list.value[index] = newValue;
  updateModel();
}

function updateModel() {
  model.value = list.value.filter((v) => v != null);
}

const listHasNullItem = computed(() => list.value.includes(null));

watchEffect(() => (list.value = [...model.value]));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode(IconButtonV2, {
        name: "md:add_circle",
        size: "l",
        onClick: addNullItem,
        label: "Add",
        disabled: listHasNullItem.value,
        "data-test": "add-item"
      }, null, 8, ["disabled"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {
            item: item,
            updateItem: (newValue) => updateItem(index, newValue)
          })
        ]),
        _createVNode(IconButtonV2, {
          name: "delete",
          onClick: ($event: any) => (removeItem(index)),
          label: "Remove"
        }, null, 8, ["onClick"])
      ]))
    }), 128))
  ]))
}
}

})