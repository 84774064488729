import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0" }
const _hoisted_2 = { class: "flex flex-col items-start gap-10 self-stretch" }
const _hoisted_3 = { class: "flex items-center justify-end gap-10 self-stretch" }

import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { FullWorkspace, ProviderType, useWorkspaceStore } from "@/common/stores/workspaceStore";
import { computed, onMounted, ref } from "vue";
import SpinnerButton from "../SpinnerButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { BackendError } from "@/common/http/http";
import { isObject, isString } from "lodash";

interface SnowflakeWorkspace extends FullWorkspace {
  credentials: {
    user: string;
    password: string;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SnowflakeEditConnectionSettings',
  props: {
    currentWorkspace: {},
    mode: {}
  },
  emits: ["saveWorkspace", "cancel"],
  setup(__props: any, { emit: __emit }) {

const workspaceStore = useWorkspaceStore();

const workspace = ref<SnowflakeWorkspace>(newSnowflakeWorkspace());

const props = __props;

const emits = __emit;

const saving = ref(false);
const errorDialogMessage = ref<string | undefined>();

const saveEnabled = computed(() => {
  const metadata = workspace.value.metadata;
  const creds = workspace.value.credentials;
  return metadata.nickname && metadata.id && creds.user && creds.password;
});

const commitLabel = computed(() => {
  if (saving.value) {
    return props.mode === "edit" ? "Updating…" : "Connecting…";
  }
  return props.mode === "edit" ? "Update" : "Connect";
});

function cancelEditWorkspace() {
  emits("cancel");
}

async function saveWorkspace() {
  saving.value = true;
  try {
    await workspaceStore.saveWorkspace(workspace.value);
    emits("saveWorkspace");
  } catch (error: unknown) {
    if (error instanceof BackendError) {
      const response = error.failure?.response;
      if (isObject(response) && "cause" in response && isString(response.cause)) {
        errorDialogMessage.value = response.cause;
      }
      return;
    }
  } finally {
    saving.value = false;
  }
}

function newSnowflakeWorkspace(): SnowflakeWorkspace {
  return {
    metadata: {
      nickname: "",
      id: "",
      connected: true,
      current_warehouse_id: undefined,
      provider: ProviderType.Snowflake,
    },
    credentials: {
      user: "",
      password: "",
    },
  };
}

onMounted(() => {
  if (props.currentWorkspace) {
    // Todo: add a proper type filter.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workspace.value = props.currentWorkspace as any;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }, [
        _createElementVNode("span", null, " Enter your Snowflake credentials. ")
      ], -1)),
      _createElementVNode("form", _hoisted_2, [
        _createVNode(Textbox, {
          label: "Workspace nickname",
          modelValue: workspace.value.metadata.nickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((workspace.value.metadata.nickname) = $event)),
          required: true,
          disabled: saving.value
        }, null, 8, ["modelValue", "disabled"]),
        _createVNode(Textbox, {
          label: "Account ID",
          modelValue: workspace.value.metadata.id,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((workspace.value.metadata.id) = $event)),
          required: true,
          disabled: saving.value
        }, null, 8, ["modelValue", "disabled"]),
        _createVNode(Textbox, {
          label: "User",
          modelValue: workspace.value.credentials.user,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((workspace.value.credentials.user) = $event)),
          required: true,
          autocomplete: "username",
          disabled: saving.value
        }, null, 8, ["modelValue", "disabled"]),
        _createVNode(Textbox, {
          label: "Password",
          modelValue: workspace.value.credentials.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((workspace.value.credentials.password) = $event)),
          required: true,
          type: "password",
          autocomplete: "current-password",
          disabled: saving.value
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(TextButton, {
          label: "Cancel",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (cancelEditWorkspace())),
          disabled: saving.value
        }, null, 8, ["disabled"]),
        (!saving.value)
          ? (_openBlock(), _createBlock(TextButton, {
              key: 0,
              label: commitLabel.value,
              disabled: !saveEnabled.value || saving.value,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (saveWorkspace()))
            }, null, 8, ["label", "disabled"]))
          : (_openBlock(), _createBlock(SpinnerButton, {
              key: 1,
              label: commitLabel.value
            }, null, 8, ["label"]))
      ])
    ]),
    (errorDialogMessage.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          "cancel-label": undefined,
          title: "Connection Error",
          icon: "error",
          onSucceeded: _cache[6] || (_cache[6] = ($event: any) => (errorDialogMessage.value = undefined))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(errorDialogMessage.value), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})