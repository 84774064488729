import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["disabled"]

import Icon from "@/common/components/Icon.vue";
import { Tooltip } from "floating-vue";
import { IconColor, IconSize } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconButton',
  props: {
    name: {},
    size: { default: "m" },
    color: {},
    tooltip: {},
    highlighted: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Tooltip), {
      class: "flex",
      disabled: _ctx.tooltip == null
    }, {
      popper: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: _normalizeClass(["cursor-pointer border-0 p-0", {
          'opacity-20': _ctx.disabled,
          'opacty-100 hover:opacity-70': _ctx.highlighted,
          'opacity-50 hover:opacity-100': !_ctx.highlighted && !_ctx.disabled,
        }]),
          disabled: _ctx.disabled
        }, [
          _createVNode(Icon, {
            name: _ctx.name,
            size: _ctx.size,
            color: _ctx.color
          }, null, 8, ["name", "size", "color"])
        ], 10, _hoisted_2)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}
}

})