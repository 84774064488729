import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

import { computed } from "vue";
import { Tooltip } from "floating-vue";
import { getIconSizePx, IconSize, parseIconName } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconButtonV2',
  props: {
    name: {},
    size: { default: "m" },
    label: {},
    disabled: { type: Boolean, default: false },
    targetSize: { default: 32 },
    darkHover: { type: Boolean, default: false },
    preText: {},
    selected: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const sizePx = computed(() => getIconSizePx(props.size));
const iconSrc = computed(() => parseIconName(props.name));
const buttonSizePx = computed(() => Math.max(sizePx.value, props.targetSize) + "px");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", { '-dark-hover': _ctx.darkHover }]),
    style: _normalizeStyle({ height: buttonSizePx.value })
  }, [
    _createVNode(_unref(Tooltip), {
      class: "tooltip flex",
      disabled: _ctx.label == null || _ctx.disabled,
      theme: "icon-button-tooltip",
      placement: "bottom",
      distance: 2
    }, {
      popper: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: _normalizeClass(["with-icon group flex cursor-pointer items-center justify-around rounded-[16px] border-none p-0", {
          'active:bg-orange dark:active:bg-black': !_ctx.disabled && !_ctx.selected,
          'bg-orange dark:bg-black': _ctx.selected,
          'hover:bg-gray-200 dark:hover:bg-gray-900': !_ctx.disabled && !_ctx.darkHover && !_ctx.selected,
          'hover:bg-gray-300 dark:hover:bg-black dark:active:bg-gray-900':
            !_ctx.disabled && _ctx.darkHover && !_ctx.selected,
          'cursor-not-allowed opacity-50 dark:opacity-100': _ctx.disabled,
        }]),
          disabled: _ctx.disabled,
          style: _normalizeStyle({ width: buttonSizePx.value, height: buttonSizePx.value })
        }, [
          (_ctx.preText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["svg-to-medium-gray", {
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !_ctx.disabled,
            'dark:svg-to-dark-gray svg-to-light-gray': _ctx.disabled,
          }])
              }, _toDisplayString(_ctx.preText), 3))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            src: iconSrc.value,
            style: _normalizeStyle({ width: `${sizePx.value}px`, height: `${sizePx.value}px` }),
            class: _normalizeClass(["dark:svg-to-medium-gray svg-to-dark-gray max-w-none", {
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !_ctx.disabled,
            'svg-to-light-gray dark:svg-to-dark-gray': _ctx.disabled,
          }])
          }, null, 14, _hoisted_2)
        ], 14, _hoisted_1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 6))
}
}

})