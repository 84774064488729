import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 ml-[22px] mr-5 flex items-center rounded-xl bg-gray-200 px-5 py-2 dark:bg-gray-900" }
const _hoisted_2 = { class: "mr-5 basis-full overflow-hidden" }

import IconButton from "@/common/components/IconButton.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { ComputedRef, computed, toRefs } from "vue";
import EqualityFilterComponent from "./filter-types/EqualityFilter.vue";
import TextFilterComponent from "./filter-types/TextFilter.vue";
import RangeFilterComponent from "./filter-types/RangeFilter.vue";
import ExistenceFilterComponent from "./filter-types/ExistenceFilter.vue";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import Icon from "@/common/components/Icon.vue";
import { QueryFilter } from "@/common/lib/query";
import {
  FilterType,
  ExistenceFilter,
  EqualityFilter,
  RangeFilter,
  TextFilter,
} from "@/common/lib/fetchApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'Filter',
  props: {
    filter: {}
  },
  setup(__props: any) {

const props = __props;
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const menuItems: ComputedRef<MenuItem[]> = computed(() => [
  {
    key: "negate",
    label: "Negate filter",
    action: () => exploreStore.toggleFilterNegated(filter.value.alias),
  },
  {
    key: "remove",
    label: "Remove filter",
    action: () => exploreStore.removeFilter(filter.value.alias),
  },
]);

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Icon, {
      name: "md:filter_alt",
      color: "orange",
      class: "self-start"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_unref(filter).type === _unref(FilterType).Equality)
        ? (_openBlock(), _createBlock(EqualityFilterComponent, {
            key: 0,
            filter: _unref(filter) as QueryFilter<EqualityFilter>
          }, null, 8, ["filter"]))
        : _createCommentVNode("", true),
      (_unref(filter).type === _unref(FilterType).Text)
        ? (_openBlock(), _createBlock(TextFilterComponent, {
            key: 1,
            filter: _unref(filter) as QueryFilter<TextFilter>
          }, null, 8, ["filter"]))
        : _createCommentVNode("", true),
      (_unref(filter).type === _unref(FilterType).Range)
        ? (_openBlock(), _createBlock(RangeFilterComponent, {
            key: 2,
            filter: _unref(filter) as QueryFilter<RangeFilter>
          }, null, 8, ["filter"]))
        : _createCommentVNode("", true),
      (_unref(filter).type === _unref(FilterType).Exists)
        ? (_openBlock(), _createBlock(ExistenceFilterComponent, {
            key: 3,
            filter: _unref(filter) as QueryFilter<ExistenceFilter>
          }, null, 8, ["filter"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_unref(Dropdown), {
      placement: "right",
      class: "body"
    }, {
      popper: _withCtx(() => [
        _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
          [_directive_close_popper]
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(IconButton, {
          name: "chevron-down",
          size: "xs"
        })
      ]),
      _: 1
    })
  ]))
}
}

})