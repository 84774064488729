import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }

import { stringifyValue, toNative, toTypedValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isNumber, isString } from "lodash";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import { GraphValueType } from "@/common/lib/value";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { QueryFilter } from "@/common/lib/query";
import { RangeFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import { DateTime } from "luxon";
import SelectStrip from "@/editor/components/SelectStrip.vue";
import { UserAction } from "@/common/lib/userAction";
import { columnName } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'RangeFilter',
  props: {
    filter: {}
  },
  setup(__props: any) {

const props = __props;
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const lowerBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const upperBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const lowerBoundInputEl: Ref<HTMLInputElement | null> = ref(null);
const isEditing: Ref<boolean> = ref(false);
const specifyTimeOfDay: Ref<boolean> = ref(false);

const valueType = computed(function () {
  if (filter.value.property_type != null) {
    return propertyValueType(filter.value.property_type) as GraphValueType;
  } else {
    return propertyValueType(
      exploreStore.columnByAlias(filter.value.on!)!.property_type
    ) as GraphValueType;
  }
});

function subjectName() {
  if (filter.value.property_type != null) {
    return propertyName(filter.value.property_type);
  } else {
    return columnName(exploreStore.columnByAlias(filter.value.on!)!);
  }
}

const lowerBound = computed(() => filter.value.values[0]?.gte ?? filter.value.values[0]?.gt);
const upperBound = computed(() => filter.value.values[0]?.lte ?? filter.value.values[0]?.lt);
const isExact = computed(() => lowerBound.value?.value === upperBound.value?.value);

const opDescription = computed(() => (filter.value.negated ? "is not" : "is"));
const formLabel = computed(() => `${subjectName()} ${opDescription.value} between`);

const inputType = computed(() => {
  switch (valueType.value) {
    case GraphValueType.Date:
      return "date";
    case GraphValueType.Datetime:
      if (specifyTimeOfDay.value) {
        return "datetime-local";
      } else {
        return "date";
      }
    default:
      return "number";
  }
});

function isDate() {
  return [GraphValueType.Date, GraphValueType.Datetime].includes(valueType.value);
}

function dateToInputString(dt: DateTime) {
  return specifyTimeOfDay.value
    ? dt.toISO({ includeOffset: false, suppressMilliseconds: true })!
    : dt.toISODate()!;
}

function startEditing() {
  if (isDate()) {
    specifyTimeOfDay.value =
      valueType.value === GraphValueType.Datetime &&
      (lowerBound.value?._type === GraphValueType.Datetime ||
        upperBound.value?._type === GraphValueType.Datetime);
    lowerBoundUnderEdit.value =
      lowerBound.value == null ? "" : dateToInputString(toNative(lowerBound.value) as DateTime);
    upperBoundUnderEdit.value =
      upperBound.value == null ? "" : dateToInputString(toNative(upperBound.value) as DateTime);
  } else {
    lowerBoundUnderEdit.value = lowerBound.value ? (toNative(lowerBound.value) as number) : "";
    upperBoundUnderEdit.value = upperBound.value ? (toNative(upperBound.value) as number) : "";
  }
  isEditing.value = true;
}

function setSpecifyTimeOfDay(newValue: boolean) {
  const lower = (lowerBoundUnderEdit.value ?? "") as string;
  const upper = (upperBoundUnderEdit.value ?? "") as string;
  specifyTimeOfDay.value = newValue;
  nextTick(function () {
    // After input types change, convert dates to datetimes or vice versa
    const convert = (ds: string) => dateToInputString(DateTime.fromISO(ds));
    if (lower.length > 0) lowerBoundUnderEdit.value = convert(lower);
    if (upper.length > 0) upperBoundUnderEdit.value = convert(upper);
  });
}

function focusFirstField() {
  setTimeout(() => lowerBoundInputEl.value?.focus(), 50); // Boy do I hate this
}

function commit() {
  const rangeFilter: Partial<RangeFilter> = {};
  if (isNumber(lowerBoundUnderEdit.value)) {
    rangeFilter.gte = toTypedValue(lowerBoundUnderEdit.value, valueType.value);
  }
  if (isNumber(upperBoundUnderEdit.value)) {
    rangeFilter.lte = toTypedValue(upperBoundUnderEdit.value, valueType.value);
  }
  if (isString(lowerBoundUnderEdit.value) && lowerBoundUnderEdit.value !== "") {
    rangeFilter.gte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: lowerBoundUnderEdit.value,
    };
  }
  if (isString(upperBoundUnderEdit.value) && upperBoundUnderEdit.value !== "") {
    rangeFilter.lte = {
      _type: inputType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: upperBoundUnderEdit.value,
    };
  }
  filter.value.values = [rangeFilter];
  isEditing.value = false;
  exploreStore.load(UserAction.Update_Range_Filter);
}

const isEditValid = computed(function () {
  // At least one bound must be present
  let lowerIsSet, upperIsSet;
  if (isDate()) {
    lowerIsSet = !!lowerBoundUnderEdit.value;
    upperIsSet = !!upperBoundUnderEdit.value;
  } else {
    lowerIsSet = isNumber(lowerBoundUnderEdit.value);
    upperIsSet = isNumber(upperBoundUnderEdit.value);
  }
  if (!lowerIsSet && !upperIsSet) return false;

  // If both bounds are present, bounds must not be inverted
  if (lowerIsSet && upperIsSet) {
    if (isDate()) {
      const lowerDate = DateTime.fromISO(lowerBoundUnderEdit.value as string);
      const upperDate = DateTime.fromISO(upperBoundUnderEdit.value as string);
      if (lowerDate > upperDate) return false;
    } else {
      if (lowerBoundUnderEdit.value! > upperBoundUnderEdit.value!) return false;
    }
  }

  return true;
});

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    placement: "right",
    shown: isEditing.value,
    "onUpdate:shown": _cache[4] || (_cache[4] = ($event: any) => ((isEditing).value = $event)),
    onApplyShow: focusFirstField
  }, {
    popper: _withCtx(() => [
      _createVNode(FilterEditor, {
        label: formLabel.value,
        onCancel: _cache[3] || (_cache[3] = ($event: any) => (isEditing.value = false)),
        onCommit: commit,
        valid: isEditValid.value
      }, {
        default: _withCtx(() => [
          (valueType.value === _unref(GraphValueType).Datetime)
            ? (_openBlock(), _createBlock(SelectStrip, {
                key: 0,
                "model-value": specifyTimeOfDay.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => setSpecifyTimeOfDay(v as boolean)),
                options: [
            { label: 'Date', value: false },
            { label: 'Date & Time', value: true },
          ],
                class: "mb-5 h-[28px]"
              }, null, 8, ["model-value"]))
            : _createCommentVNode("", true),
          _createVNode(Textbox, {
            type: inputType.value,
            modelValue: lowerBoundUnderEdit.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lowerBoundUnderEdit).value = $event)),
            ref_key: "lowerBoundInputEl",
            ref: lowerBoundInputEl,
            label: inputType.value === 'date' ? 'Start date' : 'Minimum value',
            class: "w-full"
          }, null, 8, ["type", "modelValue", "label"]),
          _createVNode(Textbox, {
            type: inputType.value,
            modelValue: upperBoundUnderEdit.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((upperBoundUnderEdit).value = $event)),
            label: inputType.value === 'date' ? 'End date' : 'Maximum value',
            class: "w-full"
          }, null, 8, ["type", "modelValue", "label"])
        ]),
        _: 1
      }, 8, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: startEditing,
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
      }, [
        _createTextVNode(_toDisplayString(opDescription.value) + " ", 1),
        (_unref(filter).values.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (isExact.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[5] || (_cache[5] = _createTextVNode(" exactly ")),
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(stringifyValue)(lowerBound.value!)), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (upperBound.value == null)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[6] || (_cache[6] = _createTextVNode(" at least ")),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(stringifyValue)(lowerBound.value!)), 1)
                        ], 64))
                      : (lowerBound.value == null)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _cache[7] || (_cache[7] = _createTextVNode(" at most ")),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(stringifyValue)(upperBound.value)), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _cache[8] || (_cache[8] = _createTextVNode(" between ")),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(stringifyValue)(lowerBound.value)), 1),
                            _cache[9] || (_cache[9] = _createTextVNode(" and ")),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(stringifyValue)(upperBound.value)), 1)
                          ], 64))
                  ], 64))
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode("(click to set)")
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["shown"]))
}
}

})