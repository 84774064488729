import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mx-5 flex basis-full flex-row items-center gap-5 pr-2" }

import IconButton from "@/common/components/IconButton.vue";
import Icon from "@/common/components/Icon.vue";
import Menu from "@/common/components/Menu.vue";
import { PropertyOpType } from "@/common/lib/derived";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useKnowledge from "@/common/composables/useKnowledge";
import { buildSimpleColumn, findCurrentColumn } from "@/reader/lib/explore";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import Filter from "../Filter.vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import { isEqual } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeProperty',
  props: {
    conceptPath: {},
    propertyType: {},
    derivedAliases: {},
    isResolved: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const { propertyType, conceptPath } = toRefs(props);

const exploreStore = useExploreStore();
const { typeLabel } = useKnowledge();

const label = computed(() => typeLabel(propertyType.value));
const isCurrentColumn = computed(() => !!findCurrentColumn(column()));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.PropertyType, {
    conceptPath: conceptPath.value,
    propertyType: propertyType.value,
  })
);
const nonaggregateColumnsProhibited = computed(() => exploreStore.query!.group_by === GROUP_BY_ALL);

function column(op?: PropertyOpType) {
  return buildSimpleColumn(expandTreePath(conceptPath.value), propertyType.value, op);
}

function toggleBasicColumn(op?: PropertyOpType) {
  const currentColumn = findCurrentColumn(column(op));
  if (currentColumn != null) {
    exploreStore.removeColumn(findCurrentColumn(column(op))!);
  } else {
    exploreStore.addColumn(column(op));
  }
}

const relatedFilters = computed(function () {
  const asColumn = column();
  return exploreStore.query!.filters.filter(
    (f) => isEqual(f.path, asColumn.path) && f.property_type === asColumn.property_type
  );
});

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(exploreStore).hideUnusedProperties || isCurrentColumn.value || relatedFilters.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mx-5 flex cursor-pointer items-center rounded-full px-5 py-2 hover:bg-gray-200 dark:hover:bg-black", { 'dark:text-white': isCurrentColumn.value, 'text-gray-500': !isCurrentColumn.value }])
        }, [
          _createVNode(IconButton, {
            name: isCurrentColumn.value ? 'checkbox-selected' : 'checkbox-empty',
            highlighted: isCurrentColumn.value,
            color: isCurrentColumn.value ? 'orange' : undefined,
            disabled: nonaggregateColumnsProhibited.value,
            tooltip: 
        nonaggregateColumnsProhibited.value
          ? 'While Summarizing All, only aggregate columns can be added.'
          : undefined
      ,
            size: "l",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleBasicColumn()))
          }, null, 8, ["name", "highlighted", "color", "disabled", "tooltip"]),
          _createVNode(_unref(Dropdown), {
            placement: "right",
            class: "flex basis-full items-center"
          }, {
            popper: _withCtx(() => [
              _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
                [_directive_close_popper]
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  class: _normalizeClass({ 'font-bold': _ctx.isResolved })
                }, _toDisplayString(label.value), 3),
                (_ctx.isResolved)
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      name: "merge",
                      size: "xs"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(IconButton, {
                name: "chevron-down",
                size: "xs"
              })
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relatedFilters.value, (filter) => {
      return (_openBlock(), _createBlock(Filter, {
        filter: filter,
        key: filter.alias
      }, null, 8, ["filter"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.derivedAliases, (alias) => {
      return (_openBlock(), _createBlock(TreeDerivedColumn, {
        alias: alias,
        key: alias
      }, null, 8, ["alias"]))
    }), 128))
  ], 64))
}
}

})