import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "w-[25px]"
}
const _hoisted_3 = {
  key: 0,
  class: "select-text text-nowrap text-right"
}
const _hoisted_4 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_5 = { class: "sticky" }
const _hoisted_6 = {
  key: 0,
  class: "select-text text-nowrap text-right"
}
const _hoisted_7 = {
  key: 1,
  class: "text-right opacity-50"
}

import { FinComputedCell, FinComputedRow } from "../FinancialReport.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialSubreport',
  props: {
    rows: {},
    columns: {},
    depth: {},
    expandedMap: {}
  },
  emits: ["update-expanded"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { depth, expandedMap } = toRefs(props);

const emits = __emit;

function cells(row: FinComputedRow): FinComputedCell[] {
  return props.columns.map((_, index) => row.cells[index]);
}

function expandMap(rowLabel: string) {
  emits("update-expanded", rowLabel);
}

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));

return (_ctx: any,_cache: any) => {
  const _component_FinancialSubreport = _resolveComponent("FinancialSubreport", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndex }, [
      _createElementVNode("tr", {
        class: _normalizeClass([{
        'odd:bg-gray-100': row.contents == null && !row.highlight && !_unref(expandedMap).has(row.label),
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight && !_unref(expandedMap).has(row.label),
        uppercase: row.isTotal,
      }, "h-[34px] hover:bg-finance-highlight"])
      }, [
        _createElementVNode("td", {
          class: "sticky cursor-pointer",
          onClick: ($event: any) => (row.contents != null && expandMap(row.label))
        }, [
          _createElementVNode("div", {
            class: "flex flex-row items-center text-nowrap font-bold",
            style: _normalizeStyle(indentStyle.value)
          }, [
            (row.contents != null)
              ? (_openBlock(), _createBlock(Icon, {
                  key: 0,
                  name: 
              _unref(expandedMap).has(row.label) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'
            ,
                  color: "medium-gray",
                  size: "xl"
                }, null, 8, ["name"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2)),
            _createTextVNode(" " + _toDisplayString(row.label), 1)
          ], 4)
        ], 8, _hoisted_1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cells(row), (cell, colIndex) => {
          return (_openBlock(), _createElementBlock("td", {
            key: colIndex,
            class: "p-10 align-baseline"
          }, [
            (!_unref(expandedMap).has(row.label))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle(colIndex == 0 ? indentStyle.value : undefined)
                }, [
                  (cell.value != null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(GraphValueComponent, {
                          value: cell.value.formattedValue
                        }, null, 8, ["value"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                ], 4))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ], 2),
      (row.contents != null && _unref(expandedMap).has(row.label))
        ? (_openBlock(), _createBlock(_component_FinancialSubreport, {
            key: 0,
            rows: row.contents,
            columns: _ctx.columns,
            depth: _unref(depth) + 1,
            expandedMap: _unref(expandedMap),
            onUpdateExpanded: _cache[0] || (_cache[0] = (rowLabel) => emits('update-expanded', rowLabel))
          }, null, 8, ["rows", "columns", "depth", "expandedMap"]))
        : _createCommentVNode("", true),
      (_unref(expandedMap).has(row.label))
        ? (_openBlock(), _createElementBlock("tr", {
            key: 1,
            class: _normalizeClass([{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'bg-finance-row_even odd:bg-finance-row_odd': row.highlight,
      }, "h-[34px] cursor-pointer hover:bg-finance-highlight"])
          }, [
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-row items-center text-nowrap font-bold", { uppercase: row.isTotal }]),
                style: _normalizeStyle(indentStyle.value)
              }, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-[25px]" }, null, -1)),
                _createTextVNode(" Total " + _toDisplayString(row.label), 1)
              ], 6)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cells(row), (cell, colIndex) => {
              return (_openBlock(), _createElementBlock("td", {
                key: colIndex,
                class: "p-10 align-baseline"
              }, [
                _createElementVNode("div", {
                  style: _normalizeStyle(colIndex == 0 ? indentStyle.value : undefined)
                }, [
                  (cell.value != null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(GraphValueComponent, {
                          value: cell.value.formattedValue
                        }, null, 8, ["value"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                ], 4)
              ]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}
}

})