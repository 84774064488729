import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between pr-[4px] align-text-top" }
const _hoisted_2 = { class: "tooltip-content" }

import StatusPill from "@/common/components/StatusPill.vue";
import { Tooltip } from "floating-vue";
import { computed, toRefs } from "vue";
import { Warehouse } from "../stores/workspaceStore";
import { capitalize, upperCase } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseStatus',
  props: {
    warehouse: {}
  },
  setup(__props: any) {

const props = __props;

const { warehouse } = toRefs(props);

const warehouseInfo = computed(() => getWarehouseInfo(warehouse.value));

const warehouseStatusColor = computed(() => {
  switch (warehouse.value.state) {
    case "RUNNING":
      return "green";
    case "STARTING":
      return "yellow";
    case "STOPPED":
    case "STOPPING":
      return "red";
    case "DELETED":
    case "DELETING":
      return "black";
  }
  return "black";
});

function getWarehouseInfo(warehouse: Warehouse) {
  return {
    status: warehouse.state,
    name: warehouse.name,
    size: warehouse.cluster_size,
  };
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_unref(Tooltip), {
      placement: "bottom",
      delay: { show: 100, hide: 100 }
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(warehouseInfo.value.name) + " is " + _toDisplayString(warehouseInfo.value.status), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(StatusPill, {
          color: warehouseStatusColor.value,
          label: _unref(capitalize)(warehouseInfo.value.status) + ' ' + _unref(upperCase)(warehouseInfo.value.name),
          sublabel: warehouseInfo.value.size
        }, null, 8, ["color", "label", "sublabel"])
      ]),
      _: 1
    })
  ]))
}
}

})