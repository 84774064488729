import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, normalizeStyle as _normalizeStyle, vModelText as _vModelText } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "relative flex w-full gap-30"
}
const _hoisted_3 = ["type", "disabled", "autocomplete", "required", "id"]
const _hoisted_4 = ["type", "disabled", "autocomplete", "id"]
const _hoisted_5 = ["type", "disabled", "id"]
const _hoisted_6 = {
  key: 4,
  class: "pt-5 text-xs text-gray-500"
}
const _hoisted_7 = {
  key: 5,
  class: "flex w-full items-start gap-10 p-5 text-xs text-red-300"
}
const _hoisted_8 = {
  key: 6,
  class: "min-h-[22px]"
}

import { computed, onMounted, ref, watch, Ref, inject } from "vue";
import Icon from "./Icon.vue";
import { DarkMode } from "../lib/keys";

export default /*@__PURE__*/_defineComponent({
  __name: 'Textbox',
  props: {
    label: {},
    helperText: {},
    errorText: {},
    modelValue: {},
    type: {},
    disabled: { type: Boolean },
    textarea: { type: Boolean },
    minHeight: {},
    maxHeight: {},
    autocomplete: {},
    autoselect: { type: Boolean },
    required: { type: Boolean },
    id: {}
  },
  emits: ["update:modelValue", "blur", "keyup.enter", "focus"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;
const el = ref<HTMLTextAreaElement | undefined>(undefined);
const darkMode = inject(DarkMode) as Ref<boolean>;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

if (props.textarea) {
  watch([value, el], () => {
    if (!el.value) {
      return;
    }
    // Make tiny to force shrinking when text is deleted
    el.value.style["height"] = "1px";
    const height = `${el.value.scrollHeight}px`;
    el.value.style["height"] = height;
  });
}

function focus() {
  el.value?.focus();
  el.value?.select();
}

__expose({
  focus,
});

onMounted(() => {
  if (props.autoselect && el.value) {
    focus();
  }
});

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group flex w-full flex-col items-start pt-5", { 'pb-5': _ctx.errorText === undefined }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass(["text-s pointer-events-none mb-5 flex items-start gap-5 font-semibold text-gray-500", [
        requiredText.value,
        {
          'group-focus-within:text-orange': !_ctx.disabled,
        },
      ]])
        }, _toDisplayString(_ctx.label), 11, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.type === 'search')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Icon, {
            class: _normalizeClass(["absolute left-8 top-[7px]", {
          'svg-to-dark-gray': _unref(darkMode),
          'svg-to-medium-gray': !_unref(darkMode),
        }]),
            name: "search"
          }, null, 8, ["class"]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["flex h-[34px] w-full resize-none items-center gap-10 self-stretch rounded border border-solid py-10 pl-[38px] pr-20 text-base text-gray-800 caret-orange outline-0 focus:border-[1px] focus:border-orange focus:outline-none search-cancel:appearance-none dark:border-gray-700 dark:text-gray-200 dark:focus:border-orange", {
          'border-gray-200 bg-gray-100 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-800 dark:bg-gray-900 dark:hover:border-black dark:hover:bg-black dark:focus:bg-gray-900':
            !_ctx.disabled,
          'background-unset border-[1px] border-gray-300 dark:border-gray-700': _ctx.disabled,
        }]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
            type: _ctx.type,
            disabled: _ctx.disabled,
            autocomplete: _ctx.autocomplete,
            "helper-text": "hmm okay",
            required: _ctx.required,
            id: _ctx.id,
            ref_key: "el",
            ref: el
          }, null, 10, _hoisted_3), [
            [_vModelDynamic, value.value]
          ])
        ]))
      : (!_ctx.textarea)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
            required: "",
            class: _normalizeClass(["flex h-[34px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800", {
        'border-gray-500 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !_ctx.disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': _ctx.disabled,
      }]),
            style: _normalizeStyle({ 'color-scheme': _unref(darkMode) ? 'dark' : 'light' }),
            type: _ctx.type,
            disabled: _ctx.disabled,
            autocomplete: _ctx.autocomplete,
            autofocus: true,
            id: _ctx.id,
            "data-test": "text-input",
            ref_key: "el",
            ref: el,
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (emit('focus', $event))),
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (emit('blur', $event))),
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (emit('keyup.enter', $event)), ["enter"]))
          }, null, 46, _hoisted_4)), [
            [_vModelDynamic, value.value]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 3,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((value).value = $event)),
            required: "",
            class: _normalizeClass(["flex h-[40px] max-h-[140px] resize-none items-center gap-10 self-stretch rounded border-[1px] border-solid bg-gray-200 py-10 pl-10 pr-20 caret-orange focus:border-orange focus:outline-none dark:bg-gray-800", {
        'dark:focus:gray-900 border-gray-300 text-gray-900 hover:border-gray-500 hover:bg-gray-100 focus:bg-gray-100 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-900 dark:focus:bg-gray-900':
          !_ctx.disabled,
        'border-gray-300 text-gray-300 dark:border-gray-700 dark:text-gray-700': _ctx.disabled,
      }]),
            type: _ctx.type,
            disabled: _ctx.disabled,
            id: _ctx.id,
            ref_key: "el",
            ref: el,
            style: _normalizeStyle({ 'min-height': _ctx.minHeight, 'max-height': _ctx.maxHeight })
          }, null, 14, _hoisted_5)), [
            [_vModelText, value.value]
          ]),
    (_ctx.helperText && !_ctx.errorText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.helperText), 1))
      : _createCommentVNode("", true),
    (_ctx.errorText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errorText), 1))
      : _createCommentVNode("", true),
    (_ctx.errorText === '' && !_ctx.helperText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("", true)
  ], 2))
}
}

})