import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "absolute inset-0 z-[1000]" }
const _hoisted_2 = { class: "absolute inset-0 flex items-center justify-center" }
const _hoisted_3 = { class: "flex min-h-[100px] min-w-[450px] max-w-[450px] flex-col gap-5 rounded-[5px] border border-gray-200 bg-white px-20 py-10 text-gray-500 shadow-modal dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200" }
const _hoisted_4 = { class: "mb-5 flex flex-row items-center gap-10 text-xxl font-bold leading-6" }
const _hoisted_5 = {
  key: 0,
  class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
}
const _hoisted_6 = { class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white" }
const _hoisted_7 = {
  key: 1,
  class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
}
const _hoisted_8 = {
  key: 2,
  class: "flex flex-row items-center justify-end gap-10 pt-10"
}

import TextButton from "@/common/components/TextButton.vue";
import Icon from "./Icon.vue";
import { useKeyHandler } from "../composables/useKeyHandler";
import { KeyCommand } from "../lib/modifierKeys";
import { IconColorSpec } from "@/common/lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog',
  props: {
    title: {},
    icon: { default: "md:deployed_code" },
    iconColor: {},
    successLabel: { default: "Ok" },
    cancelLabel: { default: "Cancel" },
    skipDefaultFooter: { type: Boolean, default: false }
  },
  emits: ["succeeded", "cancelled"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const keyHandler = useKeyHandler();
if (!props.skipDefaultFooter && props.cancelLabel) {
  keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("cancelled"), true, false);
}
if (!props.skipDefaultFooter && props.successLabel) {
  keyHandler.registerHandler(new KeyCommand("Enter"), () => emit("succeeded"), true, false);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(Icon, {
              name: _ctx.icon,
              color: _ctx.iconColor
            }, null, 8, ["name", "color"]),
            _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
          ]),
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "header")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (_ctx.$slots.footer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true),
          (!_ctx.skipDefaultFooter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_ctx.cancelLabel)
                  ? (_openBlock(), _createBlock(TextButton, {
                      key: 0,
                      label: _ctx.cancelLabel,
                      secondary: true,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancelled')))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.successLabel)
                  ? (_openBlock(), _createBlock(TextButton, {
                      key: 1,
                      label: _ctx.successLabel,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('succeeded'))),
                      "data-test": "success-button"
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})