import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-1" }

import { ExploreTreeNode } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";
import TreeConcept from "./TreeConcept.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metatree',
  setup(__props) {

const exploreStore = useExploreStore();
const rootPath = computed(function (): ExploreTreeNode[] {
  return [{ concept_type: exploreStore.query!.root_concept_type }];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TreeConcept, { path: rootPath.value }, null, 8, ["path"])
  ]))
}
}

})