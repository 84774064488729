import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Label',
  props: {
    label: {},
    disabled: { type: Boolean },
    focus: { type: Boolean },
    required: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["pointer-events-none flex items-start gap-5 pl-10 text-xs font-bold text-gray-500", [
      requiredText.value,
      {
        'text-orange': _ctx.focus,
      },
    ]])
  }, _toDisplayString(_ctx.label), 3))
}
}

})