import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-5 my-4 flex h-20 cursor-pointer items-center rounded-full p-4 hover:bg-gray-200 dark:text-white dark:hover:bg-black" }
const _hoisted_2 = { class: "mx-5 basis-full overflow-hidden text-ellipsis whitespace-nowrap" }

import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { propertyName } from "@/common/lib/derived";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import Filter from "../Filter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeDerivedColumn',
  props: {
    alias: {}
  },
  setup(__props: any) {

const props = __props;
const { alias } = toRefs(props);

const exploreStore = useExploreStore();

const column = () => exploreStore.columnByAlias(alias.value)!;

const label = computed(() => propertyName(column().property_type, undefined, column().displayName));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.Column, {
    columnAlias: alias.value,
  })
);

const relatedFilters = computed(function () {
  return exploreStore.query!.filters.filter((f) => f.on === alias.value);
});

function removeColumn() {
  exploreStore.removeColumn(alias.value);
}

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(IconButton, {
        name: "close",
        color: "orange",
        size: "l",
        highlighted: true,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (removeColumn()))
      }),
      _createVNode(_unref(Dropdown), {
        placement: "right",
        class: "flex min-w-0 basis-full items-center"
      }, {
        popper: _withCtx(() => [
          _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
            [_directive_close_popper]
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(label.value), 1),
          _createVNode(IconButton, {
            name: "chevron-down",
            size: "xs"
          })
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relatedFilters.value, (filter) => {
      return (_openBlock(), _createBlock(Filter, {
        filter: filter,
        key: filter.alias
      }, null, 8, ["filter"]))
    }), 128))
  ], 64))
}
}

})