import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

import { useExploreStore } from "@/reader/stores/explore";
import { Ref, computed, inject, ref, watchEffect } from "vue";
import vSelect from "vue-select";
import { find } from "lodash";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { DarkMode } from "@/common/lib/keys";
import { QueryColumn, QueryOrderBy } from "@/common/lib/query";
import { FetchNOrderBy } from "@/common/lib/fetchApi";
import { columnName } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderBy',
  props: {
    "modelValue": { required: true },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const darkMode = inject(DarkMode) as Ref<boolean>;

const orderBy: Ref<Partial<QueryOrderBy>> = ref({});
const model = _useModel<QueryOrderBy | null>(__props, "modelValue");

const exploreStore = useExploreStore();

const selectedColumn = computed(function () {
  if (orderBy.value.on == null) return undefined;
  return find(exploreStore.query!.columns, { alias: orderBy.value.on });
});

function toggleAsc() {
  updateOrderBy({ asc: !orderBy.value.asc });
}

function updateColumn(col: QueryColumn) {
  updateOrderBy({ on: col.alias });
}

function updateOrderBy(changes: Partial<FetchNOrderBy>) {
  const newValue = { ...orderBy.value, ...changes };
  orderBy.value = newValue;
  if (newValue.asc != null && newValue.on != null) model.value = newValue as FetchNOrderBy;
}

watchEffect(
  () =>
    (orderBy.value = {
      on: model.value?.on,
      asc: model.value?.asc ?? true,
    })
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(IconButtonV2, {
      name: orderBy.value.asc ? 'sort-asc' : 'sort-desc',
      label: orderBy.value.asc ? 'Switch to Descending' : 'Switch to Ascending',
      onClick: toggleAsc,
      size: "m"
    }, null, 8, ["name", "label"]),
    _createVNode(_unref(vSelect), {
      class: _normalizeClass(["select basis-full", { 'light-select': !_unref(darkMode) }]),
      "model-value": selectedColumn.value,
      options: _unref(exploreStore).query!.columns,
      getOptionLabel: _unref(columnName),
      "onOption:selected": updateColumn,
      clearable: false,
      placeholder: "Choose a Property"
    }, null, 8, ["class", "model-value", "options", "getOptionLabel"])
  ]))
}
}

})