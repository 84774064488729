import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  name: "footer",
  class: "flex flex-none flex-row justify-end gap-5 border-t border-t-gray-200 bg-gray-100 p-10 text-gray-500 dark:border-t-gray-900 dark:bg-gray-800 dark:text-gray-500"
}

import { Mode, useExploreStore } from "../stores/explore";
import { computed } from "vue";
import { environment } from "@/common/environments/environmentLoader";
import { isNil } from "lodash";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import { asyncHasValue, asyncResultOr } from "@/common/lib/async";
import { UserAction } from "@/common/lib/userAction";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExploreFooter',
  setup(__props) {

const exploreStore = useExploreStore();

const showFooter = computed(() => {
  return exploreStore.mode === Mode.Table && limitMenuItems.value.length > 0;
});

const rowsReturned = computed(() => {
  return asyncResultOr(exploreStore.table, []).length.toLocaleString();
});

const queryLimit = computed(() => {
  return exploreStore.query?.size ?? environment.requireJson("EXPLORER_DEFAULT_QUERY_LIMIT");
});

const limitMenuItems = computed(() => {
  const query = exploreStore.query;
  if (isNil(query)) {
    return [];
  }
  if (!asyncHasValue(exploreStore.table)) {
    return [];
  }
  function makeMenuItem(limit: number): MenuItem {
    return {
      key: limit.toString(),
      label: limit.toLocaleString(),
      icon: queryLimit.value === limit ? "md:check" : null,
      action: async () => {
        query!.size = limit;
        await exploreStore.loadTable(UserAction.Change_Query_Limit);
      },
      align: "end",
    };
  }
  return environment.requireJson("EXPLORER_QUERY_LIMIT_OPTIONS").map(makeMenuItem);
});

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (showFooter.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(rowsReturned.value) + " rows returned", 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "|", -1)),
        _createVNode(_unref(Dropdown), { class: "menu-wrapper" }, {
          popper: _withCtx(() => [
            _withDirectives(_createVNode(Menu, { items: limitMenuItems.value }, null, 8, ["items"]), [
              [_directive_close_popper]
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, "Row limit: " + _toDisplayString(queryLimit.value), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})